import React, { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { updateUserUrlSlug } from "../../../utils/urlSlugUtils";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import logo from "../../../assets/images/foundry.png";
import styles from "./NameSidebar.module.css";

const NameSidebar = ({ isOpen, onToggle, onNext, darkMode = false }) => {
  const { currentUser } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);
  const [loadingDots, setLoadingDots] = useState(1);

  // Effect for loading animation
  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setLoadingDots((prev) => (prev % 3) + 1);
      }, 500);
    }
    return () => clearInterval(interval);
  }, [loading]);

  const handleFirstNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setFirstName(e.target.value);
    }
  };

  const handleLastNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setLastName(e.target.value);
    }
  };

  // Function to render the loading animation
  const renderLoadingSymbols = () => {
    if (loadingDots === 1) return ">";
    if (loadingDots === 2) return "> >";
    return "> > >";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName) {
      setErrorPopup({
        message: "Please enter both your first and last names.",
        isError: true,
      });
      return;
    }

    if (!currentUser?.uid) {
      setErrorPopup({
        message: "No user found. Please try logging in again.",
        isError: true,
      });
      return;
    }

    setLoading(true);
    try {
      // Update the user's name
      await updateUserUrlSlug(currentUser.uid, firstName, lastName);

      // Also set a default birthDate value to skip the age verification requirement
      const userDocRef = doc(firestore, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        birthDate: new Date("2000-01-01").toISOString(), // Default date (making all users 21+)
      });

      // Proceed to next step
      onNext();
    } catch (error) {
      setErrorPopup({
        message: error.message || "Failed to update profile. Please try again.",
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 13 && !loading && firstName && lastName) {
        handleSubmit(e);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [loading, firstName, lastName]);

  const isFormValid = firstName && lastName;

  return (
    <>
      <aside
        className={`${styles.sidebar} ${isOpen ? styles.open : ""} ${
          darkMode ? styles.darkMode : ""
        }`}
      >
        {/* Top bar with logo - matching LandingSidebar */}
        <div className={`${styles.topBar} ${darkMode ? styles.darkMode : ""}`}>
          {/* Logo on the left */}
          <a
            href="/"
            className={styles.logoButton}
            type="button"
            aria-label="Logo"
          >
            <img src={logo} alt="foundry" className={styles.logo} />
          </a>

          {/* Right buttons for toggle sidebar */}
          <div className={styles.topBarRightButtons}>
            <button
              className={styles.iconButton}
              type="button"
              aria-label="Toggle Sidebar"
              onClick={onToggle}
            >
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                keyboard_double_arrow_left
              </span>
            </button>
          </div>
        </div>

        {/* Add spacer to push content to bottom */}
        <div className={styles.spacer}></div>

        {/* Content wrapper for bottom content */}
        <div className={styles.contentWrapper}>
          <h1 className={styles.title}>what's your name?</h1>
          {/* <p className={styles.subtitle}>what's your name?</p> */}

          <div className={styles.buttons}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <input
                id="firstName"
                name="firstName"
                type="text"
                placeholder="forename"
                className={styles.input}
                value={firstName}
                onChange={handleFirstNameChange}
                disabled={loading}
              />

              <input
                id="lastName"
                name="lastName"
                type="text"
                placeholder="surname"
                className={styles.input}
                value={lastName}
                onChange={handleLastNameChange}
                disabled={loading}
              />

              <button
                type="submit"
                className={`${styles.link} ${
                  loading || !isFormValid ? styles.disabled : ""
                }`}
                disabled={!isFormValid || loading}
                style={{
                  opacity: !isFormValid || loading ? 0.5 : 1,
                  justifyContent: "flex-start",
                }}
              >
                <div className={styles.linkIcon}>
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize: "1.25rem",
                      transform: "rotate(45deg)",
                    }}
                  >
                    arrow_upward
                  </span>
                </div>
                <span className={styles.linkText} style={{ textAlign: "left" }}>
                  {loading ? renderLoadingSymbols() : "continue"}
                </span>
              </button>
            </form>
          </div>
        </div>

        <div className={`${styles.footer} ${darkMode ? styles.darkMode : ""}`}>
          <div className={styles.links}>
            <a href="/t&c's" className={styles.smallPrint}>
              terms
            </a>
            <a href="/privacy-policy" className={styles.smallPrint}>
              privacy
            </a>
            <div className={styles.tag}>beta/v 1.0</div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default NameSidebar;
