import React, { useEffect } from "react";
import { onMessage } from "firebase/messaging";
import { messaging, isInAppBrowser, isLinkedInBrowser } from "../firebase";

const NotificationHandler = () => {
  useEffect(() => {
    // Skip for both regular in-app browsers and LinkedIn specifically
    if (isInAppBrowser() || isLinkedInBrowser() || !messaging) {
      return;
    }

    // Extra safety - bail out if the required APIs aren't available
    if (!("Notification" in window) || !("serviceWorker" in navigator)) {
      return;
    }

    const handleForegroundMessage = async (payload) => {
      try {
        console.log(
          "Foreground message payload:",
          JSON.stringify(payload, null, 2)
        );

        const permission = await Notification.requestPermission();
        if (permission !== "granted") return;

        try {
          const notification = new Notification(payload.notification.title, {
            body: payload.notification.body,
            icon: "/favicon.png",
            tag: Date.now().toString(),
            requireInteraction: true,
          });

          notification.onclick = function () {
            window.focus();
            notification.close();
          };
        } catch (err) {
          // Fallback to service worker notification
          if (navigator.serviceWorker?.ready) {
            const registration = await navigator.serviceWorker.ready;
            await registration.showNotification(payload.notification.title, {
              body: payload.notification.body,
              icon: "/favicon.png",
              tag: Date.now().toString(),
              requireInteraction: true,
            });
          }
        }
      } catch (error) {
        // Silently handle any errors to prevent app crashes
        console.log("Notification handling error (safe to ignore):", error);
      }
    };

    let unsubscribe;
    try {
      unsubscribe = onMessage(messaging, handleForegroundMessage);
    } catch (error) {
      // Silently handle any errors to prevent app crashes
      console.log("Notification setup error (safe to ignore):", error);
    }

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, []);

  return null;
};

export default NotificationHandler;
