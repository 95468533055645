// SettingsModal.js
import React, { useState, useEffect } from "react";
import classes from "./SettingsModal.module.css";

const SettingsModal = ({ onClose, onLogout, onDeleteAccount }) => {
  const [accountConfirmingDelete, setAccountConfirmingDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loadingDots, setLoadingDots] = useState(1);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);

  useEffect(() => {
    // Prevent body scrolling when modal is open
    document.body.classList.add("bodyNoScroll");

    let interval;
    if (isDeleting) {
      interval = setInterval(() => {
        setLoadingDots((prev) => (prev % 3) + 1);
      }, 500);
    }

    return () => {
      document.body.classList.remove("bodyNoScroll");
      clearInterval(interval);
    };
  }, [isDeleting]);

  const renderLoadingSymbols = () => {
    if (loadingDots === 1) return ">";
    if (loadingDots === 2) return "> >";
    return "> > >";
  };

  const handleDeleteAccount = async () => {
    setIsDeleting(true);
    try {
      await onDeleteAccount();
      // If successful, the navigation will happen in the parent component
    } catch (error) {
      setIsDeleting(false);
    }
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            <h2></h2>
            <button
              className={classes.closeIcon}
              onClick={onClose}
              aria-label="Close"
            >
              <span class="material-symbols-outlined">close</span>
            </button>
          </div>
        </div>

        <div className={classes.interactions}>
          <div className={classes.settingsMenuItems}>
            <button onClick={onLogout} className={classes.userInput}>
              log out
            </button>

            {accountConfirmingDelete ? (
              <div className={classes.deleteConfirmation}>
                <p className={classes.confirmText}>
                  are you sure you want to deactivate your account? this cannot
                  be undone.
                </p>
                <div className={classes.confirmationButtons}>
                  <button
                    onClick={handleDeleteAccount}
                    className={classes.button}
                    disabled={isDeleting}
                  >
                    {isDeleting ? renderLoadingSymbols() : "confirm"}
                  </button>
                  <button
                    onClick={() => setAccountConfirmingDelete(false)}
                    className={classes.userInput2}
                  >
                    cancel
                  </button>
                </div>
              </div>
            ) : (
              <button
                onClick={() => setAccountConfirmingDelete(true)}
                className={classes.button}
              >
                deactivate account
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
