import React, { useState, useMemo } from "react";
import styles from "./SidebarProfile.module.css"; // Reusing the same CSS
import { firestore, auth } from "../../firebase";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

const SidebarProfile = ({ isOpen, onToggle, userData }) => {
  const [expandedTexts, setExpandedTexts] = useState({});
  const MAX_LENGTH = 125;

  // Social media icon components - copied exactly from SidebarRight.js
  const SocialIcons = {
    instagram: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    apple: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M17.537 12.625a4.421 4.421 0 0 0 2.684 4.047 10.96 10.96 0 0 1-1.384 2.845c-.834 1.218-1.7 2.432-3.062 2.457-1.34.025-1.77-.794-3.3-.794-1.531 0-2.01.769-3.275.82-1.316.049-2.317-1.318-3.158-2.532-1.72-2.484-3.032-7.017-1.27-10.077A4.9 4.9 0 0 1 8.91 6.884c1.292-.025 2.51.869 3.3.869.789 0 2.27-1.075 3.828-.917a4.67 4.67 0 0 1 3.66 1.984 4.524 4.524 0 0 0-2.16 3.805m-2.52-7.432A4.4 4.4 0 0 0 16.06 2a4.482 4.482 0 0 0-2.945 1.516 4.185 4.185 0 0 0-1.061 3.093 3.708 3.708 0 0 0 2.967-1.416Z" />
      </svg>
    ),
    amazon: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z" />
      </svg>
    ),
    discord: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M18.942 5.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.586 11.586 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3 17.392 17.392 0 0 0-2.868 11.662 15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.638 10.638 0 0 1-1.706-.83c.143-.106.283-.217.418-.331a11.664 11.664 0 0 0 10.118 0c.137.114.277.225.418.331-.544.328-1.116.606-1.71.832a12.58 12.58 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM8.678 14.813a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.929 1.929 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
      </svg>
    ),
    dribbble: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M12 2a10 10 0 1 0 10 10A10.009 10.009 0 0 0 12 2Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.093 20.093 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM10 3.707a8.82 8.82 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.755 45.755 0 0 0 10 3.707Zm-6.358 6.555a8.57 8.57 0 0 1 4.73-5.981 53.99 53.99 0 0 1 3.168 4.941 32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.641 31.641 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM12 20.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 15.113 13a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    dropbox: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12.013 6.175 7.006 9.369l5.007 3.194-5.007 3.193L2 12.545l5.006-3.193L2 6.175l5.006-3.194 5.007 3.194ZM6.981 17.806l5.006-3.193 5.006 3.193L11.987 21l-5.006-3.194Z" />
        <path d="m12.013 12.545 5.006-3.194-5.006-3.176 4.98-3.194L22 6.175l-5.007 3.194L22 12.562l-5.007 3.194-4.98-3.211Z" />
      </svg>
    ),
    facebook: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    github: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M12.006 2a9.847 9.847 0 0 0-6.484 2.44 10.32 10.32 0 0 0-3.393 6.17 10.48 10.48 0 0 0 1.317 6.955 10.045 10.045 0 0 0 5.4 4.418c.504.095.683-.223.683-.494 0-.245-.01-1.052-.014-1.908-2.78.62-3.366-1.21-3.366-1.21a2.711 2.711 0 0 0-1.11-1.5c-.907-.637.07-.621.07-.621.317.044.62.163.885.346.266.183.487.426.647.71.135.253.318.476.538.655a2.079 2.079 0 0 0 2.37.196c.045-.52.27-1.006.635-1.37-2.219-.259-4.554-1.138-4.554-5.07a4.022 4.022 0 0 1 1.031-2.75 3.77 3.77 0 0 1 .096-2.713s.839-.275 2.749 1.05a9.26 9.26 0 0 1 5.004 0c1.906-1.325 2.74-1.05 2.74-1.05.37.858.406 1.828.101 2.713a4.017 4.017 0 0 1 1.029 2.75c0 3.939-2.339 4.805-4.564 5.058a2.471 2.471 0 0 1 .679 1.897c0 1.372-.012 2.477-.012 2.814 0 .272.18.592.687.492a10.05 10.05 0 0 0 5.388-4.421 10.473 10.473 0 0 0 1.313-6.948 10.32 10.32 0 0 0-3.39-6.165A9.847 9.847 0 0 0 12.007 2Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    google: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M12.037 21.998a10.313 10.313 0 0 1-7.168-3.049 9.888 9.888 0 0 1-2.868-7.118 9.947 9.947 0 0 1 3.064-6.949A10.37 10.37 0 0 1 12.212 2h.176a9.935 9.935 0 0 1 6.614 2.564L16.457 6.88a6.187 6.187 0 0 0-4.131-1.566 6.9 6.9 0 0 0-4.794 1.913 6.618 6.618 0 0 0-2.045 4.657 6.608 6.608 0 0 0 1.882 4.723 6.891 6.891 0 0 0 4.725 2.07h.143c1.41.072 2.8-.354 3.917-1.2a5.77 5.77 0 0 0 2.172-3.41l.043-.117H12.22v-3.41h9.678c.075.617.109 1.238.1 1.859-.099 5.741-4.017 9.6-9.746 9.6l-.215-.002Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    linkedin: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
          clipRule="evenodd"
        />
        <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
      </svg>
    ),
    reddit: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12.008 16.521a3.84 3.84 0 0 0 2.47-.77v.04a.281.281 0 0 0 .005-.396.281.281 0 0 0-.395-.005 3.291 3.291 0 0 1-2.09.61 3.266 3.266 0 0 1-2.081-.63.27.27 0 0 0-.38.381 3.84 3.84 0 0 0 2.47.77Z" />
        <path
          fillRule="evenodd"
          d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-4.845-1.407A1.463 1.463 0 0 1 18.67 12a1.46 1.46 0 0 1-.808 1.33c.01.146.01.293 0 .44 0 2.242-2.61 4.061-5.829 4.061s-5.83-1.821-5.83-4.061a3.25 3.25 0 0 1 0-.44 1.458 1.458 0 0 1-.457-2.327 1.458 1.458 0 0 1 2.063-.064 7.163 7.163 0 0 1 3.9-1.23l.738-3.47v-.006a.31.31 0 0 1 .37-.236l2.452.49a1 1 0 1 1-.132.611l-2.14-.45-.649 3.12a7.11 7.11 0 0 1 3.85 1.23c.259-.246.6-.393.957-.405Z"
          clipRule="evenodd"
        />
        <path d="M15.305 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-4.625 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
      </svg>
    ),
    stackoverflow: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M17 20v-5h2v6.988H3V15h1.98v5H17Z" />
        <path d="m6.84 14.522 8.73 1.825.369-1.755-8.73-1.825-.369 1.755Zm1.155-4.323 8.083 3.764.739-1.617-8.083-3.787-.739 1.64Zm3.372-5.481L10.235 6.08l6.859 5.704 1.132-1.362-6.859-5.704ZM15.57 17H6.655v2h8.915v-2ZM12.861 3.111l6.193 6.415 1.414-1.415-6.43-6.177-1.177 1.177Z" />
      </svg>
    ),
    twitter: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M13.795 10.533 20.68 2h-3.073l-5.255 6.517L7.69 2H1l7.806 10.91L1.47 22h3.074l5.705-7.07L15.31 22H22l-8.205-11.467Zm-2.38 2.95L9.97 11.464 4.36 3.627h2.31l4.528 6.317 1.443 2.02 6.018 8.409h-2.31l-4.934-6.89Z" />
      </svg>
    ),
    whatsapp: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z"
          clipRule="evenodd"
        />
        <path d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z" />
      </svg>
    ),
    youtube: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    default: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm8 6a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-10a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 12 8Zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  };

  // In SidebarProfile.js, enhance the handleMessageClick function
  const handleMessageClick = async () => {
    const currentUser = auth.currentUser;

    if (!currentUser || !userData || !userData.id) {
      console.error("Missing required data to create a direct chat", {
        currentUser: currentUser ? "exists" : "missing",
        userData: userData ? "exists" : "missing",
        userId: userData?.id || "missing",
      });
      return;
    }

    try {
      console.log(
        "Attempting to create or open direct chat with:",
        userData.firstName,
        userData.lastName
      );

      // Check if a chat already exists between these users
      const chatsRef = collection(firestore, "chats");
      const q = query(
        chatsRef,
        where("participants", "array-contains", currentUser.uid)
      );

      const querySnapshot = await getDocs(q);

      let existingChat = null;

      querySnapshot.forEach((doc) => {
        const chatData = doc.data();
        if (
          chatData.participants &&
          chatData.participants.includes(userData.id)
        ) {
          existingChat = {
            id: doc.id,
            ...chatData,
          };
        }
      });

      let chatId;

      if (existingChat) {
        // Use existing chat
        chatId = existingChat.id;
        console.log("Found existing chat:", chatId);
      } else {
        // Create a new chat
        const newChatRef = doc(collection(firestore, "chats"));
        chatId = newChatRef.id;
        console.log("Creating new chat:", chatId);

        await setDoc(newChatRef, {
          participants: [currentUser.uid, userData.id],
          createdAt: serverTimestamp(),
          lastMessageTime: serverTimestamp(),
          hasMessages: false,
          unreadCount: {
            [currentUser.uid]: 0,
            [userData.id]: 0,
          },
          deletedBy: [],
        });
      }

      // Close the profile sidebar
      onToggle();

      console.log("Dispatching openDirectChat event with:", {
        chatId,
        otherUser: {
          id: userData.id,
          name: `${userData.firstName} ${userData.lastName}`,
        },
      });

      // Dispatch a custom event to notify the Chat component
      window.dispatchEvent(
        new CustomEvent("openDirectChat", {
          detail: {
            chatId,
            otherUser: {
              id: userData.id,
              name: `${userData.firstName} ${userData.lastName}`,
              firstName: userData.firstName,
              lastName: userData.lastName,
              image: userData.profileImage,
              bio: userData.bio,
              intro: userData.intro,
              city: userData.city,
              country: userData.country,
            },
          },
        })
      );
    } catch (error) {
      console.error("Error creating or opening direct chat:", error);
    }
  };

  const ownedStartups = useMemo(() => {
    if (!userData?.startups) return [];
    return userData.startups; // No filter needed, just use all startups
  }, [userData?.startups]);

  console.log("userData startups:", userData?.startups);
  console.log("ownedStartups:", ownedStartups);

  const toggleExpandText = (id) => {
    setExpandedTexts((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Function from SidebarRight.js for determining link type and icon
  const getLinkIconAndType = (url) => {
    if (!url) return { type: "default", icon: "default" };

    try {
      const parsedUrl = new URL(url.toLowerCase());
      const hostname = parsedUrl.hostname;
      if (hostname.includes("linkedin.com")) {
        return { type: "linkedin", icon: "linkedin" };
      } else if (
        hostname.includes("twitter.com") ||
        hostname.includes("x.com")
      ) {
        return { type: "twitter", icon: "twitter" };
      } else if (hostname.includes("instagram.com")) {
        return { type: "instagram", icon: "instagram" };
      } else if (hostname.includes("facebook.com")) {
        return { type: "facebook", icon: "facebook" };
      } else if (hostname.includes("youtube.com")) {
        return { type: "youtube", icon: "youtube" };
      } else if (hostname.includes("github.com")) {
        return { type: "github", icon: "github" };
      } else if (hostname.includes("apple.com")) {
        return { type: "apple", icon: "apple" };
      } else if (hostname.includes("amazon.com")) {
        return { type: "amazon", icon: "amazon" };
      } else if (
        hostname.includes("discord.com") ||
        hostname.includes("discord.gg")
      ) {
        return { type: "discord", icon: "discord" };
      } else if (hostname.includes("dribbble.com")) {
        return { type: "dribbble", icon: "dribbble" };
      } else if (hostname.includes("dropbox.com")) {
        return { type: "dropbox", icon: "dropbox" };
      } else if (hostname.includes("google.com")) {
        return { type: "google", icon: "google" };
      } else if (hostname.includes("reddit.com")) {
        return { type: "reddit", icon: "reddit" };
      } else if (hostname.includes("stackoverflow.com")) {
        return { type: "stackoverflow", icon: "stackoverflow" };
      } else if (hostname.includes("whatsapp.com")) {
        return { type: "whatsapp", icon: "whatsapp" };
      } else {
        return { type: "default", icon: "default" };
      }
    } catch (error) {
      return { type: "default", icon: "default" };
    }
  };

  // Helper function to get a nice display label for the link
  const getLinkDisplayLabel = (link) => {
    // If user provided a custom label, use that
    if (link.label && link.label.trim()) {
      return link.label;
    }

    try {
      const url = new URL(link.url);
      // Try to extract a readable name from the hostname
      let hostname = url.hostname.replace("www.", "");

      // Special cases for common domains
      if (hostname.includes("linkedin.com")) {
        const pathParts = url.pathname.split("/");
        const username = pathParts[pathParts.length - 1] || "profile";
        return `linkedin/${username}`;
      } else if (hostname.includes("github.com")) {
        const pathParts = url.pathname.split("/");
        const username = pathParts[1] || "profile";
        return `github/${username}`;
      }

      // For other sites, just return the domain name
      return hostname.split(".")[0];
    } catch (error) {
      // If URL parsing fails, return the URL as is
      return link.url;
    }
  };

  // Render a single link with the appropriate icon
  const renderLink = (link, index) => {
    if (!link || !link.url) return null;

    const { icon } = getLinkIconAndType(link.url);
    const displayLabel = getLinkDisplayLabel(link);

    // For unknown links, use the Material Icons "language" icon
    if (icon === "default") {
      return (
        <div className={styles.links} key={`link-${index}`}>
          <a
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <div className={styles.linkIcon}>
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  color: "gray",
                }}
              >
                language
              </span>
            </div>
            <span className={styles.linkText}>{displayLabel}</span>
          </a>
        </div>
      );
    }

    // For known social media platforms, use the custom SVG icons
    const IconComponent = SocialIcons[icon];

    return (
      <div className={styles.links} key={`link-${index}`}>
        <a
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          <div className={styles.linkIcon}>
            <IconComponent />
          </div>
          <span className={styles.linkText}>{displayLabel}</span>
        </a>
      </div>
    );
  };

  const renderTruncatedText = (text, id) => {
    if (!text) return null;
    const isExpanded = expandedTexts[id];

    if (text.length <= MAX_LENGTH) {
      return <p className={styles.description}>{text}</p>;
    }

    if (isExpanded) {
      return (
        <p className={styles.description}>
          {text}
          <span
            className={styles.moreButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleExpandText(id);
            }}
          >
            Show less
          </span>
        </p>
      );
    }

    const truncatedText = text.slice(0, MAX_LENGTH).trim();
    return (
      <p className={styles.description}>
        {truncatedText}
        <span
          className={styles.moreButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleExpandText(id);
          }}
        >
          ...more
        </span>
      </p>
    );
  };

  // Sort experiences by date (most recent first)
  const sortedExperiences = useMemo(() => {
    if (!userData?.experiences) return [];
    return [...userData.experiences].sort((a, b) => {
      const aEndDate = a.isCurrentlyWorking
        ? Date.now()
        : new Date(`${a.endMonth} 1, ${a.endYear}`).getTime();
      const bEndDate = b.isCurrentlyWorking
        ? Date.now()
        : new Date(`${b.endMonth} 1, ${b.endYear}`).getTime();

      if (aEndDate !== bEndDate) {
        return bEndDate - aEndDate;
      }

      const aStartDate = new Date(
        `${a.startMonth} 1, ${a.startYear}`
      ).getTime();
      const bStartDate = new Date(
        `${b.startMonth} 1, ${b.startYear}`
      ).getTime();
      return bStartDate - aStartDate;
    });
  }, [userData?.experiences]);

  // Sort education by date (most recent first)
  const sortedEducation = useMemo(() => {
    if (!userData?.education) return [];
    return [...userData.education].sort((a, b) => {
      const now = new Date();
      const aEndDate = a.isCurrentlyStudying
        ? now
        : new Date(`${a.endMonth} 1, ${a.endYear}`);
      const bEndDate = b.isCurrentlyStudying
        ? now
        : new Date(`${b.endMonth} 1, ${b.endYear}`);
      return bEndDate - aEndDate;
    });
  }, [userData?.education]);

  // Get links in the format needed - similar to SidebarRight.js
  const getLinksToRender = (userLinksArray, fallbackLink, fallbackLabel) => {
    let linksToRender = [];

    if (userLinksArray && userLinksArray.length > 0) {
      // New format - array of link objects
      linksToRender = userLinksArray;
    } else if (fallbackLink) {
      // Old format - single link
      linksToRender = [
        {
          url: fallbackLink,
          label: fallbackLabel || fallbackLink,
        },
      ];
    }

    return linksToRender;
  };

  return (
    <aside className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}>
      {/* Simple close button with clear positioning */}
      <button className={styles.closeBtn} onClick={onToggle}>
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "1.25rem", color: "gray" }}
        >
          keyboard_double_arrow_right
        </span>
      </button>

      {/* The header with the message button */}
      <div className={styles.sidebarHeader}>
        <div className={styles.actionButtons}>
          {/* <button
            className={styles.iconButton}
            onClick={handleMessageClick}
          ></button> */}
        </div>
      </div>

      <div className={styles.topHalf}>
        <div className={styles.profileSection}>
          <img
            src={userData.profileImage}
            alt="profile"
            className={styles.profileImage}
          />
          <h2 className={styles.profileName}>
            {userData.firstName} {userData.lastName}
          </h2>
        </div>

        {userData.bio && <p className={styles.bio}>{userData.bio}</p>}

        {(userData.city || userData.country) && (
          <div className={styles.infoRow}>
            {[userData.city, userData.country].filter(Boolean).join(", ")}
          </div>
        )}

        {/* Render multiple links - using the same structure as SidebarRight.js */}
        {getLinksToRender(userData.links, userData.link, userData.linkText)
          .length > 0 && (
          <div className={styles.linksContainer}>
            {getLinksToRender(
              userData.links,
              userData.link,
              userData.linkText
            ).map((link, index) => renderLink(link, index))}
          </div>
        )}

        {userData.intro && <p className={styles.intro}>{userData.intro}</p>}

        {/* Owned Startups Section */}
        {/* {ownedStartups.length > 0 && (
          <>
            {ownedStartups.map((startup) => (
              <div key={startup.id} className={styles.section}>
                <div
                  style={{
                    borderTop: "0.05rem solid lightgray",
                    marginRight: "1rem",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                ></div>

                <div style={{ marginRight: "0" }}>
                  <img
                    src={
                      startup.startupImage === "default.jpg"
                        ? "https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/startupImage.jpg?alt=media&token=194ae584-4000-41e3-a2ee-9c2c5f438d95"
                        : startup.startupImage || "/api/placeholder/400/320"
                    }
                    alt={startup.name}
                    className={styles.startupImage}
                  />
                  <h2 className={styles.profileName}>{startup.name}</h2>

                  {startup.bio && <p className={styles.bio}>{startup.bio}</p>}

                  {(startup.city || startup.country) && (
                    <div className={styles.infoRow}>
                      {[startup.city, startup.country]
                        .filter(Boolean)
                        .join(", ")}
                    </div>
                  )}

                  {getLinksToRender(
                    startup.links,
                    startup.link,
                    startup.linkText
                  ).length > 0 && (
                    <div className={styles.linksContainer}>
                      {getLinksToRender(
                        startup.links,
                        startup.link,
                        startup.linkText
                      ).map((link, index) => renderLink(link, index))}
                    </div>
                  )}

                  {startup.intro && (
                    <p className={styles.intro}>{startup.intro}</p>
                  )}
                </div>
              </div>
            ))}
          </>
        )} */}
      </div>
      <div className={styles.footer}>
        <a
          href="#"
          className={styles.footerButton}
          onClick={(e) => {
            e.preventDefault();
            handleMessageClick();
          }}
        >
          <div className={styles.linkIcon}>
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "1.25rem",
                color: "white",
                transform: "rotate(45deg)",
              }}
            >
              arrow_upward
            </span>
          </div>
          <span className={styles.linkText}>Message</span>
        </a>
      </div>
    </aside>
  );
};

export default SidebarProfile;
