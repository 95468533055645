// SuggestionCarousel.js
import { useState, useEffect, useRef } from "react";
import styles from "./SuggestionCarousel.module.css";

const SuggestionCarousel = ({ suggestions = [], onSuggestionSelect }) => {
  const [extendedSuggestions, setExtendedSuggestions] = useState([]);
  const carouselRef = useRef(null);

  // Setup initial suggestions array on component mount
  useEffect(() => {
    if (suggestions.length > 0) {
      // Duplicate the suggestions multiple times to ensure smooth looping
      let duplicated = [];
      for (let i = 0; i < 6; i++) {
        duplicated = [...duplicated, ...suggestions];
      }
      setExtendedSuggestions(duplicated);
    } else {
      setExtendedSuggestions([]);
    }
  }, [suggestions]);

  // Set up continuous rightward movement with looping
  useEffect(() => {
    if (extendedSuggestions.length === 0 || !carouselRef.current) return;

    // Animation speed - pixels per second (lower = slower)
    const pixelsPerSecond = 40;

    let lastTimestamp = 0;
    let animationFrameId;

    // Start with negative position to have items already visible at beginning
    let currentPosition = -1000; // Start with items already visible

    // Calculate width of a full cycle of original suggestions
    const calculateCycleWidth = () => {
      if (!carouselRef.current) return 2000;

      const buttons = carouselRef.current.querySelectorAll(
        `.${styles.suggestionButton}`
      );
      if (!buttons.length) return 2000;

      // Only measure width of one cycle (original suggestions length)
      const cycleBtnCount = Math.min(buttons.length, suggestions.length);
      let totalWidth = 0;

      for (let i = 0; i < cycleBtnCount; i++) {
        const btnWidth = buttons[i].offsetWidth;
        const btnMargin =
          parseInt(window.getComputedStyle(buttons[i]).marginRight) || 16;
        totalWidth += btnWidth + btnMargin;
      }

      return totalWidth || 2000;
    };

    let cycleWidth = calculateCycleWidth();

    const animate = (timestamp) => {
      if (!lastTimestamp) lastTimestamp = timestamp;
      const elapsed = timestamp - lastTimestamp;

      // Calculate movement based on elapsed time
      const pixelsToMove = (pixelsPerSecond * elapsed) / 1000;

      // Update position (POSITIVE for rightward movement)
      currentPosition += pixelsToMove;

      // Apply the new position
      if (carouselRef.current) {
        carouselRef.current.style.transform = `translateX(${currentPosition}px)`;
      }

      // Loop animation when we've scrolled one cycle
      if (currentPosition > 0) {
        // Reset to negative position when we've scrolled too far right
        currentPosition = -cycleWidth;

        // Immediately apply the new position to avoid visual jump
        if (carouselRef.current) {
          carouselRef.current.style.transform = `translateX(${currentPosition}px)`;
        }
      }

      lastTimestamp = timestamp;
      animationFrameId = requestAnimationFrame(animate);
    };

    // Handle window resize to recalculate cycle width
    const handleResize = () => {
      cycleWidth = calculateCycleWidth();
    };

    window.addEventListener("resize", handleResize);

    // Start animation
    animationFrameId = requestAnimationFrame(animate);

    // Cleanup
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, [extendedSuggestions, suggestions.length]);

  if (extendedSuggestions.length === 0) {
    return null;
  }

  return (
    <div className={styles.suggestionCarousel}>
      <div className={styles.carouselWrapper}>
        <div className={styles.carouselContainer} ref={carouselRef}>
          {extendedSuggestions.map((suggestion, index) => (
            <button
              key={`suggestion-${index}`}
              className={styles.suggestionButton}
              onClick={() => onSuggestionSelect(suggestion)}
            >
              {suggestion}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SuggestionCarousel;
