import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import {
  requestNotificationPermission,
  onMessageListener,
  isInAppBrowser,
  isLinkedInBrowser,
} from "../firebase";

const FCMContext = createContext();

export const useFCM = () => useContext(FCMContext);

export const FCMProvider = ({ children }) => {
  const [pushNotification, setPushNotification] = useState(null);
  const [isSupported, setIsSupported] = useState(false);
  const { currentUser } = useAuth();

  // Check if notifications are supported in this environment
  useEffect(() => {
    const checkSupport = () => {
      const hasNotificationAPI = typeof Notification !== "undefined";
      const hasServiceWorker = "serviceWorker" in navigator;

      // Check if we're in a compatible browser
      const isCompatible =
        hasNotificationAPI &&
        hasServiceWorker &&
        !isInAppBrowser() &&
        !isLinkedInBrowser();

      setIsSupported(isCompatible);
    };

    checkSupport();
  }, []);

  useEffect(() => {
    const setupFCM = async () => {
      try {
        // Only proceed if we have an authenticated user AND notifications are supported
        if (currentUser?.uid && isSupported) {
          // Wait a bit to ensure Firebase Auth is fully initialized
          await new Promise((resolve) => setTimeout(resolve, 1000));

          await requestNotificationPermission();

          // Listen for foreground messages
          onMessageListener()
            .then((payload) => {
              if (payload) {
                setPushNotification(payload);
              }
            })
            .catch((err) => {
              console.error("Error listening for messages:", err);
            });
        }
      } catch (error) {
        console.log(
          "FCM setup error (safe to ignore in some environments):",
          error
        );
      }
    };

    setupFCM();
  }, [currentUser, isSupported]);

  const value = {
    pushNotification,
    setPushNotification,
    isSupported,
  };

  return <FCMContext.Provider value={value}>{children}</FCMContext.Provider>;
};

export default FCMProvider;
