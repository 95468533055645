import "./index.css";
import reportWebVitals from "./reportWebVitals";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import { ChatProvider } from "./contexts/ChatContext";
import { BrowserRouter as Router } from "react-router-dom";

// Add this error handler before any React rendering
window.addEventListener("error", function (event) {
  // Prevent any error in Firebase from crashing the app
  if (
    event.error &&
    event.error.message &&
    (event.error.message.includes("firebase") ||
      event.error.message.includes("messaging") ||
      event.error.message.includes("notification"))
  ) {
    event.preventDefault();
    console.warn("Suppressed Firebase error:", event.error);
  }
});

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ChatProvider>
        <Router>
          <App />
        </Router>
      </ChatProvider>
    </AuthProvider>
  </React.StrictMode>
);
