import React, { useState, useRef, useEffect } from "react";
import {
  doc,
  updateDoc,
  serverTimestamp,
  writeBatch,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { convertUrlsToLinks } from "../../utils/textUtils";
import DayDropdown from "../Dropdowns/DayDropdown";
import { firestore } from "../../firebase";
import classes from "./EditSingleStartupModal.module.css";
import defaultImage from "../../assets/images/startupImage.jpg";
import imageCompression from "browser-image-compression";
import Cropper from "react-easy-crop";
import MonthDropdown from "../Dropdowns/MonthDropdown";
import YearDropdown from "../Dropdowns/YearDropdown";
import CompanyTypeDropdown from "../Dropdowns/CompanyTypeDropdown";
import CustomDropdown from "../Dropdowns/CustomDropdown";
import monthsList from "../../utils/monthsList";
import { updateStartupUrlSlug } from "../../utils/startupUrlUtils";

const config = {
  cUrl: "https://api.countrystatecity.in/v1",
  ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
};

const EditSingleStartupModal = ({
  startup,
  onClose,
  onUpdate,
  currentUser,
  isFromStartupPage = false,
}) => {
  const initialState = {
    startupName: startup?.startupName || "",
    role: startup?.role || "",
    industry: startup?.industry || "",
    companyType: startup?.companyType || null,
    bio: startup?.bio || "",
    intro: startup?.intro || "", // Add this line
    link: startup?.link || "",
    linkText: startup?.linkText || "",
    country: startup?.country
      ? { name: startup.country, iso2: startup.countryISO2 }
      : null,
    city: startup?.city ? { name: startup.city } : null,
    monthFounded: startup?.monthFounded
      ? monthsList.indexOf(startup.monthFounded) + 1
      : null,
    yearFounded: startup?.yearFounded ? startup.yearFounded.toString() : null,
    startupImage: startup?.startupImage || "",
  };
  const [loadingDots, setLoadingDots] = useState(1);
  const [dayFounded, setDayFounded] = useState(startup.dayFounded || null);
  const [startupName, setStartupName] = useState(initialState.startupName);
  const [role, setRole] = useState(initialState.role);
  const [industry, setIndustry] = useState(initialState.industry);
  const [companyType, setCompanyType] = useState(initialState.companyType);
  const [bio, setBio] = useState(initialState.bio);
  const [link, setLink] = useState(initialState.link);
  const [linkText, setLinkText] = useState(initialState.linkText);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(initialState.country);
  const [selectedCity, setSelectedCity] = useState(initialState.city);
  const [countryInputValue, setCountryInputValue] = useState(
    initialState.country?.name || ""
  );
  const [intro, setIntro] = useState(startup?.intro || "");
  const [cityInputValue, setCityInputValue] = useState(
    initialState.city?.name || ""
  );
  const [monthFounded, setMonthFounded] = useState(initialState.monthFounded);
  const [yearFounded, setYearFounded] = useState(initialState.yearFounded);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(!!initialState.link);
  const [startupImage, setStartupImage] = useState(null);
  const [currentStartupImageUrl, setCurrentStartupImageUrl] = useState(
    initialState.startupImage
  );
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const formRef = useRef(null);
  const cityCache = useRef({});
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const [links, setLinks] = useState(
    startup?.links && startup.links.length > 0
      ? startup.links
      : startup?.link
      ? [{ url: startup.link, label: startup.linkText || "" }]
      : [{ url: "", label: "" }]
  );
  const [linkErrors, setLinkErrors] = useState(Array(links.length).fill(""));

  const validateUrl = (string) => {
    if (!string) return true; // Empty string is considered valid (optional)
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleLinkChange = (index, field, value) => {
    const newLinks = [...links];
    newLinks[index][field] = value;
    setLinks(newLinks);

    // Validate URL
    const newErrors = [...linkErrors];
    if (field === "url") {
      newErrors[index] = validateUrl(value) ? "" : "Please enter a valid URL";
    }
    setLinkErrors(newErrors);
  };

  const addLink = () => {
    setLinks([...links, { url: "", label: "" }]);
    setLinkErrors([...linkErrors, ""]);
  };

  const removeLink = (index) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);

    const newErrors = [...linkErrors];
    newErrors.splice(index, 1);
    setLinkErrors(newErrors);

    // Always keep at least one link field
    if (newLinks.length === 0) {
      setLinks([{ url: "", label: "" }]);
      setLinkErrors([""]);
    }
  };

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setLoadingDots((prev) => (prev % 3) + 1);
      }, 500);
    }
    return () => clearInterval(interval);
  }, [loading]);

  // 3. Create a function to render the loading symbols
  const renderLoadingSymbols = () => {
    if (loadingDots === 1) return ">";
    if (loadingDots === 2) return "> >";
    return "> > >";
  };

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  // Form validation effect
  useEffect(() => {
    const isAllFieldsFilled = startupName.trim() !== "";
    setIsFormValid(isAllFieldsFilled);
  }, [startupName]);

  // Change detection effect
  // Update the change detection useEffect to check for links changes:

  useEffect(() => {
    // Convert initial links for comparison
    const initialLinks =
      startup?.links && startup.links.length > 0
        ? startup.links
        : startup?.link
        ? [{ url: startup.link, label: startup.linkText || "" }]
        : [{ url: "", label: "" }];

    // Check if links have changed
    const linksChanged =
      links.length !== initialLinks.length ||
      links.some((link, index) => {
        return (
          !initialLinks[index] ||
          link.url !== initialLinks[index].url ||
          link.label !== initialLinks[index].label
        );
      });

    const hasChanged =
      startupName !== initialState.startupName ||
      role !== initialState.role ||
      industry !== initialState.industry ||
      companyType !== initialState.companyType ||
      bio !== initialState.bio ||
      intro !== initialState.intro ||
      linksChanged ||
      monthFounded !== initialState.monthFounded ||
      yearFounded !== initialState.yearFounded ||
      JSON.stringify(selectedCountry) !==
        JSON.stringify(initialState.country) ||
      JSON.stringify(selectedCity) !== JSON.stringify(initialState.city) ||
      startupImage !== null;

    setIsChanged(hasChanged);
  }, [
    startupName,
    role,
    industry,
    companyType,
    bio,
    intro,
    links,
    monthFounded,
    yearFounded,
    selectedCountry,
    selectedCity,
    startupImage,
  ]);

  // Load countries effect
  useEffect(() => {
    const loadCountries = async () => {
      try {
        const response = await fetch(`${config.cUrl}/countries`, {
          headers: { "X-CSCAPI-KEY": config.ckey },
        });
        const data = await response.json();
        setCountries(Array.isArray(data) ? data : []);
      } catch (error) {
        setCountries([]);
      }
    };

    loadCountries();
  }, []);

  // Load cities effect
  useEffect(() => {
    const loadCities = async () => {
      if (!selectedCountry) return;

      if (cityCache.current[selectedCountry.iso2]) {
        setCities(cityCache.current[selectedCountry.iso2]);
        return;
      }

      try {
        const response = await fetch(
          `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
          {
            headers: { "X-CSCAPI-KEY": config.ckey },
          }
        );
        const data = await response.json();
        const citiesList = Array.isArray(data) ? data : [];
        cityCache.current[selectedCountry.iso2] = citiesList;
        setCities(citiesList);
      } catch (error) {
        setCities([]);
      }
    };

    loadCities();
  }, [selectedCountry]);

  const handleStartupNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setStartupName(e.target.value);
    }
  };

  const handleIntroChange = (e) => {
    if (e.target.value.length <= 2500) {
      setIntro(e.target.value);
    }
  };

  const handleRoleChange = (e) => {
    if (e.target.value.length <= 50) {
      setRole(e.target.value);
    }
  };

  const handleIndustryChange = (e) => {
    if (e.target.value.length <= 50) {
      setIndustry(e.target.value);
    }
  };

  const handleBioChange = (e) => {
    if (e.target.value.length <= 250) {
      setBio(e.target.value);
    }
  };

  const handleLinkTextChange = (e) => {
    if (e.target.value.length <= 50) {
      setLinkText(e.target.value);
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCityInputValue("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleStartupImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 8,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onload = () => {
          setCurrentStartupImageUrl(reader.result);
          setIsCropping(true);
        };
        reader.readAsDataURL(compressedFile);
        setStartupImage(compressedFile);
        e.target.value = "";
      } catch (error) {
        setError("Failed to process the image. Please try again.");
      }
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  };

  // In EditSingleStartupModal.js, modify the handleSubmit function:

  // Modified handleSubmit function for EditSingleStartupModal.js
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid) {
      setError("Please fill in all required fields.");
      return;
    }

    // Validate all links
    const urlErrors = links.map((link) =>
      validateUrl(link.url) ? "" : "Please enter a valid URL"
    );
    if (urlErrors.some((error) => error)) {
      setLinkErrors(urlErrors);
      setError("Please fix the errors in your links.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      // Force the startupName to be trimmed and non-empty
      const finalStartupName = startupName.trim() || initialState.startupName;

      console.log("Form submission - Current values:", {
        startupName: finalStartupName,
        bio,
        intro,
        links,
        country: selectedCountry,
        city: selectedCity,
      });

      let newStartupImageUrl = currentStartupImageUrl;

      // Handle image update if there's a new image
      if (startupImage && croppedAreaPixels) {
        // Delete old image if it exists and isn't the default image
        if (
          initialState.startupImage &&
          !initialState.startupImage.includes("startupImage.jpg")
        ) {
          try {
            const storage = getStorage();
            const imageUrl = new URL(initialState.startupImage);
            const imagePath = decodeURIComponent(
              imageUrl.pathname.split("/o/")[1].split("?")[0]
            );
            const oldImageRef = ref(storage, imagePath);
            await deleteObject(oldImageRef);
          } catch (error) {
            console.error("Error deleting old image:", error);
          }
        }

        // Upload new image using the same path structure as AddStartupModal
        const croppedImage = await getCroppedImg(
          currentStartupImageUrl,
          croppedAreaPixels
        );
        const response = await fetch(croppedImage);
        const blob = await response.blob();

        const storage = getStorage();
        const startupImageRef = ref(
          storage,
          `startupImages/${currentUser.uid}/${
            startup.id
          }/profile/${Date.now()}.jpg`
        );
        await uploadBytes(startupImageRef, blob);
        newStartupImageUrl = await getDownloadURL(startupImageRef);
      }

      const userDocRef = doc(firestore, "users", currentUser.uid);
      const startupDocRef = doc(userDocRef, "startups", startup.id);

      // Update URL slug if startup name changed
      let startupUrlSlug = startup.startupUrlSlug;
      if (finalStartupName !== initialState.startupName) {
        startupUrlSlug = await updateStartupUrlSlug(
          currentUser.uid,
          startup.id,
          finalStartupName
        );
      }

      const trimmedIntro = intro.replace(/\s+$/g, "");
      const introHtml = convertUrlsToLinks(trimmedIntro);

      // Filter out empty links
      const validLinks = links.filter((link) => link.url.trim() !== "");

      // For backward compatibility - take the first link if present
      const firstLink = validLinks.length > 0 ? validLinks[0] : null;

      // Create a clean object with all the updated fields
      const updatedStartup = {
        startupName: finalStartupName,
        name: finalStartupName, // Set both name fields
        role: role?.trim() || null,
        industry: industry?.trim() || null,
        companyType: companyType || null,
        bio: bio?.trim() || null,
        intro: trimmedIntro,
        introHtml,
        // For backward compatibility
        link: firstLink ? firstLink.url : "",
        linkText: firstLink ? firstLink.label : "",
        // New multiple links array
        links: validLinks,
        country: selectedCountry?.name || null,
        countryISO2: selectedCountry?.iso2 || null,
        city: selectedCity?.name || null,
        monthFounded: monthFounded ? monthsList[monthFounded - 1] : null,
        yearFounded: yearFounded ? Number(yearFounded) : null,
        startupImage: newStartupImageUrl || null,
        startupUrlSlug,
        updatedAt: new Date().toISOString(),
      };

      console.log("About to update Firestore with:", updatedStartup);

      // Update Firestore - this might take time to propagate
      await setDoc(startupDocRef, updatedStartup, { merge: true });

      // Verify the update by fetching the document again
      const updatedDoc = await getDoc(startupDocRef);

      if (!updatedDoc.exists()) {
        throw new Error("Failed to verify update in Firestore");
      }

      // Get the updated data from Firestore to confirm changes
      const updatedData = updatedDoc.data();
      console.log("Verified updated data in Firestore:", updatedData);

      if (updatedData.startupName !== finalStartupName) {
        console.warn(
          "Name verification failed - Firestore:",
          updatedData.startupName,
          "Local:",
          finalStartupName
        );
        // Try updating one more time with a small delay
        await new Promise((resolve) => setTimeout(resolve, 500));
        await setDoc(
          startupDocRef,
          { startupName: finalStartupName, name: finalStartupName },
          { merge: true }
        );

        // Final verification
        const finalCheck = await getDoc(startupDocRef);
        console.log("Final verification data:", finalCheck.data());
      }

      // Create the complete updated startup object with BOTH name properties
      const completeUpdatedStartup = {
        id: startup.id,
        ...startup,
        ...updatedStartup,
        startupName: finalStartupName,
        name: finalStartupName,
        membershipType: startup.membershipType || "owner",
        _localUpdateTime: Date.now(), // Mark as recently updated locally
      };

      console.log("Calling onUpdate with:", completeUpdatedStartup);

      // Immediately pass the updated data to the parent component
      onUpdate(completeUpdatedStartup);

      // Only now close the modal after verifying the update
      onClose(true);
    } catch (error) {
      console.error("Error updating startup:", error);
      setError("Failed to update startup. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Modified button onClick handler in the card footer
  <button
    type="button"
    className={classes.button}
    disabled={!isFormValid || !isChanged || loading}
    onClick={() => {
      if (formRef.current) {
        formRef.current.dispatchEvent(
          new Event("submit", { bubbles: true, cancelable: true })
        );
        // The modal will be closed in handleSubmit after Firestore confirms the update
      }
    }}
  >
    {loading ? "saving..." : "save"}
  </button>;

  return (
    <div className={classes.modalBackdrop}>
      <div className={classes.modalContent}>
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            a
            <div
              className={classes.closeIcon}
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <span class="material-symbols-outlined">close</span>
            </div>
          </div>
        </div>

        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
          style={{ opacity: loading ? 0.5 : 1 }}
        >
          <div className={classes.imageContainer}>
            <div className={classes.profileImage}>
              {currentStartupImageUrl && isCropping ? (
                <div className={classes.cropContainer}>
                  <Cropper
                    image={currentStartupImageUrl}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    showGrid={false}
                    cropSize={{ width: 96, height: 96 }}
                    objectFit="cover"
                  />
                </div>
              ) : (
                <img
                  src={currentStartupImageUrl || defaultImage}
                  alt="Startup"
                  className={classes.startupImage}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = defaultImage;
                  }}
                />
              )}
            </div>
            <button
              type="button"
              className={classes.uploadButton}
              onClick={() => document.getElementById("startupImage").click()}
            >
              + add image
            </button>
          </div>
          <input
            type="file"
            id="startupImage"
            accept="image/*"
            onChange={handleStartupImageChange}
            className={classes.profileButton}
          />

          <input
            id="startupName"
            name="startupName"
            type="text"
            placeholder="name (ex: airbnb)"
            className={classes.userInput}
            value={startupName}
            onChange={handleStartupNameChange}
            disabled={loading}
          />

          <textarea
            id="bio"
            value={bio}
            onChange={handleBioChange}
            className={classes.textArea}
            placeholder="headline (ex: belong anywhere.)"
          />

          <CustomDropdown
            id="country"
            options={countries}
            selectedValue={selectedCountry}
            onChange={handleCountryChange}
            inputValue={countryInputValue}
            setInputValue={setCountryInputValue}
            disabled={loading}
            placeholder="country (ex: united states)"
          />
          <div className={classes.space}></div>

          <CustomDropdown
            id="city"
            options={cities}
            selectedValue={selectedCity}
            onChange={handleCityChange}
            inputValue={cityInputValue}
            setInputValue={setCityInputValue}
            disabled={!selectedCountry || loading}
            placeholder="city (ex: san francisco)"
          />
          <div className={classes.space}></div>
          <div className={classes.linksSection}>
            <div className={classes.linksSectionHeader}>
              <button
                type="button"
                className={classes.addLinkButton}
                onClick={addLink}
                disabled={loading}
              >
                + add link
              </button>
            </div>

            {links.map((link, index) => (
              <div key={index} className={classes.linkContainer}>
                <div className={classes.linkInputs}>
                  <input
                    type="url"
                    placeholder="url (ex: https://linkedin.com/in/username)"
                    className={`${classes.linkInput} ${
                      linkErrors[index] ? classes.inputError : ""
                    }`}
                    value={link.url}
                    onChange={(e) =>
                      handleLinkChange(index, "url", e.target.value)
                    }
                    disabled={loading}
                  />
                  <input
                    type="text"
                    placeholder="label (ex: linkedin)"
                    className={classes.linkInput}
                    value={link.label}
                    onChange={(e) =>
                      handleLinkChange(index, "label", e.target.value)
                    }
                    disabled={loading || !link.url}
                  />
                </div>
                {linkErrors[index] && (
                  <div className={classes.errorText}>{linkErrors[index]}</div>
                )}
                {links.length > 1 && (
                  <button
                    type="button"
                    className={classes.removeLinkButton}
                    onClick={() => removeLink(index)}
                    disabled={loading}
                  >
                    - remove
                  </button>
                )}
              </div>
            ))}
          </div>
          <div
            className={`${classes.smallPrint} ${
              !isValidUrl ? classes.disabledInput : ""
            }`}
          ></div>
          <textarea
            id="intro"
            value={intro}
            onChange={handleIntroChange}
            className={classes.textArea}
            style={{
              opacity: loading ? 0.5 : 1,
              minHeight: "16.25rem",
            }}
            disabled={loading}
            placeholder={
              "tell us about your page (e.g. startup, accelerator, incubator, community, event, program, etc.), and what it does.\n\n- what makes this unique?\n\n- who's it for?\n\n- what stage/phase?\n\n- key technologies or approaches.\n\n- what you're looking for."
            }
          />
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <button
            type="button"
            className={`${classes.button} ${loading ? classes.loading : ""}`}
            disabled={!isFormValid || !isChanged || loading}
            onClick={() => {
              if (formRef.current) {
                formRef.current.dispatchEvent(
                  new Event("submit", { bubbles: true, cancelable: true })
                );
              }
            }}
          >
            {loading ? renderLoadingSymbols() : "save changes"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSingleStartupModal;
