// Chat.js
import { useState, useEffect, useRef, useCallback } from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import SidebarRight from "../../../components/Sidebar/SidebarRight";
import SidebarProfile from "../../../components/Sidebar/SidebarProfile";
import styles from "./Chat.module.css";
import UserCarousel from "./UserCarousel";
import SuggestionCarousel from "./SuggestionCarousel";
import logo from "../../../assets/images/foundry.png";

import { firestore, auth } from "../../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  addDoc,
  where,
  serverTimestamp,
  query,
  writeBatch,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import defaultImage from "../../../assets/icons/profileImage.jpg";

const defaultSvgImage =
  "https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/userImage.png?alt=media&token=e47b51db-e00b-48c8-8923-fdee9a181589";

const placeholders = [
  "Help me find people who like hiking",
  "Who's interested in photography near me?",
  "Looking for web developers in chicago",
  "Show me experts in digital marketing",
  "Who's working on climate change projects?",
  "Connect me with designers in my area",
  "Find people with knowledge about gardening",
  "Anyone teaching piano lessons?",
  "People interested in book clubs near me",
  "Who can help with learning Spanish?",
];

// Generic placeholders for direct chat
const directChatPlaceholders = [
  "What are you passionate about these days?",
  "I'd love to hear about your interests",
  "What projects are you currently working on?",
  "How did you get started with your hobby?",
  "Would you be interested in collaborating?",
  "I found your profile interesting because...",
  "Do you have any recommendations for...",
  "I'd be grateful for your advice on...",
  "Have you discovered any great resources lately?",
  "I'd like to learn more about your experience with...",
];

// Generic placeholders for discover mode
const discoverPlaceholders = [
  "Just learned something interesting about...",
  "Looking for people to join my...",
  "Anyone interested in discussing...",
  "Sharing my thoughts on...",
  "Seeking advice about...",
  "Excited to meet people who...",
  "Would love feedback on my...",
  "Organizing an event about...",
  "Recently started exploring...",
  "Asking for recommendations on...",
];

const Chat = () => {
  const [discoverMode, setDiscoverMode] = useState(false);
  const [posts, setPosts] = useState([]);
  const [typingStage, setTypingStage] = useState(0);
  const [isTypingTransitioning, setIsTypingTransitioning] = useState(false);
  const messagesContainerRef = useRef(null);
  const [directChat, setDirectChat] = useState(null);
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [isProfileCardOpen, setIsProfileCardOpen] = useState(false);
  const [matchedUsers, setMatchedUsers] = useState([]);
  const [userData, setUserData] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 960);
  const [isProfileSidebarOpen, setIsProfileSidebarOpen] = useState(false);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [currentPlaceholder, setCurrentPlaceholder] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Check if using mobile device based on user agent
      const isMobile =
        /android|webos|iphone|ipad|ipod|blackberry|IEMobile|Opera Mini/i.test(
          userAgent
        );

      // Also consider screen width (anything under 768px is likely mobile or tablet)
      const isSmallScreen = window.innerWidth < 768;

      setIsMobileDevice(isMobile || isSmallScreen);
    };

    // Check on initial load
    checkMobile();

    // Also check when window is resized
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const handleKeyPress = (e) => {
    // If Enter is pressed without Shift and not on mobile
    if (e.key === "Enter" && !e.shiftKey && !isMobileDevice) {
      e.preventDefault(); // Prevent default behavior (new line)
      handleSubmit(e); // Submit the form
    }
  };

  // Add this new state variable for the AI image
  const aiImage =
    "https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/foundry.png?alt=media&token=050d8cd0-b482-4154-b448-ae4236850bbf";

  const [currentUser, setCurrentUser] = useState(null);
  const [currentChatId, setCurrentChatId] = useState(null);
  const textareaRef = useRef(null);

  const streamOpenAICompletion = async (messages, options = {}) => {
    try {
      const idToken = await auth.currentUser.getIdToken();

      // Replace with your actual function URL - you'll get this after deploying
      const functionUrl =
        "https://europe-west1-foundry-2024.cloudfunctions.net/streamOpenAI";

      const response = await fetch(functionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          model: options.model || "gpt-4o-mini",
          messages,
          temperature: options.temperature || 0.7,
          max_tokens: options.max_tokens || 500,
          presence_penalty: options.presence_penalty || 1.0,
          frequency_penalty: options.frequency_penalty || 1.0,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error connecting to OpenAI: ${response.status}`);
      }

      return response.body;
    } catch (error) {
      console.error("Error in streaming OpenAI:", error);
      throw error;
    }
  };

  const toggleLeftSidebar = () => {
    if (!isSidebarOpen) {
      setIsProfileSidebarOpen(false);
      setIsProfileCardOpen(false);
      setIsSidebarOpen(true);
    } else {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (!isLoading) return;

    const intervalId = setInterval(() => {
      setIsTypingTransitioning(true);
      setTimeout(() => {
        setTypingStage((prev) => (prev + 1) % 4); // 4 stages: empty, >, >>, >>>
        setIsTypingTransitioning(false);
      }, 200);
    }, 500); // 500ms interval like in Age.js

    return () => clearInterval(intervalId);
  }, [isLoading]);

  // Add this to your Chat component, near the start
  useEffect(() => {
    const handleChatSelected = (e) => {
      // Wait for DOM updates to complete
      setTimeout(forceScrollToBottom, 100);
      setTimeout(forceScrollToBottom, 300);
      setTimeout(forceScrollToBottom, 500);
    };

    // Listen for clicks on chat buttons in the sidebar
    document.addEventListener("click", handleChatSelected);

    return () => {
      document.removeEventListener("click", handleChatSelected);
    };
  }, []);

  // Add this new function to your component
  const forceScrollToBottom = () => {
    if (!messagesContainerRef.current) return;

    // First approach - direct property setting
    messagesContainerRef.current.scrollTop = 999999;

    // Second approach - manual animation
    const startTime = Date.now();
    const duration = 50; // very quick
    const container = messagesContainerRef.current;
    const targetScroll = container.scrollHeight;

    const animateScroll = () => {
      const currentTime = Date.now();
      const timeElapsed = currentTime - startTime;

      if (timeElapsed < duration) {
        container.scrollTop = targetScroll;
        requestAnimationFrame(animateScroll);
      } else {
        container.scrollTop = targetScroll;
      }
    };

    requestAnimationFrame(animateScroll);

    // Third approach - with delayed retry
    setTimeout(() => {
      if (container) {
        container.scrollTop = container.scrollHeight + 10000;
      }
    }, 100);

    // Fourth approach - after all content should be loaded and rendered
    setTimeout(() => {
      if (container) {
        container.scrollTop = container.scrollHeight + 10000;
      }
    }, 500);
  };

  const toggleRightSidebar = () => {
    if (!isProfileSidebarOpen) {
      setIsSidebarOpen(false);
      setIsProfileCardOpen(false);
      setIsProfileSidebarOpen(true);
    } else {
      setIsProfileSidebarOpen(false);
    }
  };

  const toggleProfileCard = () => {
    if (!isProfileCardOpen) {
      setIsSidebarOpen(false);
      setIsProfileSidebarOpen(false);
      setIsProfileCardOpen(true);
    } else {
      setIsProfileCardOpen(false);
    }
  };

  // And just to ensure complete consistency, update the useEffect that watches isSidebarOpen:
  useEffect(() => {
    if (isSidebarOpen) {
      setIsProfileSidebarOpen(false);
      setIsProfileCardOpen(false);
    }
  }, [isSidebarOpen]);

  // Add similar effects for the other sidebars:
  useEffect(() => {
    if (isProfileSidebarOpen) {
      setIsSidebarOpen(false);
      setIsProfileCardOpen(false);
    }
  }, [isProfileSidebarOpen]);

  useEffect(() => {
    if (isProfileCardOpen) {
      setIsSidebarOpen(false);
      setIsProfileSidebarOpen(false);
    }
  }, [isProfileCardOpen]);

  // Add this useEffect in the Chat component
  // In Chat.js, modify the useEffect that listens for the "openDirectChat" event
  useEffect(() => {
    // Create event listener for opening direct chats from the profile
    const handleOpenDirectChat = (event) => {
      const { chatId, otherUser } = event.detail;

      if (chatId && otherUser) {
        console.log("Received openDirectChat event:", { chatId, otherUser });

        // Set the direct chat state
        setDirectChat({
          id: chatId,
          otherUser: otherUser,
        });

        // Set current chat ID
        setCurrentChatId(chatId);

        // Turn off discover mode (this is the key fix)
        setDiscoverMode(false);

        // Close sidebars
        setIsSidebarOpen(false);
        setIsProfileSidebarOpen(false);
        setIsProfileCardOpen(false);
      }
    };

    // Add event listener
    window.addEventListener("openDirectChat", handleOpenDirectChat);

    // Clean up
    return () => {
      window.removeEventListener("openDirectChat", handleOpenDirectChat);
    };
  }, []);

  const handleCarouselQuestionSelect = (question) => {
    if (!question) return;

    // Set the input value to the question
    setInput(question);

    // Focus and adjust the textarea height
    if (textareaRef.current) {
      textareaRef.current.focus();
      adjustTextareaHeight();
    }
  };

  // Add a function to handle selecting a user from the carousel
  const handleCarouselUserSelect = async (userId) => {
    if (!userId) return;

    try {
      // Find the user in allUsers array
      const selectedUser = allUsers.find((user) => user.id === userId);

      if (!selectedUser) {
        console.error("User not found in allUsers array");
        return;
      }

      // Get the user's startups/pages collection if not already loaded
      let startups = selectedUser.startups || [];

      if (!startups.length) {
        const startupsSnapshot = await getDocs(
          collection(firestore, `users/${userId}/startups`)
        );

        startups = startupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().startupName || doc.data().name,
          startupImage: doc.data().startupImage,
          bio: doc.data().bio || "",
          intro: doc.data().intro || "",
          introHtml: doc.data().introHtml || "",
          city: doc.data().city || "",
          country: doc.data().country || "",
          link: doc.data().link || "",
          linkText: doc.data().linkText || "",
          industry: doc.data().industry || "",
          tags: doc.data().tags || [],
          ...doc.data(),
        }));
      }

      // Prepare user profile data
      const userProfileImage = selectedUser.profileImage;
      const displayProfileImage =
        userProfileImage &&
        !userProfileImage.includes("default.jpg") &&
        !userProfileImage.includes("profileImage.jpg")
          ? userProfileImage
          : defaultSvgImage;

      // Set the selected user profile
      setSelectedUserProfile({
        id: userId,
        firstName: selectedUser.firstName || "",
        lastName: selectedUser.lastName || "",
        name: `${selectedUser.firstName || ""} ${
          selectedUser.lastName || ""
        }`.trim(),
        profileImage: displayProfileImage,
        bio: selectedUser.bio || "",
        intro: selectedUser.intro || "",
        introHtml: selectedUser.introHtml || "",
        city: selectedUser.city || "",
        country: selectedUser.country || "",
        link: selectedUser.link || "",
        linkText: selectedUser.linkText || "",
        skills: selectedUser.skills || [],
        interests: selectedUser.interests || [],
        education: selectedUser.education || [],
        experiences: selectedUser.experiences || [],
        startups: startups,
      });

      // Open profile sidebar and close others
      setIsSidebarOpen(false);
      setIsProfileSidebarOpen(false);
      setIsProfileCardOpen(true);
    } catch (error) {
      console.error("Error opening profile:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        try {
          const userDoc = await getDoc(doc(firestore, "users", user.uid));

          if (userDoc.exists()) {
            const userData = userDoc.data();

            // Only fetch startups/pages
            const startupsSnapshot = await getDocs(
              collection(firestore, `users/${user.uid}/startups`)
            );
            const startupsData = startupsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            setUserData({
              id: user.uid,
              ...userData,
              startups: startupsData,
              profileImage: userData.profileImage || defaultImage,
            });
            setProfileImage(userData.profileImage || defaultImage);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setProfileImage(defaultImage);
        }
      } else {
        setCurrentUser(null);
        setUserData({});
        setProfileImage(defaultImage);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const loadChat = async () => {
      if (!currentChatId || !auth.currentUser) {
        setMessages([]);
        return;
      }

      try {
        // If it's a direct chat from inbox, use real-time listener
        if (directChat) {
          const messagesRef = collection(
            firestore,
            `chats/${currentChatId}/messages`
          );

          const q = query(messagesRef, orderBy("createdAt", "asc"));

          // Replace getDocs with onSnapshot for real-time updates
          const unsubscribe = onSnapshot(
            q,
            (messagesSnapshot) => {
              const messagesList = messagesSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
                createdAt: doc.data().createdAt?.toDate() || new Date(),
              }));

              setMessages(messagesList);
            },
            (error) => {
              console.error("Error in direct chat messages listener:", error);
            }
          );

          // Return unsubscribe function for cleanup
          return unsubscribe;
        } else {
          // Regular AI chat handling (your existing code)
          const chatRef = doc(
            firestore,
            `users/${auth.currentUser.uid}/chats/${currentChatId}`
          );
          const chatDoc = await getDoc(chatRef);

          if (chatDoc.exists()) {
            const chatData = chatDoc.data();
            const sortedMessages = (chatData.messages || []).sort(
              (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
            );
            setMessages(sortedMessages);
          }
        }
      } catch (error) {
        console.error("Error loading chat:", error);
      }
    };

    const unsubscribe = loadChat();

    // Cleanup function for the onSnapshot listener
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [currentChatId, directChat]);

  // 1. Add this useCallback near the top of your component (after your state declarations)
  const focusTextarea = useCallback(() => {
    // Only focus if there isn't already another active element (like a modal input)
    if (
      textareaRef.current &&
      document.activeElement === document.body &&
      document.hasFocus()
    ) {
      textareaRef.current.focus();
    }
  }, []);

  // 2. Add this useEffect for initial focus only
  useEffect(() => {
    // Only focus on initial load, not when returning to the tab
    focusTextarea();
  }, []); // Empty dependency array means this only runs on mount

  // 3. Add this useEffect to focus when chat changes, but only if no other element has focus
  useEffect(() => {
    // Only focus if no other element has focus
    if (document.activeElement === document.body) {
      focusTextarea();
    }
  }, [currentChatId, directChat, discoverMode]);
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.height = "auto";
    const originalValue = textarea.value;
    const originalPlaceholder = textarea.placeholder;

    if (!originalValue) {
      textarea.value = originalPlaceholder;
    }

    const scrollHeight = textarea.scrollHeight;

    if (!originalValue) {
      textarea.value = "";
      textarea.placeholder = originalPlaceholder;
    }

    const maxHeight = window.innerHeight * 0.25;
    textarea.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [input, currentPlaceholder]);

  const handleProfileUpdate = (updatedData) => {
    console.log("Profile update received in Chat.js:", updatedData);

    setUserData((prevUserData) => {
      const newUserData = { ...prevUserData };

      // Handle startups array specially
      if (updatedData.startups) {
        // Process each startup to ensure consistent naming
        const processedStartups = updatedData.startups.map((startup) => {
          const nameToUse = startup.startupName || startup.name || "";

          // Create a new object with both name properties set consistently
          return {
            ...startup,
            name: nameToUse,
            startupName: nameToUse,
          };
        });

        // Replace the entire startups array
        newUserData.startups = processedStartups;
      }

      // Handle all other properties
      Object.keys(updatedData).forEach((key) => {
        if (key !== "startups") {
          newUserData[key] = updatedData[key];
        }
      });

      console.log("Updated userData state in Chat.js:", newUserData);
      return newUserData;
    });
  };

  // Then make sure this is passed to SidebarRight
  <SidebarRight
    isOpen={isProfileSidebarOpen}
    onToggle={toggleRightSidebar}
    userData={{
      ...userData,
      onProfileUpdate: handleProfileUpdate,
    }}
  />;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        // Select placeholder list based on mode
        const placeholderList = discoverMode
          ? discoverPlaceholders
          : directChat
          ? directChatPlaceholders
          : placeholders;
        setCurrentPlaceholder((prev) => (prev + 1) % placeholderList.length);
        setIsTransitioning(false);
      }, 200);
    }, 2500);

    return () => clearInterval(intervalId);
  }, [directChat, discoverMode]); // Add discoverMode as a dependency

  // Replace the real-time listener with a one-time fetch
  useEffect(() => {
    if (!discoverMode) return;

    const fetchPosts = async () => {
      setIsLoading(true);

      try {
        const postsRef = collection(firestore, "posts");
        const q = query(postsRef, orderBy("createdAt", "desc"));

        const snapshot = await getDocs(q);

        // Use a Map to keep only the latest post from each user
        const userPostMap = new Map();

        snapshot.docs.forEach((doc) => {
          const postData = {
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate() || new Date(),
          };

          const userId = postData.userId;

          // If we haven't seen this user yet, or this post is newer than the one we have
          if (
            !userPostMap.has(userId) ||
            postData.createdAt > userPostMap.get(userId).createdAt
          ) {
            userPostMap.set(userId, postData);
          }
        });

        // Convert map values to array and sort by date
        const filteredPosts = Array.from(userPostMap.values()).sort(
          (a, b) => b.createdAt - a.createdAt
        );

        // For each post, fetch the current user data to ensure we have latest info
        const postsWithUserData = await Promise.all(
          filteredPosts.map(async (post) => {
            if (post.userId === auth.currentUser?.uid) {
              // For current user, use the userData from state which should be up-to-date
              return {
                ...post,
                userName: `${userData.firstName || ""} ${
                  userData.lastName || ""
                }`.trim(),
                userImage: profileImage || defaultSvgImage,
              };
            } else {
              try {
                // Fetch latest user data for other users
                const userDoc = await getDoc(
                  doc(firestore, "users", post.userId)
                );
                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  const userName = `${userData.firstName || ""} ${
                    userData.lastName || ""
                  }`.trim();
                  const userImage = userData.profileImage || defaultSvgImage;

                  return {
                    ...post,
                    userName,
                    userImage,
                  };
                }
              } catch (err) {
                console.error(
                  `Error fetching user data for post ${post.id}:`,
                  err
                );
              }
              return post; // Return original post if fetch fails
            }
          })
        );

        setPosts(postsWithUserData);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [discoverMode, userData, profileImage]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 960) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
      adjustTextareaHeight();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersRef = collection(firestore, "users");
        const snapshot = await getDocs(usersRef);

        const userData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const user = {
              id: doc.id,
              ...doc.data(),
            };

            // Only fetch the startups/pages collection
            const startupsSnapshot = await getDocs(
              collection(doc.ref, "startups")
            );
            user.startups = startupsSnapshot.docs.map((startup) =>
              startup.data()
            );

            return user;
          })
        );

        setAllUsers(userData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const scrollToBottomForce = () => {
    if (messagesContainerRef.current) {
      // Try multiple approaches to ensure scrolling works
      setTimeout(() => {
        // Method 1: Direct scroll property
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;

        // Method 2: scrollTo with behavior for modern browsers
        messagesContainerRef.current.scrollTo({
          top: messagesContainerRef.current.scrollHeight,
          behavior: "auto", // Use 'auto' instead of 'smooth' for immediate effect
        });

        // Method 3: Force layout recalculation and scroll again
        setTimeout(() => {
          messagesContainerRef.current.scrollTop =
            messagesContainerRef.current.scrollHeight + 1000;
        }, 50);
      }, 50);
    }
  };

  useEffect(() => {
    // Scroll to bottom when messages change
    scrollToBottomForce();
  }, [messages, currentChatId, directChat]);

  // Replace your current useEffect for chat selection
  useEffect(() => {
    if (currentChatId) {
      // Initial force scroll
      forceScrollToBottom();

      // Multiple retries with increasing delays
      for (let i = 1; i <= 10; i++) {
        setTimeout(forceScrollToBottom, i * 100);
      }
    }
  }, [currentChatId, directChat]);

  useEffect(() => {
    // This helps with auto-scrolling during streaming responses
    if (isLoading && messagesContainerRef.current) {
      const checkAndScroll = () => {
        if (messagesContainerRef.current) {
          const { scrollTop, scrollHeight, clientHeight } =
            messagesContainerRef.current;
          // If user is already near the bottom, auto-scroll
          // (within 150px of the bottom, or if the container is small)
          if (
            scrollHeight - scrollTop - clientHeight < 150 ||
            clientHeight < 300
          ) {
            scrollToBottomForce();
          }
        }
      };

      // Set an interval to check and scroll during loading
      const intervalId = setInterval(checkAndScroll, 100);
      return () => clearInterval(intervalId);
    }
  }, [isLoading]);

  const filterRelevantUserData = (users, query) => {
    // Extract location terms from the query
    const locationRegex = /\b(in|from|at|near)\s+([a-zA-Z\s,]+)(?=\s|$)/i;
    const locationMatch = query.match(locationRegex);

    let locationTerms = [];
    if (locationMatch && locationMatch[2]) {
      locationTerms = locationMatch[2]
        .toLowerCase()
        .split(/[,\s]+/)
        .filter((term) => term.length > 1)
        .map((term) => term.trim());
    }

    // Build mapping of common role variations and related terms
    const semanticMap = {
      // Design roles
      design: [
        "design",
        "designer",
        "designing",
        "designs",
        "ux",
        "ui",
        "user experience",
        "user interface",
        "graphic",
        "visual",
        "creative",
        "art director",
        "creative director",
      ],
      designer: [
        "design",
        "designer",
        "designing",
        "designs",
        "ux",
        "ui",
        "user experience",
        "user interface",
        "graphic",
        "visual",
        "creative",
        "art director",
        "creative director",
      ],

      // Engineering/Development roles
      engineer: [
        "engineer",
        "engineering",
        "developer",
        "dev",
        "coder",
        "programmer",
        "software",
        "frontend",
        "backend",
        "fullstack",
        "full-stack",
        "technical",
        "tech lead",
      ],
      developer: [
        "engineer",
        "engineering",
        "developer",
        "dev",
        "coder",
        "programmer",
        "software",
        "frontend",
        "backend",
        "fullstack",
        "full-stack",
        "technical",
        "tech lead",
      ],
      coding: [
        "code",
        "coding",
        "program",
        "programming",
        "develop",
        "developing",
        "engineer",
        "engineering",
      ],

      // Product roles
      product: [
        "product",
        "pm",
        "product manager",
        "product management",
        "product owner",
      ],

      // Marketing roles
      marketing: [
        "marketing",
        "marketer",
        "growth",
        "acquisition",
        "brand",
        "content",
        "social media",
      ],

      // Business roles
      founder: [
        "founder",
        "founding",
        "co-founder",
        "cofounder",
        "ceo",
        "chief executive",
        "entrepreneur",
        "startup",
        "founded",
      ],
      entrepreneur: [
        "entrepreneur",
        "entrepreneurial",
        "founder",
        "startup",
        "venture",
        "business owner",
      ],
      business: [
        "business",
        "biz dev",
        "business development",
        "strategy",
        "operations",
        "coo",
        "cfo",
        "executive",
      ],

      // Sales roles
      sales: [
        "sales",
        "selling",
        "business development",
        "account",
        "revenue",
        "customer success",
        "deal",
        "deals",
      ],

      // Data/AI roles
      data: [
        "data",
        "analytics",
        "analyst",
        "scientist",
        "machine learning",
        "ml",
        "ai",
        "artificial intelligence",
        "statistics",
        "database",
      ],
      ai: [
        "ai",
        "artificial intelligence",
        "machine learning",
        "ml",
        "deep learning",
        "nlp",
        "neural",
        "gpt",
      ],

      // Industry terms
      tech: [
        "tech",
        "technology",
        "technical",
        "digital",
        "software",
        "hardware",
        "it",
      ],
      startup: [
        "startup",
        "start-up",
        "early stage",
        "seed",
        "venture",
        "founder",
        "entrepreneurial",
      ],
      finance: [
        "finance",
        "financial",
        "fintech",
        "banking",
        "investment",
        "investor",
        "venture capital",
        "vc",
      ],
      healthcare: [
        "healthcare",
        "health",
        "medical",
        "medicine",
        "biotech",
        "pharma",
        "life science",
      ],
    };

    // Process regular search keywords (excluding location terms and common prepositions)
    let keywords = query
      .toLowerCase()
      .replace(locationRegex, "") // Remove the location part if found
      .split(/\s+/)
      .filter(
        (word) =>
          ![
            "to",
            "with",
            "my",
            "me",
            "i",
            "a",
            "the",
            "for",
            "and",
            "in",
            "from",
            "at",
            "near",
          ].includes(word) && word.length > 1
      );

    // Expand keywords with semantic variations
    const expandedKeywords = new Set();
    keywords.forEach((keyword) => {
      // Add the original keyword
      expandedKeywords.add(keyword);

      // Add semantic variations if they exist
      if (semanticMap[keyword]) {
        semanticMap[keyword].forEach((variation) =>
          expandedKeywords.add(variation)
        );
      }
    });

    // Convert set back to array
    const finalKeywords = Array.from(expandedKeywords);

    // If we detected keywords like "designer" or "developer", also look for role titles with these terms
    const roleTitlePatterns = [];
    if (keywords.some((k) => ["design", "designer"].includes(k))) {
      roleTitlePatterns.push(
        /(vp|vice president|head|lead|senior|chief|director)\s+of\s+design/i
      );
      roleTitlePatterns.push(/(design)\s+(director|manager|lead)/i);
    }
    if (
      keywords.some((k) =>
        ["engineer", "developer", "engineering", "code", "coding"].includes(k)
      )
    ) {
      roleTitlePatterns.push(
        /(vp|vice president|head|lead|senior|chief|director)\s+of\s+(engineering|technology|software)/i
      );
      roleTitlePatterns.push(
        /(tech|engineering|software)\s+(director|manager|lead)/i
      );
    }
    if (keywords.some((k) => ["product"].includes(k))) {
      roleTitlePatterns.push(
        /(vp|vice president|head|lead|senior|chief|director)\s+of\s+product/i
      );
      roleTitlePatterns.push(/(product)\s+(director|manager|lead|owner)/i);
    }

    const otherUsers = users.filter((user) => user.id !== auth.currentUser.uid);

    // First pass: filter by location if location terms exist
    let filteredByLocation = otherUsers;
    if (locationTerms.length > 0) {
      filteredByLocation = otherUsers.filter((user) => {
        const userCity = (user.city || "").toLowerCase();
        const userCountry = (user.country || "").toLowerCase();

        // Check startups locations too
        const startupLocations = (user.startups || [])
          .map((startup) => {
            return [
              (startup.city || "").toLowerCase(),
              (startup.country || "").toLowerCase(),
            ].join(" ");
          })
          .join(" ");

        const userLocations = `${userCity} ${userCountry} ${startupLocations}`;

        // Check if ANY of the location terms are in the user's locations
        return locationTerms.some((term) => userLocations.includes(term));
      });
    }

    // Second pass: apply keyword filtering with improved matching
    const relevantUsers = filteredByLocation.filter((user) => {
      // If we have no keywords but had location terms, and the user matched location, keep them
      if (finalKeywords.length === 0 && locationTerms.length > 0) {
        return true;
      }

      // Create searchable text from user data
      const userSearchableText = [
        user.bio || "",
        user.intro || "",
        user.city || "",
        user.country || "",
      ]
        .join(" ")
        .toLowerCase();

      // Create searchable text from all pages (startups collection)
      const pagesSearchableText = (user.startups || [])
        .map((page) =>
          [
            page.name || "",
            page.bio || "",
            page.intro || "",
            page.city || "",
            page.country || "",
          ].join(" ")
        )
        .join(" ")
        .toLowerCase();

      const combinedSearchableText = `${userSearchableText} ${pagesSearchableText}`;

      // Check for standard keyword matches
      const hasKeywordMatch = finalKeywords.some((keyword) =>
        combinedSearchableText.includes(keyword)
      );

      if (hasKeywordMatch) {
        return true;
      }

      // Check for role title pattern matches (like "VP of Design")
      if (roleTitlePatterns.length > 0) {
        return roleTitlePatterns.some((pattern) =>
          pattern.test(combinedSearchableText)
        );
      }

      return false;
    });

    return relevantUsers.map((user) => ({
      id: user.id,
      name: `${user.firstName || ""} ${user.lastName || ""}`.trim(),
      firstName: user.firstName,
      lastName: user.lastName,
      bio: (user.bio || "").slice(0, 150),
      intro: user.intro,
      introHtml: user.introHtml,
      profileImage: user.profileImage || defaultImage,
      link: user.link || "",
      linkText: user.linkText || "",
      location: {
        city: user.city,
        country: user.country,
      },
      startups: (user.startups || []).map((page) => ({
        id: page.id || String(Math.random()),
        name: page.name || page.startupName,
        bio: page.bio,
        intro: page.intro,
        city: page.city,
        country: page.country,
        industry: page.industry,
        tags: page.tags || [],
      })),
    }));
  };

  const cleanUndefinedValues = (obj) => {
    // Handle null or undefined input
    if (obj === null || obj === undefined) {
      return null;
    }

    // Handle arrays
    if (Array.isArray(obj)) {
      const cleanedArray = obj
        .map(cleanUndefinedValues)
        .filter((item) => item !== null && item !== undefined);
      return cleanedArray.length > 0 ? cleanedArray : [];
    }

    // Handle objects
    if (typeof obj === "object") {
      const cleaned = {};
      for (const [key, value] of Object.entries(obj)) {
        const cleanedValue = cleanUndefinedValues(value);
        if (cleanedValue !== undefined && cleanedValue !== null) {
          cleaned[key] = cleanedValue;
        }
      }
      return Object.keys(cleaned).length > 0 ? cleaned : null;
    }

    // Handle primitives
    return obj;
  };

  const prepareMessageForFirestore = (message) => {
    const cleaned = {
      role: message.role || "assistant",
      content: message.content || "",
      timestamp: message.timestamp || new Date().toISOString(),
      matches: Array.isArray(message.matches)
        ? message.matches.map((user) => ({
            id: user.id,
            name: user.name || "",
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            bio: user.bio || "",
            intro: user.intro || "",
            introHtml: user.introHtml || "",
            profileImage: user.profileImage || defaultImage,
            link: user.link || "",
            linkText: user.linkText || "",
            location: {
              city: user.location?.city || "",
              country: user.location?.country || "",
            },
            startups: Array.isArray(user.startups)
              ? user.startups.map((page) => ({
                  id: page.id || String(Math.random()),
                  name: page.name || "",
                  bio: page.bio || "",
                  intro: page.intro || "",
                  city: page.city || "",
                  country: page.country || "",
                  industry: page.industry || "",
                  tags: Array.isArray(page.tags) ? page.tags : [],
                }))
              : [],
          }))
        : [],
    };

    return cleanUndefinedValues(cleaned);
  };

  const extractBoldNames = (content) => {
    const boldPattern = /\*\*([^*]+?)\*\*/g;
    const matches = [...content.matchAll(boldPattern)];
    return matches.map((match) => match[1].trim());
  };

  const sendDirectMessage = async () => {
    if (!input.trim() || !directChat || !auth.currentUser) return;

    setIsLoading(true);

    // Create the message data but don't update UI yet
    const userMessage = {
      role: "user",
      content: input,
      timestamp: new Date().toISOString(),
    };

    // Clear input immediately for better UX
    setInput("");

    try {
      const chatRef = doc(firestore, `chats/${directChat.id}`);
      const chatDoc = await getDoc(chatRef);
      const chatData = chatDoc.exists() ? chatDoc.data() : null;

      // Create message data
      const messageData = {
        text: input.trim(),
        senderId: auth.currentUser.uid,
        createdAt: serverTimestamp(),
        read: false,
      };

      const batch = writeBatch(firestore);
      const currentUnreadCount = chatData?.unreadCount || {};

      // Prepare unread count update
      const updatedUnreadCount = {
        ...currentUnreadCount,
        [directChat.otherUser.id]:
          (currentUnreadCount[directChat.otherUser.id] || 0) + 1,
        [auth.currentUser.uid]: 0,
      };

      // Handle deleted chats
      let updatedDeletedBy = chatData?.deletedBy || [];
      if (updatedDeletedBy.includes(directChat.otherUser.id)) {
        updatedDeletedBy = updatedDeletedBy.filter(
          (id) => id !== directChat.otherUser.id
        );
      }

      // Update chat document
      batch.update(chatRef, {
        participants: [auth.currentUser.uid, directChat.otherUser.id],
        hasMessages: true,
        lastMessage: {
          text: messageData.text,
          senderId: auth.currentUser.uid,
        },
        lastMessageTime: serverTimestamp(),
        unreadCount: updatedUnreadCount,
        deletedBy: updatedDeletedBy,
      });

      // Add the message
      const messagesRef = collection(chatRef, "messages");
      const newMessageRef = doc(messagesRef);
      batch.set(newMessageRef, messageData);

      await batch.commit();

      // Only after successful Firestore operation, update the UI with the complete message
      const newMessage = {
        id: newMessageRef.id,
        role: "user",
        content: input.trim(),
        senderId: auth.currentUser.uid,
        createdAt: new Date(),
        text: input.trim(),
      };

      // Add the new message to our local messages state (just once)
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      // More aggressive scrolling approach
      scrollToBottomForce();
    } catch (error) {
      console.error("Error sending direct message:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || !auth.currentUser) return;

    // Inside the handleSubmit function, in the discover mode section
    // Modify the handleSubmit function in the discover mode section
    if (discoverMode) {
      try {
        setIsLoading(true);

        // Enforce 500 character limit for discover mode posts
        if (input.trim().length > 2500) {
          setInput(input.trim().substring(0, 2500));
        }

        // First, check if the user already has a post and delete it
        const existingPostsQuery = query(
          collection(firestore, "posts"),
          where("userId", "==", auth.currentUser.uid)
        );

        const existingPostsSnapshot = await getDocs(existingPostsQuery);

        // Use a batch to handle both deletion and creation
        const batch = writeBatch(firestore);

        // Delete any existing posts from this user
        existingPostsSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        // Prepare new post data
        const postData = {
          text: input.trim().substring(0, 2500), // Ensure text is limited to 500 characters
          userId: auth.currentUser.uid,
          userName: `${userData.firstName || ""} ${
            userData.lastName || ""
          }`.trim(),
          userImage: profileImage || defaultSvgImage,
          createdAt: serverTimestamp(),
        };

        // Create the new post reference
        const newPostRef = doc(collection(firestore, "posts"));
        batch.set(newPostRef, postData);

        // Commit the batch
        await batch.commit();

        // Update local state: remove any existing posts by this user and add the new one
        setPosts((prevPosts) => [
          {
            id: newPostRef.id,
            ...postData,
            createdAt: new Date(), // Use current date for immediate display
          },
          ...prevPosts.filter((post) => post.userId !== auth.currentUser.uid),
        ]);

        // Clear input field
        setInput("");
      } catch (error) {
        console.error("Error creating post:", error);
      } finally {
        setIsLoading(false);
      }
      return;
    }

    if (directChat) {
      sendDirectMessage();
      return;
    }

    let chatId = currentChatId;

    const userMessage = {
      role: "user",
      content: input,
      timestamp: new Date().toISOString(),
    };

    try {
      let currentMessages = [...messages];
      currentMessages = [...currentMessages, userMessage];
      setMessages(currentMessages);
      setInput("");
      setIsLoading(true);

      // Create new chat if needed
      if (!chatId) {
        const newChatRef = doc(
          collection(firestore, `users/${auth.currentUser.uid}/chats`)
        );
        chatId = newChatRef.id;

        const cleanedUserMessage = prepareMessageForFirestore(userMessage);
        await setDoc(newChatRef, {
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          title: input.slice(0, 50),
          messages: [cleanedUserMessage],
        });

        setCurrentChatId(chatId);
      } else {
        const chatRef = doc(
          firestore,
          `users/${auth.currentUser.uid}/chats/${chatId}`
        );
        const cleanedMessages = currentMessages.map(prepareMessageForFirestore);
        await updateDoc(chatRef, {
          messages: cleanedMessages,
          updatedAt: serverTimestamp(),
        });
      }

      // Debug logs to see what data we're working with
      console.log("All users before filtering:", allUsers);
      const relevantUserData = filterRelevantUserData(allUsers, input);
      console.log("Filtered users:", relevantUserData);

      let assistantMessage = {
        role: "assistant",
        content: "",
        timestamp: new Date().toISOString(),
        matches: [],
      };

      currentMessages = [...currentMessages, assistantMessage];
      setMessages(currentMessages);

      const systemPrompt = `You help connect people. When analyzing profiles:

1. CHECK ALL THE DATA PROPERLY:
   - Read user bios & intros carefully to understand their role & goals
   - Look at all their pages (startups, projects, programs, etc.)
   - Read the bio & intro of each page to understand what it represents
   - Pay attention to locations when mentioned
      
2. UNDERSTAND WHAT MAKES A MATCH:
   - Look for relevant skills, experiences, and interests
   - Consider both primary profession and secondary capabilities
   - Match based on context, not just keywords
   - Be consistent in your matching criteria between similar queries

3. ONLY RETURN REAL MATCHES:
   - Look at everything before deciding
   - Don't suggest people without relevant evidence
   - Show strongest matches first
   - Better to find one perfect match than list multiple weak matches
   - Consider both the person's profile AND their pages
   - If location is specified, respect location requirements
   - Apply intelligent role matching across similar titles and positions

4. WRITE IN A CASUAL, CONVERSATIONAL STYLE:
   - Speak in a natural, flowing conversational tone
   - Avoid formal structured lists - present information in paragraphs
   - Use natural language transitions between people you're introducing
   - Sound human, helpful, and conversational
   - Avoid bullet points and numbered lists when possible
   - Use short paragraphs with natural breaks
   
5. FORMAT PROPERLY WITH SPECIFIC BOLDING:
   - ONLY bold two types of entities:
     1. Person names: "**John Smith**"
     2. Organization/project names: "**Terrarium**", "**YC**", "**Google**"
   - NEVER bold anything else like roles, locations, or descriptive phrases
   
6. ABOUT FOUNDRY:
   - Foundry is a people search engine where users can find who they're looking for instantly
   - Foundry helps users connect with relevant people based on their needs, skills, projects, or interests
   - Foundry is for everyone - it's for anyone who wants to find or be found
   - When users ask what Foundry does, explain it's a platform designed to help people find each other based on their interests, needs, and goals
   - The platform uses profiles, pages (for projects, businesses, etc.), and AI-powered matching to connect users from all backgrounds and industries

7. ABOUT THE CURRENT USER:
   - When users ask about themselves, provide information from their profile
   - Reference their name, bio, interests, skills, and pages they've created
   - Only discuss information that appears in their profile data
   - If they ask what their role is, tell them about their profile information and any startups/pages they've created

Be concise but provide enough detail to make the matches interesting.
IMPORTANT: Never include any URLs, firebase links, or image URLs in your text response.
DO NOT include profile image, or other details in text form.

If you find actual matches based on the available data, show them. If not, say so politely. Never return irrelevant profiles just to show something.`;

      console.log("System prompt and data:", {
        prompt: systemPrompt,
        query: input,
        availableMatches: relevantUserData,
      });

      const conversationHistory = [
        {
          role: "system",
          content: `${systemPrompt}
          Query: ${input}
          Current user: ${JSON.stringify({
            id: auth.currentUser.uid,
            name: `${userData.firstName || ""} ${
              userData.lastName || ""
            }`.trim(),
            firstName: userData.firstName || "",
            lastName: userData.lastName || "",
            bio: userData.bio || "",
            intro: userData.intro || "",
            introHtml: userData.introHtml || "",
            city: userData.city || "",
            country: userData.country || "",
            skills: userData.skills || [],
            interests: userData.interests || [],
            education: userData.education || [],
            experiences: userData.experiences || [],
            startups: userData.startups || [],
          })}
          Available matches: ${JSON.stringify(relevantUserData)}`,
        },
        ...messages.map((msg) => ({
          role: msg.role,
          content: msg.content,
        })),
        { role: userMessage.role, content: userMessage.content },
      ];

      // here

      try {
        const stream = await streamOpenAICompletion(conversationHistory, {
          model: "gpt-4o-mini",
          temperature: 0.7,
          max_tokens: 500,
          presence_penalty: 1.0,
          frequency_penalty: 1.0,
        });

        const reader = stream.getReader();
        const decoder = new TextDecoder();

        let done = false;

        while (!done) {
          const { value, done: readerDone } = await reader.read();
          done = readerDone;

          if (value) {
            const chunk = decoder.decode(value);

            // Parse SSE data
            const lines = chunk.split("\n\n");
            for (const line of lines) {
              if (line.startsWith("data: ")) {
                const data = line.substring(6);

                try {
                  const parsed = JSON.parse(data);

                  if (parsed.content) {
                    assistantMessage.content += parsed.content;
                    currentMessages = [
                      ...currentMessages.slice(0, -1),
                      { ...assistantMessage },
                    ];
                    setMessages([...currentMessages]);
                  }

                  // Replace the user matching section in the streamOpenAICompletion handler
                  // Find this block in your code (around line 1118 in the most recent paste):
                  if (parsed.finishReason === "stop" || parsed.done) {
                    // Process the completed message
                    const boldNames = extractBoldNames(
                      assistantMessage.content
                    );

                    const mentionedUsers = relevantUserData.filter((user) => {
                      // First try exact match (original approach that worked with special characters)
                      const normalizedUserName = user.name.toLowerCase().trim();
                      const exactMatch = boldNames.some(
                        (boldName) =>
                          boldName.toLowerCase().trim() === normalizedUserName
                      );

                      if (exactMatch) return true;

                      // Then try partial match by words (preserve this logic for edge cases)
                      const userNameWords = normalizedUserName.split(/\s+/);
                      return boldNames.some((boldName) => {
                        const boldNameWords = boldName
                          .toLowerCase()
                          .trim()
                          .split(/\s+/);

                        // If first and last name match (for users with middle names/characters)
                        if (
                          userNameWords.length > 0 &&
                          boldNameWords.length > 0
                        ) {
                          const firstMatch =
                            userNameWords[0] === boldNameWords[0];
                          const lastMatch =
                            userNameWords[userNameWords.length - 1] ===
                            boldNameWords[boldNameWords.length - 1];

                          // Only return true if BOTH first and last match (this is the key fix)
                          if (firstMatch && lastMatch) return true;
                        }

                        // Add a more flexible match for names with special characters
                        // This normalizes text by removing special chars for comparison
                        const normalizeText = (text) =>
                          text.replace(/[^\p{L}\p{N}\s]/gu, "").trim();

                        const normalizedUserName = normalizeText(
                          user.name.toLowerCase()
                        );
                        const normalizedBoldName = normalizeText(
                          boldName.toLowerCase()
                        );

                        // Split again after normalization
                        const normalizedUserParts =
                          normalizedUserName.split(/\s+/);
                        const normalizedBoldParts =
                          normalizedBoldName.split(/\s+/);

                        // Check if both first and last parts match after normalization
                        if (
                          normalizedUserParts.length > 0 &&
                          normalizedBoldParts.length > 0
                        ) {
                          const firstNormalizedMatch =
                            normalizedUserParts[0] === normalizedBoldParts[0];
                          const lastNormalizedMatch =
                            normalizedUserParts[
                              normalizedUserParts.length - 1
                            ] ===
                            normalizedBoldParts[normalizedBoldParts.length - 1];

                          if (firstNormalizedMatch && lastNormalizedMatch)
                            return true;
                        }

                        // Check if significant portion of words match (preserve from original)
                        // but ensure it's checking both first and last, not just any words
                        let firstNameMatched = false;
                        let lastNameMatched = false;

                        if (
                          userNameWords.length > 0 &&
                          boldNameWords.length > 0
                        ) {
                          // Check first name
                          firstNameMatched =
                            userNameWords[0] === boldNameWords[0] ||
                            userNameWords[0].includes(boldNameWords[0]) ||
                            boldNameWords[0].includes(userNameWords[0]);

                          // Check last name
                          lastNameMatched =
                            userNameWords[userNameWords.length - 1] ===
                              boldNameWords[boldNameWords.length - 1] ||
                            userNameWords[userNameWords.length - 1].includes(
                              boldNameWords[boldNameWords.length - 1]
                            ) ||
                            boldNameWords[boldNameWords.length - 1].includes(
                              userNameWords[userNameWords.length - 1]
                            );
                        }

                        return firstNameMatched && lastNameMatched;
                      });
                    });

                    console.log("MATCHED USERS:", mentionedUsers); // Debug log

                    assistantMessage.matches = mentionedUsers.map((user) => ({
                      id: user.id,
                      name: user.name,
                      firstName: user.name.split(" ")[0],
                      lastName: user.name.split(" ").slice(1).join(" "),
                      bio: user.bio || "",
                      intro: user.intro || "",
                      introHtml: user.introHtml || "",
                      profileImage:
                        user.profileImage === defaultImage ||
                        (user.profileImage &&
                          (user.profileImage.includes("default.jpg") ||
                            user.profileImage.includes("profileImage.jpg")))
                          ? defaultSvgImage
                          : user.profileImage || defaultSvgImage,
                      link: user.link || "",
                      linkText: user.linkText || "",
                      location: user.location,
                      skills: user.skills || [],
                      interests: user.interests || [],
                      education: user.education || [],
                      experiences: user.experiences || [],
                      startups: user.startups || [],
                    }));

                    console.log("PROCESSED MATCHES:", assistantMessage.matches); // Debug log

                    currentMessages = [
                      ...currentMessages.slice(0, -1),
                      { ...assistantMessage },
                    ];
                    setMessages([...currentMessages]);
                    setIsLoading(false);
                  }
                } catch (parseError) {
                  console.error("Error parsing SSE data:", parseError);
                }
              }
            }
          }
        }
      } catch (error) {
        console.error("Error:", error);
        const errorMessage = {
          role: "assistant",
          content: error.message.includes("429")
            ? "Please try again in a moment."
            : "Sorry, I encountered an error. Please try again.",
          timestamp: new Date().toISOString(),
          matches: [],
        };

        const updatedMessages = [...messages, errorMessage];
        setMessages(updatedMessages);

        if (chatId) {
          const chatRef = doc(
            firestore,
            `users/${auth.currentUser.uid}/chats/${chatId}`
          );
          const cleanedMessages = updatedMessages.map(
            prepareMessageForFirestore
          );
          await updateDoc(chatRef, {
            messages: cleanedMessages,
            updatedAt: serverTimestamp(),
          });
        }
        setIsLoading(false);
      }

      if (!assistantMessage.content.trim()) {
        assistantMessage.content =
          "I couldn't find any relevant matches. Try adjusting your search terms.";
        currentMessages = [
          ...currentMessages.slice(0, -1),
          { ...assistantMessage },
        ];
        setMessages(currentMessages);
      }

      // Clean messages before saving to Firestore
      const cleanedMessages = currentMessages
        .map(prepareMessageForFirestore)
        .filter(Boolean);

      // Update Firestore with cleaned data
      const chatRef = doc(
        firestore,
        `users/${auth.currentUser.uid}/chats/${chatId}`
      );
      await updateDoc(chatRef, {
        messages: cleanedMessages,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = {
        role: "assistant",
        content: error.message.includes("429")
          ? "Please try again in a moment."
          : "Sorry, I encountered an error. Please try again.",
        timestamp: new Date().toISOString(),
        matches: [],
      };

      const updatedMessages = [...messages, errorMessage];
      setMessages(updatedMessages);

      if (chatId) {
        const chatRef = doc(
          firestore,
          `users/${auth.currentUser.uid}/chats/${chatId}`
        );
        const cleanedMessages = updatedMessages.map(prepareMessageForFirestore);
        await updateDoc(chatRef, {
          messages: cleanedMessages,
          updatedAt: serverTimestamp(),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isSingleLineMessage = (message) => {
    // For direct chat messages
    if (directChat && message.text) {
      return !message.text.includes("\n") && message.text.length < 100;
    }

    // For AI assistant messages
    if (message.content && typeof message.content === "string") {
      return !message.content.includes("\n") && message.content.length < 100;
    }

    return false;
  };

  return (
    <div
      className={`${styles.container} 
  ${isSidebarOpen ? styles.withSidebar : ""} 
  ${isProfileSidebarOpen ? styles.withProfileSidebar : ""} 
  ${isProfileCardOpen ? styles.withProfileCard : ""}`}
    >
      <div className={styles.topBlock}></div>
      {!isSidebarOpen && (
        <button
          className={styles.sidebarOpenButton}
          onClick={toggleLeftSidebar}
          aria-label="Open sidebar"
          type="button"
        >
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "1.25rem" }}
          >
            keyboard_double_arrow_right
          </span>
        </button>
      )}

      <Sidebar
        isOpen={isSidebarOpen}
        onToggle={toggleLeftSidebar}
        onChatSelect={(chatId, chatData) => {
          // Check if we're entering discover mode
          if (chatId === "discover") {
            setDiscoverMode(true);
            setCurrentChatId(null);
            setDirectChat(null);
          } else {
            setDiscoverMode(false);
            setCurrentChatId(chatId);

            // Set direct chat data if provided
            if (chatData) {
              setDirectChat(chatData);

              // Add this check:
              if (chatData.fromInbox) {
                // Don't modify sidebar state
                return;
              }
            } else {
              setDirectChat(null);
            }
          }

          // Only close sidebar if we're in mobile view (60rem = 960px)
          if (window.innerWidth <= 960) {
            setIsSidebarOpen(false);
            setIsProfileSidebarOpen(false);
            setIsProfileCardOpen(false);
          }
        }}
        currentChatId={currentChatId}
        isInDiscoverMode={discoverMode}
      />
      {selectedUserProfile && (
        <SidebarProfile
          isOpen={isProfileCardOpen}
          onToggle={toggleProfileCard}
          userData={selectedUserProfile}
        />
      )}

      <SidebarRight
        isOpen={isProfileSidebarOpen}
        onToggle={toggleRightSidebar}
        userData={{
          ...userData,
          onProfileUpdate: handleProfileUpdate,
        }}
      />
      <button className={styles.profileButton} onClick={toggleRightSidebar}>
        <img
          src={
            profileImage &&
            !profileImage.includes("default.jpg") &&
            !profileImage.includes("profileImage.jpg")
              ? profileImage
              : defaultSvgImage
          }
          alt="profile"
          className={styles.image}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultSvgImage;
          }}
        />
      </button>
      <div
        className={`${styles.mainContent} ${
          isProfileCardOpen ? styles.withProfileCard : ""
        } ${isProfileSidebarOpen ? styles.withProfileSidebar : ""} ${
          isSidebarOpen ? styles.withSidebar : ""
        }`}
      >
        <div className={styles.chatContainer}>
          <div className={styles.messagesContainer} ref={messagesContainerRef}>
            {!discoverMode && messages.length === 0 && (
              <div className={styles.welcomeMessage}>
                {/* Different headings based on chat type */}
                {!directChat ? (
                  <h1 className={styles.welcomeTitle}>
                    Who are you looking for?
                  </h1>
                ) : (
                  <h1
                    className={`${styles.welcomeTitle} ${styles.directChatTitle}`}
                  >
                    What do you want to say?
                  </h1>
                )}

                {/* User carousel only when NOT in direct chat */}
                {!directChat && (
                  <UserCarousel
                    allUsers={allUsers}
                    onUserSelect={(question, userId) => {
                      if (question) {
                        handleCarouselQuestionSelect(question);
                      } else if (userId) {
                        handleCarouselUserSelect(userId);
                      }
                    }}
                    currentUserId={auth.currentUser?.uid}
                  />
                )}

                {/* Suggestion carousel with different placeholders based on chat type */}
                <SuggestionCarousel
                  suggestions={
                    directChat
                      ? directChatPlaceholders
                      : discoverMode
                      ? discoverPlaceholders
                      : placeholders
                  }
                  onSuggestionSelect={handleCarouselQuestionSelect}
                />
              </div>
            )}
            {discoverMode ? (
              // Render posts in discover mode
              posts.length > 0 ? (
                posts.map((post) => (
                  <div
                    key={post.id}
                    className={`${styles.message} ${
                      post.userId === auth.currentUser?.uid
                        ? styles.userMessage
                        : styles.assistantMessage
                    } ${
                      post.text.length < 100 && !post.text.includes("\n")
                        ? styles.singleLineMessage
                        : ""
                    }`}
                  >
                    <div className={styles.messageAvatar}>
                      <img
                        src={
                          post.userImage &&
                          !post.userImage.includes("default.jpg") &&
                          !post.userImage.includes("profileImage.jpg")
                            ? post.userImage
                            : defaultSvgImage
                        }
                        alt="Avatar"
                        className={styles.avatarImage} // Change this to apply border radius to all discover images
                        style={{ borderRadius: "50%", cursor: "pointer" }} // Add this inline style to ensure 50% border radius
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultSvgImage;
                        }}
                        onClick={async () => {
                          // If post is from current user, open right sidebar
                          if (post.userId === auth.currentUser?.uid) {
                            setIsSidebarOpen(false);
                            setIsProfileCardOpen(false);
                            setIsProfileSidebarOpen(true);
                          } else {
                            try {
                              // Fetch the user's profile
                              const userDoc = await getDoc(
                                doc(firestore, "users", post.userId)
                              );
                              if (!userDoc.exists()) {
                                console.error("User document not found");
                                return;
                              }

                              const userData = userDoc.data();

                              // Get the user's startups
                              const startupsSnapshot = await getDocs(
                                collection(
                                  firestore,
                                  `users/${post.userId}/startups`
                                )
                              );

                              const startups = startupsSnapshot.docs.map(
                                (doc) => ({
                                  id: doc.id,
                                  name:
                                    doc.data().startupName || doc.data().name,
                                  startupImage: doc.data().startupImage,
                                  bio: doc.data().bio || "",
                                  intro: doc.data().intro || "",
                                  introHtml: doc.data().introHtml || "",
                                  city: doc.data().city || "",
                                  country: doc.data().country || "",
                                  link: doc.data().link || "",
                                  linkText: doc.data().linkText || "",
                                  industry: doc.data().industry || "",
                                  tags: doc.data().tags || [],
                                  ...doc.data(),
                                })
                              );

                              // Set profile data
                              setSelectedUserProfile({
                                id: post.userId,
                                firstName:
                                  userData.firstName ||
                                  post.userName?.split(" ")[0] ||
                                  "",
                                lastName:
                                  userData.lastName ||
                                  post.userName
                                    ?.split(" ")
                                    .slice(1)
                                    .join(" ") ||
                                  "",
                                name:
                                  userData.firstName && userData.lastName
                                    ? `${userData.firstName} ${userData.lastName}`
                                    : post.userName || "",
                                profileImage: post.userImage,
                                bio: userData.bio || "",
                                intro: userData.intro || "",
                                introHtml: userData.introHtml || "",
                                city: userData.city || "",
                                country: userData.country || "",
                                link: userData.link || "",
                                linkText: userData.linkText || "",
                                skills: userData.skills || [],
                                interests: userData.interests || [],
                                education: userData.education || [],
                                experiences: userData.experiences || [],
                                startups: startups,
                              });

                              // Open profile sidebar
                              setIsSidebarOpen(false);
                              setIsProfileSidebarOpen(false);
                              setIsProfileCardOpen(true);
                            } catch (error) {
                              console.error("Error opening profile:", error);
                            }
                          }
                        }}
                      />
                    </div>
                    <div
                      className={`${styles.messageContent} ${styles.directMessage}`}
                    >
                      {post.text}
                    </div>
                  </div>
                ))
              ) : !isLoading ? (
                <div className={styles.emptyState}></div>
              ) : null
            ) : (
              // Render regular messages
              messages.map((message, index) => (
                <div
                  key={index}
                  className={`${styles.message} ${
                    message.role === "user" ||
                    message.senderId === auth.currentUser?.uid
                      ? styles.userMessage
                      : styles.assistantMessage
                  } ${
                    isSingleLineMessage(message) ? styles.singleLineMessage : ""
                  }`}
                >
                  <div
                    className={`${styles.messageAvatar} ${
                      directChat && message.senderId !== auth.currentUser?.uid
                        ? styles.directChatUserAvatar
                        : ""
                    }`}
                  >
                    <img
                      src={
                        message.role === "user" ||
                        message.senderId === auth.currentUser?.uid
                          ? profileImage &&
                            !profileImage.includes("default.jpg") &&
                            !profileImage.includes("profileImage.jpg")
                            ? profileImage
                            : defaultSvgImage
                          : directChat
                          ? directChat.otherUser.image &&
                            !directChat.otherUser.image.includes(
                              "default.jpg"
                            ) &&
                            !directChat.otherUser.image.includes(
                              "profileImage.jpg"
                            )
                            ? directChat.otherUser.image
                            : defaultSvgImage
                          : aiImage
                      }
                      alt="Avatar"
                      className={styles.avatarImage}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultSvgImage;
                      }}
                      // Add this onClick handler for direct chat user avatars
                      onClick={async () => {
                        // If the message is from the current user, open the right sidebar
                        if (
                          message.role === "user" ||
                          message.senderId === auth.currentUser?.uid
                        ) {
                          // Using the same logic as toggleRightSidebar
                          setIsSidebarOpen(false);
                          setIsProfileCardOpen(false);
                          setIsProfileSidebarOpen(true);
                        }
                        // Keep the existing logic for direct chat messages from other users
                        else if (
                          directChat &&
                          message.senderId !== auth.currentUser?.uid
                        ) {
                          try {
                            const userId = directChat.otherUser.id;

                            // Fetch the complete user document to get all details
                            const userDoc = await getDoc(
                              doc(firestore, "users", userId)
                            );
                            if (!userDoc.exists()) {
                              console.error("User document not found");
                              return;
                            }

                            const userData = userDoc.data();

                            // Get the user's startups collection
                            const startupsSnapshot = await getDocs(
                              collection(firestore, `users/${userId}/startups`)
                            );

                            const startups = startupsSnapshot.docs.map(
                              (doc) => ({
                                id: doc.id,
                                name: doc.data().startupName || doc.data().name,
                                startupImage: doc.data().startupImage,
                                bio: doc.data().bio || "",
                                intro: doc.data().intro || "",
                                introHtml: doc.data().introHtml || "",
                                city: doc.data().city || "",
                                country: doc.data().country || "",
                                link: doc.data().link || "",
                                linkText: doc.data().linkText || "",
                                industry: doc.data().industry || "",
                                tags: doc.data().tags || [],
                                ...doc.data(),
                              })
                            );

                            // Properly handle the profile image
                            const userProfileImage =
                              userData.profileImage ||
                              directChat.otherUser.image;
                            const displayProfileImage =
                              userProfileImage &&
                              !userProfileImage.includes("default.jpg") &&
                              !userProfileImage.includes("profileImage.jpg")
                                ? userProfileImage
                                : defaultSvgImage;

                            // Set the selected user profile data with all available information
                            setSelectedUserProfile({
                              id: userId,
                              firstName:
                                userData.firstName ||
                                directChat.otherUser.firstName ||
                                directChat.otherUser.name?.split(" ")[0] ||
                                "",
                              lastName:
                                userData.lastName ||
                                directChat.otherUser.lastName ||
                                directChat.otherUser.name
                                  ?.split(" ")
                                  .slice(1)
                                  .join(" ") ||
                                "",
                              name:
                                userData.firstName + " " + userData.lastName ||
                                directChat.otherUser.name ||
                                "",
                              profileImage: displayProfileImage,
                              bio:
                                userData.bio || directChat.otherUser.bio || "",
                              intro:
                                userData.intro ||
                                directChat.otherUser.intro ||
                                "",
                              introHtml:
                                userData.introHtml ||
                                directChat.otherUser.introHtml ||
                                "",
                              city:
                                userData.city ||
                                directChat.otherUser.city ||
                                "",
                              country:
                                userData.country ||
                                directChat.otherUser.country ||
                                "",
                              link:
                                userData.link ||
                                directChat.otherUser.link ||
                                "",
                              linkText:
                                userData.linkText ||
                                directChat.otherUser.linkText ||
                                "",
                              skills: userData.skills || [],
                              interests: userData.interests || [],
                              education: userData.education || [],
                              experiences: userData.experiences || [],
                              startups: startups,
                            });

                            // Open the profile sidebar and close others
                            setIsSidebarOpen(false);
                            setIsProfileSidebarOpen(false);
                            setIsProfileCardOpen(true);
                          } catch (error) {
                            console.error("Error opening profile:", error);
                          }
                        }
                      }}
                    />
                  </div>
                  {directChat ? (
                    // Direct chat message - now using messageContent class for consistency
                    <div
                      className={`${styles.messageContent} ${styles.directMessage}`}
                    >
                      {message.text}
                    </div>
                  ) : message.role === "user" ? (
                    <div className={styles.messageContent}>
                      {message.content}
                    </div>
                  ) : (
                    <div className={styles.messageContent}>
                      <div className={styles.messageText}>
                        {/* Message content rendering */}

                        {message.content === undefined ||
                        typeof message.content !== "string" ? (
                          <div>this message is no longer available.</div>
                        ) : (
                          <div
                            className={styles.messageText}
                            style={{ whiteSpace: "pre-wrap" }}
                            dangerouslySetInnerHTML={{
                              __html: message.content
                                .replace(/(\d+\.) /g, "<strong>$1</strong> ")
                                .replace(
                                  /\*\*([^*]+)\*\*/g,
                                  "<strong>$1</strong>"
                                ),
                            }}
                          />
                        )}
                      </div>

                      {/* Profile cards */}
                      {message.matches?.length > 0 && (
                        <div className={styles.profileCardsContainer}>
                          {message.matches.map((user, idx) => (
                            <div
                              key={idx}
                              className={styles.profileCard}
                              onClick={async () => {
                                try {
                                  let userId = user.id;

                                  if (!userId) {
                                    const matchedUser = allUsers.find(
                                      (u) =>
                                        u.firstName === user.firstName &&
                                        u.lastName === user.lastName
                                    );
                                    userId = matchedUser?.id;
                                  }

                                  if (!userId) {
                                    console.error("Could not find user ID");
                                    return;
                                  }

                                  // Get the user's pages collection
                                  const startupsSnapshot = await getDocs(
                                    collection(
                                      firestore,
                                      `users/${userId}/startups`
                                    )
                                  );

                                  const startups = startupsSnapshot.docs.map(
                                    (doc) => ({
                                      id: doc.id,
                                      name: doc.data().startupName,
                                      startupImage: doc.data().startupImage,
                                      bio: doc.data().bio,
                                      intro: doc.data().intro,
                                      city: doc.data().city,
                                      country: doc.data().country,
                                      link: doc.data().link,
                                      ...doc.data(),
                                    })
                                  );

                                  setSelectedUserProfile({
                                    id: userId,
                                    firstName:
                                      user.firstName || user.name.split(" ")[0],
                                    lastName:
                                      user.lastName ||
                                      user.name.split(" ").slice(1).join(" "),
                                    profileImage: user.profileImage,
                                    link: user.link || "",
                                    linkText: user.linkText || "",
                                    bio: user.bio,
                                    intro: user.intro,
                                    introHtml: user.introHtml,
                                    city: user.location?.city,
                                    country: user.location?.country,
                                    startups,
                                  });

                                  setIsSidebarOpen(false);
                                  setIsProfileSidebarOpen(false);
                                  setIsProfileCardOpen(true);
                                } catch (error) {
                                  console.error(
                                    "Error opening profile:",
                                    error
                                  );
                                }
                              }}
                            >
                              <img
                                src={
                                  user.profileImage &&
                                  !user.profileImage.includes("default.jpg") &&
                                  !user.profileImage.includes(
                                    "profileImage.jpg"
                                  )
                                    ? user.profileImage
                                    : defaultSvgImage
                                }
                                alt={user.name}
                                className={styles.profileImage}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = defaultSvgImage;
                                }}
                              />
                              <div className={styles.profileName}>
                                {user.name.split(" ")[0]}
                              </div>
                              <div className={styles.profileBio}>
                                {user.bio}
                              </div>
                              <div className={styles.viewLink}>
                                <span
                                  className="material-symbols-outlined"
                                  style={{
                                    fontSize: "1.25rem",
                                    color: "gray",
                                    transform: "rotate(45deg)",
                                  }}
                                >
                                  arrow_upward
                                </span>
                                View
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))
            )}
            {isLoading && !directChat && (
              <div className={styles.loadingMessage}>
                <span
                  className={`material-symbols-outlined ${styles.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            )}
          </div>

          <form
            onSubmit={handleSubmit}
            className={`${styles.inputForm} ${
              !discoverMode && messages.length === 0
                ? styles.centeredInputForm
                : ""
            }`}
          >
            <textarea
              ref={textareaRef}
              className={`${
                styles.chat
              } transition-all duration-500 ease-in-out ${
                isTransitioning
                  ? "opacity-0 transform -translate-y-2"
                  : "opacity-100 transform translate-y-0"
              }`}
              placeholder={
                discoverMode
                  ? "Message everyone..."
                  : directChat
                  ? `Message ${
                      directChat.otherUser?.firstName ||
                      directChat.otherUser?.name?.split(" ")[0] ||
                      "User"
                    }...`
                  : "Ask anything..."
              }
              value={input}
              onChange={(e) => {
                // When in discover mode, limit input to 500 characters
                if (discoverMode && e.target.value.length > 2500) {
                  setInput(e.target.value.substring(0, 2500));
                } else {
                  setInput(e.target.value);
                }
                adjustTextareaHeight();
              }}
              onKeyPress={handleKeyPress} // Add this line to handle key presses
              rows={1}
              autoFocus={true}
            />

            {/* Replace the existing control bar in the input form */}
            {/* Replace the existing control bar in the input form */}
            <div className={styles.controlBar}>
              {directChat ? (
                // Show user info in direct chat mode
                <div
                  className={styles.tag}
                  onClick={async () => {
                    if (directChat && directChat.otherUser) {
                      try {
                        const userId = directChat.otherUser.id;

                        // Fetch the complete user document to get all details
                        const userDoc = await getDoc(
                          doc(firestore, "users", userId)
                        );
                        if (!userDoc.exists()) {
                          console.error("User document not found");
                          return;
                        }

                        const userData = userDoc.data();

                        // Get the user's startups collection
                        const startupsSnapshot = await getDocs(
                          collection(firestore, `users/${userId}/startups`)
                        );

                        const startups = startupsSnapshot.docs.map((doc) => ({
                          id: doc.id,
                          name: doc.data().startupName || doc.data().name,
                          startupImage: doc.data().startupImage,
                          bio: doc.data().bio || "",
                          intro: doc.data().intro || "",
                          introHtml: doc.data().introHtml || "",
                          city: doc.data().city || "",
                          country: doc.data().country || "",
                          link: doc.data().link || "",
                          linkText: doc.data().linkText || "",
                          industry: doc.data().industry || "",
                          tags: doc.data().tags || [],
                          ...doc.data(),
                        }));

                        // Properly handle the profile image
                        const userProfileImage =
                          userData.profileImage || directChat.otherUser.image;
                        const displayProfileImage =
                          userProfileImage &&
                          !userProfileImage.includes("default.jpg") &&
                          !userProfileImage.includes("profileImage.jpg")
                            ? userProfileImage
                            : defaultSvgImage;

                        // Set the selected user profile data with all available information
                        setSelectedUserProfile({
                          id: userId,
                          firstName:
                            userData.firstName ||
                            directChat.otherUser.firstName ||
                            directChat.otherUser.name?.split(" ")[0] ||
                            "",
                          lastName:
                            userData.lastName ||
                            directChat.otherUser.lastName ||
                            directChat.otherUser.name
                              ?.split(" ")
                              .slice(1)
                              .join(" ") ||
                            "",
                          name:
                            userData.firstName && userData.lastName
                              ? `${userData.firstName} ${userData.lastName}`
                              : directChat.otherUser.name || "",
                          profileImage: displayProfileImage,
                          bio: userData.bio || directChat.otherUser.bio || "",
                          intro:
                            userData.intro || directChat.otherUser.intro || "",
                          introHtml:
                            userData.introHtml ||
                            directChat.otherUser.introHtml ||
                            "",
                          city:
                            userData.city || directChat.otherUser.city || "",
                          country:
                            userData.country ||
                            directChat.otherUser.country ||
                            "",
                          link:
                            userData.link || directChat.otherUser.link || "",
                          linkText:
                            userData.linkText ||
                            directChat.otherUser.linkText ||
                            "",
                          skills: userData.skills || [],
                          interests: userData.interests || [],
                          education: userData.education || [],
                          experiences: userData.experiences || [],
                          startups: startups,
                        });

                        // Open the profile sidebar and close others
                        setIsSidebarOpen(false);
                        setIsProfileSidebarOpen(false);
                        setIsProfileCardOpen(true);
                      } catch (error) {
                        console.error("Error opening profile:", error);
                      }
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={
                      directChat.otherUser.image &&
                      !directChat.otherUser.image.includes("default.jpg") &&
                      !directChat.otherUser.image.includes("profileImage.jpg")
                        ? directChat.otherUser.image
                        : defaultSvgImage
                    }
                    alt={directChat.otherUser.name || "user"}
                    className={styles.img}
                    style={{ borderRadius: "50%" }} // Apply 50% border radius for user images
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultSvgImage;
                    }}
                  />
                  {directChat.otherUser.firstName ||
                    directChat.otherUser.name?.split(" ")[0] ||
                    "user"}
                </div>
              ) : (
                // Show foundry logo in AI chat mode
                <div className={styles.tag}>
                  <img src={logo} alt="foundry" className={styles.img} />
                  FOU-o1
                </div>
              )}
              <button
                className={styles.sendButton}
                type="submit"
                aria-label="Send message"
                disabled={!input.trim() || isLoading}
              >
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "1.25rem" }}
                >
                  arrow_upward
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Chat;
