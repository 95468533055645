import React from "react";
import { useNavigate } from "react-router-dom"; // Import for navigation
import styles from "./LandingSidebar.module.css";
import logo from "../../assets/images/foundry.png";

const LandingSidebar = ({ isOpen, onToggle, darkMode = false }) => {
  const navigate = useNavigate(); // Initialize the navigate function

  // Function to handle navigation instead of direct links
  const handleNavigation = (path, e) => {
    e.preventDefault(); // Prevent default link behavior
    navigate(path); // Navigate within the current window
  };

  // Redirect to signup for non-functional features
  const redirectToSignup = () => {
    navigate("/signup");
  };

  return (
    <>
      <aside
        className={`${styles.sidebar} ${isOpen ? styles.open : ""} ${
          darkMode ? styles.darkMode : ""
        }`}
      >
        {/* Top bar with logo - copied from Sidebar.js */}
        <div className={`${styles.topBar} ${darkMode ? styles.darkMode : ""}`}>
          {/* Logo on the left */}
          <button
            className={styles.logoButton}
            type="button"
            aria-label="Logo"
            onClick={redirectToSignup}
          >
            <img src={logo} alt="foundry" className={styles.logo} />
            <div className={styles.foundry}>Foundry</div>
          </button>

          {/* Right buttons for toggle sidebar */}
          <div className={styles.topBarRightButtons}>
            <button
              className={styles.iconButton}
              type="button"
              aria-label="Toggle Sidebar"
              onClick={onToggle}
            >
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                keyboard_double_arrow_left
              </span>
            </button>
            <button
              className={styles.iconButton}
              type="button"
              aria-label="Edit"
              onClick={redirectToSignup}
            >
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                edit_square
              </span>
            </button>
          </div>
        </div>

        {/* Main navigation menu - similar to Sidebar.js structure */}
        <div className={styles.topHalf}>
          {/* Search Button */}
          <button
            className={styles.searchButton}
            onClick={redirectToSignup}
            type="button"
          >
            <div className={styles.chatButtonContent}>
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  marginRight: "0.5rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                Search
              </span>
              <span className={styles.chatTitle}>Search</span>
            </div>
          </button>

          {/* Analytics Button */}
          <button
            className={styles.chatButton}
            onClick={redirectToSignup}
            type="button"
          >
            <div className={styles.chatButtonContent}>
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  marginRight: "0.5rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                monitoring
              </span>
              <span className={styles.chatTitle}>Analytics</span>
            </div>
          </button>

          {/* Inbox Button */}
          <button
            className={styles.chatButton}
            onClick={redirectToSignup}
            type="button"
          >
            <div className={styles.chatButtonContent}>
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  marginRight: "0.5rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                Inbox
              </span>
              <span className={styles.chatTitle}>Inbox</span>
            </div>
          </button>
        </div>

        {/* Empty space to push content to bottom */}
        <div className={styles.spacer}></div>

        {/* Main content at bottom */}

        <div className={styles.buttons}>
          <a
            href="/signup"
            className={styles.tryButton}
            onClick={(e) => handleNavigation("/signup", e)}
          >
            Try Foundry
          </a>

          <a
            href="/login"
            className={styles.loginButton}
            onClick={(e) => handleNavigation("/login", e)}
          >
            Log In
          </a>
        </div>

        {/* Footer with terms, privacy, and beta tag */}
        <div className={`${styles.footer} ${darkMode ? styles.darkMode : ""}`}>
          <div className={styles.links}>
            <a
              href="/terms"
              className={styles.smallPrint}
              onClick={(e) => handleNavigation("/terms", e)}
            >
              Terms
            </a>
            <a
              href="/privacy-policy"
              className={styles.smallPrint}
              onClick={(e) => handleNavigation("/privacy-policy", e)}
            >
              Privacy Policy
            </a>
            <div className={styles.tag}>Beta/V1.0</div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default LandingSidebar;
