import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import classes from "./ForgotPassword.module.css";

function ForgotPassword() {
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);
  const [resetSent, setResetSent] = useState(false);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.toLowerCase();
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(emailValue));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEmailValid) {
      setErrorPopup({
        message: "Please enter a valid email to reset your password.",
        isError: true,
      });
      return;
    }

    setLoading(true);
    setErrorPopup(null);
    try {
      await resetPassword(email);
      setResetSent(true);
    } catch (error) {
      setErrorPopup({
        message:
          "Failed to reset password. Please check your email and try again.",
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const ErrorPopup = ({ message, isError, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          {isError ? (
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="red"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clipRule="evenodd"
              />
            </svg>
          )}
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.page}>
      <div className={classes.closeButton} onClick={() => navigate("/")}>
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "1.25rem", color: "gray" }}
        >
          close
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>
                Enter your email below to reset your password
              </p>
            </div>

            <form className={classes.interactions} onSubmit={handleSubmit}>
              <label htmlFor="email" className={classes.label}>
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Ex: name@example.com"
                className={classes.email}
                value={email}
                onChange={handleEmailChange}
                disabled={loading} // Removed the resetSent condition
              />
              <div className={classes.smallInfo}>
                {resetSent
                  ? "We've sent you a password reset link"
                  : "We'll send you a password reset link"}
              </div>
              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={!isEmailValid || loading} // Removed the resetSent condition
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Send reset link"
                )}
              </button>
            </form>

            <div className={classes.divider}>
              <span>
                Remember your password?{" "}
                <a href="/login" className={classes.link}>
                  Log In
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
