// UserCarousel.js
import { useState, useEffect, useRef } from "react";
import styles from "./UserCarousel.module.css";

// Default image to use if user profile image is missing or fails to load
const defaultSvgImage =
  "https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/userImage.png?alt=media&token=e47b51db-e00b-48c8-8923-fdee9a181589";

const UserCarousel = ({ allUsers = [], onUserSelect, currentUserId }) => {
  const [users, setUsers] = useState([]);
  const carouselRef = useRef(null);

  // Questions that appear below the carousel
  const questions = [
    "who are some people i should invest in?",
    "who is making short form content?",
    "who can help me build something?",
  ];

  // Setup initial users array on component mount
  useEffect(() => {
    // Make sure currentUserId exists before filtering
    if (!currentUserId) {
      console.warn("UserCarousel: currentUserId is not provided");
    }

    console.log("Current user ID:", currentUserId);

    // Filter and map users to the format we need
    const formattedUsers = allUsers
      .filter((user) => {
        // For debugging - log any potential matching IDs
        if (user.id === currentUserId) {
          console.log("Found current user in allUsers:", user);
        }

        return (
          // Filter out users with no name or no bio
          (user.firstName || user.lastName) &&
          user.bio &&
          // Filter out current user - ensure string comparison if IDs could be different types
          String(user.id) !== String(currentUserId)
        );
      })
      .map((user) => ({
        id: user.id,
        name: `${user.firstName || ""} ${user.lastName || ""}`.trim(),
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        description:
          (user.bio || user.intro || "").slice(0, 120) +
          (user.bio?.length > 120 ? "..." : ""),
        profileImage: user.profileImage || defaultSvgImage,
        bio: user.bio || "",
        startups: user.startups || [],
      }));

    console.log("Filtered users count:", formattedUsers.length);

    // Shuffle and duplicate users for continuous scrolling
    const shuffled = shuffleArray(formattedUsers);

    // Use at least 10 cards in the carousel to ensure smooth scrolling
    const minCards = 10;
    let duplicated = [...shuffled];

    // If we don't have enough users, duplicate them until we have at least minCards
    while (duplicated.length < minCards && shuffled.length > 0) {
      duplicated = [...duplicated, ...shuffled];
    }

    setUsers(duplicated);
  }, [allUsers, currentUserId]); // Add currentUserId to dependency array

  // Set up continuous leftward movement with optimized animation
  useEffect(() => {
    if (users.length === 0 || !carouselRef.current) return;

    // Animation speed - pixels per second (lower = slower)
    const pixelsPerSecond = 40;

    let lastTimestamp = 0;
    let animationFrameId;
    let currentPosition = 0;

    // Get the width of the first card to calculate movement
    const updateCardWidth = () => {
      const cardElement = carouselRef.current.querySelector(
        `.${styles.userCard}`
      );
      if (!cardElement) return null;

      const cardWidth = cardElement.offsetWidth;
      const cardMargin =
        parseInt(window.getComputedStyle(cardElement).marginRight) || 16;
      return cardWidth + cardMargin;
    };

    // Initial width measurement
    let moveDistance = updateCardWidth();
    if (!moveDistance) return;

    // Calculate the point at which we need to reset (half the total width)
    const getTotalWidth = () => moveDistance * users.length;

    const resetIfNeeded = () => {
      // Get new measurements in case of window resize
      moveDistance = updateCardWidth() || moveDistance;

      const totalWidth = getTotalWidth();

      // Reset when we've scrolled through half the cards
      if (currentPosition <= -totalWidth / 2) {
        currentPosition = 0;
      }
    };

    const animate = (timestamp) => {
      if (!lastTimestamp) lastTimestamp = timestamp;
      const elapsed = timestamp - lastTimestamp;

      // Calculate movement based on elapsed time
      const pixelsToMove = (pixelsPerSecond * elapsed) / 1000;

      // Update position
      currentPosition -= pixelsToMove;

      // Check for reset
      resetIfNeeded();

      // Apply the new position
      if (carouselRef.current) {
        carouselRef.current.style.transform = `translateX(${currentPosition}px)`;
      }

      lastTimestamp = timestamp;
      animationFrameId = requestAnimationFrame(animate);
    };

    // Start animation
    animationFrameId = requestAnimationFrame(animate);

    // Add resize listener to update card width if window size changes
    const handleResize = () => {
      moveDistance = updateCardWidth() || moveDistance;
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, [users, styles.userCard]);

  // Function to shuffle an array (Fisher-Yates algorithm)
  function shuffleArray(array) {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  }

  // Handle question click - calls the provided onUserSelect function with the question
  const handleQuestionClick = (question) => {
    if (onUserSelect) {
      onUserSelect(question);
    }
  };

  // Handle user card click - calls the provided onUserSelect function with the userId
  const handleUserClick = (userId) => {
    if (onUserSelect && userId) {
      onUserSelect(null, userId);
    }
  };

  if (users.length === 0) {
    return null; // Don't render if no users
  }

  return (
    <div className={styles.userCarousel}>
      <div className={styles.carouselWrapper}>
        <div className={styles.carouselContainer} ref={carouselRef}>
          {users.map((user, index) => (
            <div
              key={`${user.id}-${index}`}
              className={styles.userCard}
              onClick={() => handleUserClick(user.id)}
            >
              <div className={styles.userImage}>
                <img
                  src={
                    user.profileImage &&
                    !user.profileImage.includes("default.jpg") &&
                    !user.profileImage.includes("profileImage.jpg")
                      ? user.profileImage
                      : defaultSvgImage
                  }
                  alt={user.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = defaultSvgImage;
                  }}
                />
              </div>
              <div className={styles.userInfo}>
                <h3 className={styles.userName}>{user.firstName}</h3>
                <p className={styles.userDescription}>{user.description}</p>
              </div>
              <div className={styles.viewLink}>
                <span
                  className="material-symbols-outlined"
                  style={{
                    fontSize: "1.25rem",
                    color: "gray",
                    transform: "rotate(45deg)",
                  }}
                >
                  arrow_upward
                </span>
                View
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserCarousel;
