import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { firestore } from "../../../firebase";
import {
  doc,
  updateDoc,
  collection,
  setDoc,
  addDoc,
  getDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import CustomDropdown from "../../../components/Dropdowns/CustomDropdown";
import logo from "../../../assets/images/foundry.png";
import styles from "./LocationSidebar.module.css";

const functions = getFunctions(undefined, "europe-west1");

const config = {
  cUrl: "https://api.countrystatecity.in/v1",
  ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
};

const LocationSidebar = ({ isOpen, onToggle, darkMode = false }) => {
  const { currentUser } = useAuth();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [countryLoading, setCountryLoading] = useState(true);
  const [cityLoading, setCityLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);
  const cityCache = useMemo(() => ({}), []);
  const [loadingDots, setLoadingDots] = useState(1);

  // Effect for loading animation
  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setLoadingDots((prev) => (prev % 3) + 1);
      }, 500);
    }
    return () => clearInterval(interval);
  }, [loading]);

  // Function to render the loading animation
  const renderLoadingSymbols = () => {
    if (loadingDots === 1) return ">";
    if (loadingDots === 2) return "> >";
    return "> > >";
  };

  useEffect(() => {
    const loadCountries = async () => {
      setCountryLoading(true);
      try {
        const response = await fetch(`${config.cUrl}/countries`, {
          headers: { "X-CSCAPI-KEY": config.ckey },
        });
        const data = await response.json();
        setCountries(data);
      } catch (error) {
      } finally {
        setCountryLoading(false);
      }
    };

    loadCountries();
  }, []);

  const loadCities = useCallback(async () => {
    setCityLoading(true);
    try {
      const response = await fetch(
        `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
        {
          headers: { "X-CSCAPI-KEY": config.ckey },
        }
      );
      const data = await response.json();
      cityCache[selectedCountry.iso2] = data;
      setCities(data);
    } catch (error) {
    } finally {
      setCityLoading(false);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    if (selectedCountry) {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        loadCities();
      }
    }
  }, [selectedCountry, cityCache, loadCities]);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCities([]);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCountry || !selectedCity) {
      setErrorPopup({
        message: "Please select your country and city.",
        isError: true,
      });
      return;
    }

    setLoading(true);
    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);

      await updateDoc(userDocRef, {
        country: selectedCountry.name,
        countryISO2: selectedCountry.iso2,
        city: selectedCity.name,
        createdAt: new Date().toISOString(),
      });

      const userSnapshot = await getDoc(userDocRef);
      const userData = userSnapshot.data();

      // Try to add user to SendGrid
      try {
        const addContactToSendGrid = httpsCallable(
          functions,
          "addContactToSendGrid",
          {
            timeout: 60000,
          }
        );

        const result = await addContactToSendGrid({
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
        });
      } catch (sendGridError) {
        console.error("SendGrid Error Details:", {
          message: sendGridError.message,
          code: sendGridError.code,
          details: sendGridError.details,
          stack: sendGridError.stack,
        });

        // Store failed attempt
        try {
          await setDoc(
            doc(firestore, `failedSendGridAttempts/${currentUser.uid}`),
            {
              email: userData.email,
              firstName: userData.firstName,
              lastName: userData.lastName,
              timestamp: new Date(),
              error: sendGridError.message,
              details: sendGridError.details || null,
              code: sendGridError.code || null,
            }
          );
        } catch (storeError) {
          console.error("Failed to store SendGrid error:", storeError);
        }
      }

      const connectionsCollectionRef = collection(userDocRef, "connections");
      const dummyDocRef = doc(connectionsCollectionRef, "dummyId");
      await setDoc(dummyDocRef, {
        connectedAt: new Date().toISOString(),
        status: "Connected",
      });

      const notificationsRef = collection(
        firestore,
        `users/${currentUser.uid}/notifications`
      );
      await addDoc(notificationsRef, {
        type: "welcome",
        title: "Welcome to Foundry! 👋",
        createdAt: new Date(),
        isStartup: true,
        isNew: true,
        startupImage:
          "https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/foundry.png?alt=media&token=050d8cd0-b482-4154-b448-ae4236850bbf",
        startupUrlSlug: "foundry",
      });

      navigate("/");
    } catch (error) {
      console.error("Main try-catch error:", {
        message: error.message,
        code: error.code,
        details: error.details,
        stack: error.stack,
      });

      setErrorPopup({
        message: "Failed to update location. Please try again.",
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const isSubmitDisabled =
    loading ||
    !selectedCountry ||
    !selectedCity ||
    countryInputValue !== selectedCountry?.name ||
    cityInputValue !== selectedCity?.name;

  return (
    <>
      <aside
        className={`${styles.sidebar} ${isOpen ? styles.open : ""} ${
          darkMode ? styles.darkMode : ""
        }`}
      >
        {/* Top bar with logo - matching LandingSidebar */}
        <div className={`${styles.topBar} ${darkMode ? styles.darkMode : ""}`}>
          {/* Logo on the left */}
          <a
            href="/"
            className={styles.logoButton}
            type="button"
            aria-label="Logo"
          >
            <img src={logo} alt="foundry" className={styles.logo} />
          </a>

          {/* Right buttons for toggle sidebar */}
          <div className={styles.topBarRightButtons}>
            <button
              className={styles.iconButton}
              type="button"
              aria-label="Toggle Sidebar"
              onClick={onToggle}
            >
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                keyboard_double_arrow_left
              </span>
            </button>
          </div>
        </div>

        {/* Add spacer to push content to bottom */}
        <div className={styles.spacer}></div>

        {/* Content wrapper for bottom content */}
        <div className={styles.contentWrapper}>
          <h1 className={styles.title}>where are you based?</h1>
          {/* <p className={styles.subtitle}>where are you based?</p> */}

          <div className={styles.buttons}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <CustomDropdown
                id="country"
                options={countries}
                selectedValue={selectedCountry}
                onChange={handleCountryChange}
                inputValue={countryInputValue}
                setInputValue={setCountryInputValue}
                disabled={loading}
                placeholder="country"
                loading={countryLoading}
              />

              <CustomDropdown
                id="city"
                options={cities}
                selectedValue={selectedCity}
                onChange={handleCityChange}
                inputValue={cityInputValue}
                setInputValue={setCityInputValue}
                disabled={!selectedCountry || loading}
                placeholder="city"
                loading={cityLoading}
              />

              <button
                type="submit"
                className={`${styles.link} ${
                  isSubmitDisabled ? styles.disabled : ""
                }`}
                disabled={isSubmitDisabled}
                style={{
                  opacity: isSubmitDisabled ? 0.5 : 1,
                  justifyContent: "flex-start",
                }}
              >
                <div className={styles.linkIcon}>
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize: "1.25rem",
                      transform: "rotate(45deg)",
                    }}
                  >
                    arrow_upward
                  </span>
                </div>
                <span className={styles.linkText} style={{ textAlign: "left" }}>
                  {loading ? renderLoadingSymbols() : "continue"}
                </span>
              </button>
            </form>
          </div>
        </div>

        <div className={`${styles.footer} ${darkMode ? styles.darkMode : ""}`}>
          <div className={styles.links}>
            <a href="/t&c's" className={styles.smallPrint}>
              terms
            </a>
            <a href="/privacy-policy" className={styles.smallPrint}>
              privacy
            </a>
            <div className={styles.tag}>beta/v 1.0</div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default LocationSidebar;
