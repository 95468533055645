import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook for navigation
import LandingSidebar from "./LandingSidebar";
import UserCarousel from "../Main/Chat/UserCarousel";
import SuggestionCarousel from "../Main/Chat/SuggestionCarousel";
import styles from "./Landing.module.css";
import defaultImage from "../../assets/icons/profileImage.jpg";
import logo from "../../assets/images/foundry.png";

// Firebase imports
import { firestore } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

// Default SVG image for users without profile pictures
const defaultSvgImage =
  "https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/userImage.png?alt=media&token=e47b51db-e00b-48c8-8923-fdee9a181589";

// AI image
const aiImage =
  "https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/foundry.png?alt=media&token=050d8cd0-b482-4154-b448-ae4236850bbf";

// Placeholders for the input field
const placeholders = [
  "Help me find people who like hiking",
  "Who's interested in photography near me?",
  "Looking for web developers in chicago",
  "Show me experts in digital marketing",
  "Who's working on climate change projects?",
  "Connect me with designers in my area",
  "Find people with knowledge about gardening",
  "Anyone teaching piano lessons?",
  "People interested in book clubs near me",
  "Who can help with learning Spanish?",
];

// Generic placeholders for direct chat
const directChatPlaceholders = [
  "What are you passionate about these days?",
  "I'd love to hear about your interests",
  "What projects are you currently working on?",
  "How did you get started with your hobby?",
  "Would you be interested in collaborating?",
  "I found your profile interesting because...",
  "Do you have any recommendations for...",
  "I'd be grateful for your advice on...",
  "Have you discovered any great resources lately?",
  "I'd like to learn more about your experience with...",
];

// Generic placeholders for discover mode
const discoverPlaceholders = [
  "Just learned something interesting about...",
  "Looking for people to join my...",
  "Anyone interested in discussing...",
  "Sharing my thoughts on...",
  "Seeking advice about...",
  "Excited to meet people who...",
  "Would love feedback on my...",
  "Organizing an event about...",
  "Recently started exploring...",
  "Asking for recommendations on...",
];

const Landing = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const messagesContainerRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 960);
  const [currentPlaceholder, setCurrentPlaceholder] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const textareaRef = useRef(null);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Navigation function to redirect to signup
  const navigateToSignup = () => {
    navigate("/signup");
  };

  // Cycle through placeholders
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentPlaceholder((prev) => (prev + 1) % placeholders.length);
        setIsTransitioning(false);
      }, 200);
    }, 2500);

    return () => clearInterval(intervalId);
  }, []);

  // Handle responsive sidebar
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 960) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch users from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersRef = collection(firestore, "users");
        const snapshot = await getDocs(usersRef);

        const userData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const user = {
              id: doc.id,
              ...doc.data(),
            };

            // Only fetch the startups/pages collection
            const startupsSnapshot = await getDocs(
              collection(doc.ref, "startups")
            );
            user.startups = startupsSnapshot.docs.map((startup) =>
              startup.data()
            );

            return user;
          })
        );

        setAllUsers(userData);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Updated handler for suggestions and users to navigate to signup
  const handleCarouselSelect = () => {
    navigateToSignup();
  };

  return (
    <div
      className={`${styles.container} ${
        isSidebarOpen ? styles.withSidebar : ""
      }`}
    >
      <div className={styles.topBlock}></div>
      {!isSidebarOpen && (
        <button
          className={styles.sidebarOpenButton}
          onClick={() => setIsSidebarOpen(true)}
          aria-label="Open sidebar"
          type="button"
        >
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "1.25rem" }}
          >
            keyboard_double_arrow_right
          </span>
        </button>
      )}
      <LandingSidebar
        isOpen={isSidebarOpen}
        onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
      />

      <div
        className={`${styles.mainContent} ${
          isSidebarOpen ? styles.withSidebar : ""
        }`}
      >
        <div className={styles.chatContainer}>
          <div className={styles.messagesContainer} ref={messagesContainerRef}>
            {/* Empty state with welcome message and carousels */}
            <div className={styles.welcomeMessage}>
              <h1 className={styles.welcomeTitle}>Who are you looking for?</h1>
              <UserCarousel
                allUsers={allUsers}
                onUserSelect={handleCarouselSelect}
                currentUserId="demo-user" // Providing a dummy ID since we're not logged in
              />
              <SuggestionCarousel
                suggestions={placeholders}
                onSuggestionSelect={handleCarouselSelect}
              />
            </div>
          </div>

          <form
            className={styles.inputForm}
            onSubmit={(e) => e.preventDefault()}
          >
            <textarea
              ref={textareaRef}
              className={`${
                styles.chat
              } transition-all duration-500 ease-in-out ${
                isTransitioning
                  ? "opacity-0 transform -translate-y-2"
                  : "opacity-100 transform translate-y-0"
              }`}
              placeholder="Ask anything..."
              readOnly
              rows={1}
              onClick={navigateToSignup} // Added onClick handler to navigate to signup
            />
            <div className={styles.controlBar}>
              <div className={styles.tag}>
                <img src={logo} alt="foundry" className={styles.img} />
                FOU-o1
              </div>
              <button
                className={styles.sendButton}
                type="submit"
                aria-label="Send message"
                disabled={true}
              >
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "1.25rem" }}
                >
                  arrow_upward
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Landing;
