import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore, arrayUnion } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from "firebase/messaging";
import { doc, updateDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD5c-Ecgxv0C5WrHNQx02lqrjrF00bkNYE",
  authDomain: "foundry-2024.firebaseapp.com",
  projectId: "foundry-2024",
  storageBucket: "foundry-2024.appspot.com",
  messagingSenderId: "608195603992",
  appId: "1:608195603992:web:eac09fbc51d9a375b89d6d",
  measurementId: "G-9ZZ740K9S9",
};

// Enhanced in-app browser detection with special LinkedIn handling
export const isInAppBrowser = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return /FBAN|FBAV|Instagram|Line|WhatsApp|Twitter|WebView/i.test(ua);
};

export const isLinkedInBrowser = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return /LinkedIn/i.test(ua);
};

// Safe initialization of Firebase
const app = initializeApp(firebaseConfig);

// Initialize services with defensive checks
let analytics = null;
let firestore = null;
let auth = null;
let storage = null;
let functions = null;
let messaging = null;

try {
  analytics = getAnalytics(app);
} catch (e) {
  console.log("Analytics initialization failed");
}

try {
  firestore = getFirestore(app);
} catch (e) {
  console.log("Firestore initialization failed");
}

try {
  auth = getAuth(app);
} catch (e) {
  console.log("Auth initialization failed");
}

try {
  storage = getStorage(app);
} catch (e) {
  console.log("Storage initialization failed");
}

try {
  functions = getFunctions(app, "europe-west1");
} catch (e) {
  console.log("Functions initialization failed");
}

// Only initialize messaging in supported environments
try {
  // Messaging is particularly problematic in LinkedIn
  if (
    !isInAppBrowser() &&
    !isLinkedInBrowser() &&
    "serviceWorker" in navigator &&
    "Notification" in window
  ) {
    messaging = getMessaging(app);
  }
} catch (e) {
  console.log("Messaging initialization failed");
}

export const requestNotificationPermission = async () => {
  try {
    if (!auth || !auth.currentUser) {
      return null;
    }

    const currentUser = auth.currentUser;

    if (
      isInAppBrowser() ||
      isLinkedInBrowser() ||
      !messaging ||
      !("serviceWorker" in navigator)
    ) {
      return null;
    }

    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      return null;
    }

    const idToken = await currentUser.getIdToken(true);

    let registration;
    try {
      registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js"
      );
      await navigator.serviceWorker.ready;
    } catch (e) {
      console.log("Service worker registration failed", e);
      return null;
    }

    try {
      await deleteToken(messaging);
    } catch (e) {}

    let token = null;
    if (registration) {
      try {
        token = await getToken(messaging, {
          vapidKey:
            "BFeLhiG5h4hnSnQBVPUmSTjykGVjIeZRBbUx03rQ1B6jeOdF1VQ7eHH8eDF8I2oPGAHhJT4TWG6OOLRTTOzAwUo",
          serviceWorkerRegistration: registration,
        });
      } catch (e) {
        console.log("Getting FCM token failed", e);
        return null;
      }
    }

    if (!token || !firestore) {
      return null;
    }

    try {
      const userRef = doc(firestore, "users", currentUser.uid);
      await updateDoc(userRef, {
        fcmToken: token,
        lastTokenUpdate: new Date(),
      });
    } catch (e) {
      console.log("Saving FCM token failed", e);
    }

    return token;
  } catch (error) {
    console.log(
      "FCM permission error (safe to ignore in some browsers)",
      error
    );
    return null;
  }
};

export const onMessageListener = () => {
  // If in in-app browser or messaging not initialized, immediately return resolved promise
  if (
    isInAppBrowser() ||
    isLinkedInBrowser() ||
    !messaging ||
    !("serviceWorker" in navigator)
  ) {
    return Promise.resolve(null);
  }

  try {
    // Only run the real listener in supported environments
    return new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
  } catch (e) {
    console.log("Message listener setup failed", e);
    return Promise.resolve(null);
  }
};

export {
  app,
  analytics,
  auth,
  firestore,
  storage,
  functions,
  messaging,
  GoogleAuthProvider,
  signInWithPopup,
  httpsCallable,
};
