import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import defaultImage from "../../assets/icons/profileImage.jpg";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestore } from "../../firebase";
import classes from "./ProfileModal.module.css";
import CustomDropdown from "../Dropdowns/CustomDropdown";
import imageCompression from "browser-image-compression";
import Cropper from "react-easy-crop";
import { updateUserUrlSlug } from "../../utils/urlSlugUtils";
import { convertUrlsToLinks } from "../../utils/textUtils";
import { useNavigate } from "react-router-dom";

const config = {
  cUrl: "https://api.countrystatecity.in/v1",
  ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
};

const ProfileModal = ({ onClose, currentUser, onProfileUpdate }) => {
  const navigate = useNavigate();
  const [intro, setIntro] = useState("");
  const textAreaRef = useRef(null);
  const [loadingDots, setLoadingDots] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bio, setBio] = useState("");
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [currentProfileImageUrl, setCurrentProfileImageUrl] = useState(null);
  const [initialData, setInitialData] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const cityCache = useMemo(() => ({}), []);
  const formRef = useRef(null);
  const bioTextareaRef = useRef(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropping, setIsCropping] = useState(false);

  // State for multiple links
  const [links, setLinks] = useState([]);
  const [linkErrors, setLinkErrors] = useState([]);
  const [errorPopup, setErrorPopup] = useState(null);
  const [showAddLink, setShowAddLink] = useState(true);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setLoadingDots((prev) => (prev % 3) + 1);
      }, 500);
    }
    return () => clearInterval(interval);
  }, [loading]);

  const renderLoadingSymbols = () => {
    if (loadingDots === 1) return ">";
    if (loadingDots === 2) return "> >";
    return "> > >";
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setInitialData(userData);
          setFirstName(userData.firstName || "");
          setLastName(userData.lastName || "");
          setBio(userData.bio || "");
          setIntro(userData.intro || "");
          setCurrentProfileImageUrl(userData.profileImage || "");

          // Handle links
          if (userData.links && userData.links.length > 0) {
            setLinks(userData.links);
            setShowAddLink(false);
          } else if (userData.link) {
            // Convert old single link format to new format
            setLinks([
              {
                url: userData.link || "",
                label: userData.linkText || "",
              },
            ]);
            setShowAddLink(false);
          } else {
            setLinks([]);
            setShowAddLink(true);
          }

          if (userData.country && userData.city) {
            setSelectedCountry({
              name: userData.country,
              iso2: userData.countryISO2,
            });
            setCountryInputValue(userData.country);
            setSelectedCity({ name: userData.city });
            setCityInputValue(userData.city);
          }
        }
      } catch (error) {
        setErrorPopup({
          message: "Failed to load user data. Please try again.",
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    };

    const loadCountries = async () => {
      try {
        const response = await fetch(`${config.cUrl}/countries`, {
          headers: { "X-CSCAPI-KEY": config.ckey },
        });
        const data = await response.json();
        setCountries(Array.isArray(data) ? data : []);
      } catch (error) {
        setCountries([]);
      }
    };

    fetchUserData();
    loadCountries();
  }, [currentUser.uid]);

  const loadCities = useCallback(async () => {
    if (!selectedCountry) return;
    try {
      const response = await fetch(
        `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
        {
          headers: { "X-CSCAPI-KEY": config.ckey },
        }
      );
      const data = await response.json();
      cityCache[selectedCountry.iso2] = Array.isArray(data) ? data : [];
      setCities(cityCache[selectedCountry.iso2]);
    } catch (error) {
      setCities([]);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    if (selectedCountry) {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        loadCities();
      }
    }
  }, [selectedCountry, cityCache, loadCities]);

  const handleFirstNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setFirstName(e.target.value);
    }
  };

  const handleLastNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setLastName(e.target.value);
    }
  };

  const handleBioChange = (e) => {
    if (e.target.value.length <= 250) {
      setBio(e.target.value);
    }
  };

  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 8,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);

        const reader = new FileReader();
        reader.onload = () => {
          setCurrentProfileImageUrl(reader.result);
          setIsCropping(true); // Enable cropping when an image is uploaded
        };
        reader.readAsDataURL(compressedFile);

        setProfileImage(compressedFile);
        e.target.value = "";
      } catch (error) {
        setErrorPopup({
          message: "Failed to process the image. Please try again.",
          isError: true,
        });
      }
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCities([]);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
  };

  const validateUrl = (string) => {
    if (!string) return true; // Empty string is considered valid (optional)
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  // Handle links changes
  const handleLinkChange = (index, field, value) => {
    const newLinks = [...links];
    newLinks[index][field] = value;
    setLinks(newLinks);

    // Validate URL
    const newErrors = [...linkErrors];
    if (field === "url") {
      newErrors[index] = validateUrl(value) ? "" : "Please enter a valid URL";
    }
    setLinkErrors(newErrors);
  };

  const addLink = () => {
    setLinks([...links, { url: "", label: "" }]);
    setLinkErrors([...linkErrors, ""]);
    setShowAddLink(false);
  };

  const removeLink = (index) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);

    const newErrors = [...linkErrors];
    newErrors.splice(index, 1);
    setLinkErrors(newErrors);

    // Show the add link button if no links remain
    if (newLinks.length === 0) {
      setShowAddLink(true);
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  };

  const handleIntroChange = (e) => {
    if (e.target.value.length <= 2500) {
      setIntro(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName || !selectedCountry || !selectedCity) {
      setErrorPopup({
        message: "Please enter all details.",
        isError: true,
      });
      return;
    }

    // Validate all links
    const urlErrors = links.map((link) =>
      validateUrl(link.url) ? "" : "Please enter a valid URL"
    );
    if (urlErrors.some((error) => error)) {
      setLinkErrors(urlErrors);
      setErrorPopup({
        message: "Please fix the errors in your links.",
        isError: true,
      });
      return;
    }

    setLoading(true);
    setError("");

    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      const currentUserData = userDoc.data();

      let profileImageUrl = currentProfileImageUrl || "";
      if (profileImage && croppedAreaPixels) {
        const croppedImage = await getCroppedImg(
          currentProfileImageUrl,
          croppedAreaPixels
        );
        const response = await fetch(croppedImage);
        const blob = await response.blob();

        const storage = getStorage();
        const profileImageRef = ref(
          storage,
          `profileImages/${currentUser.uid}/profile.jpg`
        );
        await uploadBytes(profileImageRef, blob);
        profileImageUrl = await getDownloadURL(profileImageRef);
      }

      if (!profileImageUrl) {
        profileImageUrl = "";
      }

      // Only update urlSlug if the name has changed
      let newSlug = currentUserData.urlSlug;
      if (
        firstName !== currentUserData.firstName ||
        lastName !== currentUserData.lastName
      ) {
        newSlug = await updateUserUrlSlug(currentUser.uid, firstName, lastName);
      }

      // Process intro for links
      const trimmedIntro = intro.replace(/\s+$/g, "");
      const introHtml = convertUrlsToLinks(trimmedIntro);

      // Filter out empty links
      const validLinks = links.filter((link) => link.url.trim() !== "");

      const updatedProfile = {
        firstName,
        lastName,
        bio,
        intro: trimmedIntro,
        introHtml,
        country: selectedCountry.name,
        countryISO2: selectedCountry.iso2,
        city: selectedCity.name,
        profileImage: profileImageUrl,
        // Remove old single link format
        link: null,
        linkText: null,
        // Add new links array
        links: validLinks,
        urlSlug: newSlug,
      };

      const newProfileData = { ...userDoc.data(), ...updatedProfile };

      await updateDoc(userDocRef, newProfileData);

      onProfileUpdate(newProfileData);
      onClose(true);
    } catch (error) {
      setErrorPopup({
        message: "Failed to update profile. Please try again.",
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check for any changes in links
    const initialLinks =
      initialData.links ||
      (initialData.link
        ? [{ url: initialData.link, label: initialData.linkText || "" }]
        : []);

    const linksChanged =
      links.length !== initialLinks.length ||
      links.some((link, index) => {
        return (
          !initialLinks[index] ||
          link.url !== initialLinks[index].url ||
          link.label !== initialLinks[index].label
        );
      });

    const dataChanged =
      (firstName !== initialData.firstName && firstName !== "") ||
      (lastName !== initialData.lastName && lastName !== "") ||
      bio !== initialData.bio ||
      intro !== initialData.intro ||
      (selectedCountry && selectedCountry.name) !== initialData.country ||
      (selectedCity && selectedCity.name) !== initialData.city ||
      linksChanged ||
      profileImage;

    setIsChanged(dataChanged);
  }, [
    firstName,
    lastName,
    bio,
    intro,
    selectedCountry,
    selectedCity,
    links,
    profileImage,
    initialData,
  ]);

  useEffect(() => {
    // Validate all links
    const allLinksValid = links.every((link) => {
      return link.url.trim() === "" || validateUrl(link.url);
    });

    setIsFormValid(
      firstName.length > 0 &&
        lastName.length > 0 &&
        selectedCountry &&
        selectedCity &&
        allLinksValid
    );
  }, [firstName, lastName, selectedCountry, selectedCity, links]);

  useEffect(() => {
    const textarea = bioTextareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [bio]);

  const handleProfileImageClick = (e) => {
    e.preventDefault();
    const input = document.getElementById("profileImage");
    if (input) {
      input.click();
    }
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.page}>
      <div className={classes.topBlock}></div>
      <div className={classes.closeButton} onClick={() => onClose(false)}>
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "1.25rem", color: "gray" }}
        >
          close
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <form
              className={classes.interactions}
              onSubmit={handleSubmit}
              ref={formRef}
            >
              {/* <p
                style={{
                  fontSize: "0.875rem",
                  color: "#444",
                  textAlign: "left", 
                  marginBottom: "0.5rem",
                }}
              >
                Profile photo
              </p> */}

              <div
                className={classes.imageContainer}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <div className={classes.profileImage}>
                  {currentProfileImageUrl && isCropping ? (
                    <div className={classes.cropContainer}>
                      <Cropper
                        image={currentProfileImageUrl}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        cropShape="round"
                        showGrid={false}
                        cropSize={{ width: 96, height: 96 }}
                        objectFit="cover"
                      />
                    </div>
                  ) : (
                    <img src={currentProfileImageUrl || defaultImage} alt="" />
                  )}
                </div>
                <button
                  type="button"
                  className={classes.uploadButton}
                  onClick={handleProfileImageClick}
                  disabled={loading}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    marginLeft: "1rem",
                  }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize: "1rem",
                      color: "gray",
                    }}
                  >
                    add_a_photo
                  </span>
                  Change photo
                </button>
              </div>
              <input
                id="profileImage"
                type="file"
                accept="image/*"
                onChange={handleProfileImageChange}
                className={classes.profileButton}
                disabled={loading}
              />

              {/* Name fields container */}
              <div
                className={classes.formGroup}
                style={{ display: "flex", gap: "0.5rem" }}
              >
                <div style={{ width: "50%" }}>
                  <label htmlFor="firstName" className={classes.label}>
                    First name*
                  </label>
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="Ex: Brian"
                    className={classes.userInput}
                    value={firstName}
                    onChange={handleFirstNameChange}
                    disabled={loading}
                    style={{ width: "100%", margin: 0 }}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <label htmlFor="lastName" className={classes.label}>
                    Last name*
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Ex: Chesky"
                    className={classes.userInput}
                    value={lastName}
                    onChange={handleLastNameChange}
                    disabled={loading}
                    style={{ width: "100%", margin: 0 }}
                  />
                </div>
              </div>

              <label
                htmlFor="bio"
                style={{
                  display: "block",
                  fontSize: "0.875rem",
                  color: "gray",
                  marginBottom: "0.5rem",
                }}
              >
                Headline
              </label>
              <input
                id="bio"
                type="text"
                placeholder="Ex: Founder, CEO @ Airbnb"
                className={classes.userInput}
                value={bio}
                onChange={handleBioChange}
                disabled={loading}
              />

              <label
                htmlFor="country"
                style={{
                  display: "block",
                  fontSize: "0.875rem",
                  color: "gray",
                  marginBottom: "0.5rem",
                }}
              >
                Country*
              </label>
              <CustomDropdown
                id="country"
                options={countries}
                selectedValue={selectedCountry}
                onChange={handleCountryChange}
                inputValue={countryInputValue}
                setInputValue={setCountryInputValue}
                disabled={loading}
                placeholder="Ex: United States"
              />

              <label
                htmlFor="city"
                style={{
                  display: "block",
                  fontSize: "0.875rem",
                  color: "gray",
                  marginBottom: "0.5rem",
                }}
              >
                City*
              </label>
              <CustomDropdown
                id="city"
                options={cities}
                selectedValue={selectedCity}
                onChange={handleCityChange}
                inputValue={cityInputValue}
                setInputValue={setCityInputValue}
                disabled={!selectedCountry || loading}
                placeholder="Ex: San Francisco"
              />

              {showAddLink && (
                <button
                  type="button"
                  onClick={addLink}
                  disabled={loading}
                  className={classes.linkButton}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize: "1rem",
                      color: "gray",
                    }}
                  >
                    add
                  </span>
                  Add link
                </button>
              )}

              {links.map((link, index) => (
                <div key={index} style={{ marginBottom: "1rem" }}>
                  <label
                    htmlFor={`linkText-${index}`}
                    style={{
                      display: "block",
                      fontSize: "0.875rem",
                      color: "gray",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Link text
                  </label>
                  <input
                    id={`linkText-${index}`}
                    type="text"
                    placeholder="Ex: LinkedIn"
                    className={classes.userInput}
                    value={link.label}
                    onChange={(e) =>
                      handleLinkChange(index, "label", e.target.value)
                    }
                    disabled={loading}
                    style={{ marginBottom: "1rem" }}
                  />

                  <div>
                    <label
                      htmlFor={`url-${index}`}
                      style={{
                        display: "block",
                        fontSize: "0.875rem",
                        color: "gray",
                        marginBottom: "0.5rem",
                      }}
                    >
                      URL
                    </label>
                    <input
                      id={`url-${index}`}
                      type="url"
                      placeholder="Ex: https://linkedin.com/in/username"
                      className={`${classes.linkInput} ${
                        linkErrors[index] ? classes.inputError : ""
                      }`}
                      value={link.url}
                      onChange={(e) =>
                        handleLinkChange(index, "url", e.target.value)
                      }
                      disabled={loading}
                      style={{ width: "100%" }}
                    />
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div
                        className={classes.remove}
                        onClick={() => removeLink(index)}
                        style={{
                          cursor: "pointer",
                          marginTop: "0.5rem",
                        }}
                      >
                        Remove
                      </div>
                    </div>
                  </div>

                  {linkErrors[index] && (
                    <div className={classes.errorText}>{linkErrors[index]}</div>
                  )}
                </div>
              ))}

              {links.length > 0 && links.length < 3 && (
                <button
                  type="button"
                  onClick={addLink}
                  disabled={loading}
                  className={classes.linkButton}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize: "1rem",
                      color: "gray",
                    }}
                  >
                    add
                  </span>
                  Add another link
                </button>
              )}

              <label
                htmlFor="intro"
                style={{
                  display: "block",
                  fontSize: "0.875rem",
                  color: "gray",
                  marginBottom: "0.5rem",
                }}
              >
                About me
              </label>
              <textarea
                ref={textAreaRef}
                id="intro"
                value={intro}
                onChange={handleIntroChange}
                className={classes.introTextArea}
                disabled={loading}
                placeholder={
                  "tell us about who you are, and what you do. the more specific you are, the more likely you are to be found.\n\n- your role and industry (e.g., 'ai engineer building llm applications').\n\n- technologies you work with (e.g., 'react, python, tensorflow').\n\n- current focus (e.g., 'developing robotics for sustainable agriculture').\n\n- specific challenges you're tackling.\n\n- resources or connections you're seeking.\n\n- areas where you can offer help or mentorship."
                }
              />

              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={!isChanged || !isFormValid || loading}
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Save profile"
                )}
              </button>
            </form>

            {errorPopup && (
              <ErrorPopup
                message={errorPopup.message}
                onClose={() => setErrorPopup(null)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;
