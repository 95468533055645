import React, { useState, useEffect, useRef } from "react";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./IntroModal.module.css";
import { convertUrlsToLinks } from "../../utils/textUtils";

const IntroModal = ({
  onClose,
  currentUser,
  onProfileUpdate,
  isStartup = false,
  isCommunity = false,
  isEvent = false, // Add new prop
  startupData,
  startupOwnerId,
  communityData,
  communityOwnerId,
  eventData,
  eventOwnerId,
}) => {
  const [intro, setIntro] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialData, setInitialData] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const formRef = useRef(null);
  const [websiteLink, setWebsiteLink] = useState("");
  const textAreaRef = useRef(null);
  const modalContentRef = useRef(null);
  const [loadingDots, setLoadingDots] = useState(1);

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setLoadingDots((prev) => (prev % 3) + 1);
      }, 500);
    }
    return () => clearInterval(interval);
  }, [loading]);

  // 3. Create a function to render the loading symbols
  const renderLoadingSymbols = () => {
    if (loadingDots === 1) return ">";
    if (loadingDots === 2) return "> >";
    return "> > >";
  };

  useEffect(() => {
    const fetchData = async () => {
      setInitialLoading(true);
      try {
        if (isEvent) {
          setInitialData(eventData);
          setIntro(eventData.intro || "");
        } else if (isCommunity) {
          setInitialData(communityData);
          setIntro(communityData.intro || "");
        } else if (isStartup) {
          setInitialData(startupData);
          setIntro(startupData.intro || "");
        } else {
          const userDocRef = doc(firestore, "users", currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setInitialData(userData);
            setIntro(userData.intro || "");
          }
        }
      } catch (error) {
      } finally {
        setInitialLoading(false);
      }
    };

    fetchData();
  }, [
    currentUser?.uid,
    isStartup,
    isCommunity,
    isEvent,
    startupData,
    communityData,
    eventData,
  ]);

  useEffect(() => {
    const dataChanged = intro !== initialData.intro;
    setIsChanged(dataChanged);
  }, [intro, initialData]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleIntroChange = (e) => {
    if (e.target.value.length <= 2500) {
      setIntro(e.target.value);
    }
  };
  // useEffect(() => {
  //   const adjustHeight = () => {
  //     if (textAreaRef.current && modalContentRef.current) {
  //       // Reset height to auto to get the correct scrollHeight
  //       textAreaRef.current.style.height = "auto";

  //       // Set the height to the scrollHeight
  //       const newHeight = textAreaRef.current.scrollHeight;
  //       textAreaRef.current.style.height = `${newHeight}px`;

  //       // Adjust modalContent height
  //       const headerHeight = document.querySelector(
  //         `.${classes.cardHeader}`
  //       ).offsetHeight;
  //       const footerHeight = document.querySelector(
  //         `.${classes.cardFooter}`
  //       ).offsetHeight;
  //       const maxHeight = window.innerHeight - 4 * 16; // 4rem in pixels
  //       const contentHeight = Math.min(
  //         newHeight + headerHeight + footerHeight,
  //         maxHeight
  //       );

  //       modalContentRef.current.style.height = `${contentHeight}px`;
  //     }
  //   };

  //   adjustHeight();
  //   window.addEventListener("resize", adjustHeight);

  //   return () => window.removeEventListener("resize", adjustHeight);
  // }, [intro]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isChanged) return;

    setLoading(true);
    setError("");

    try {
      const trimmedIntro = trimTrailingWhitespace(intro);
      const introHtml = convertUrlsToLinks(trimmedIntro);

      if (isEvent) {
        const eventDocRef = doc(
          firestore,
          `users/${eventOwnerId}/events/${eventData.id}`
        );
        const updatedData = {
          intro: trimmedIntro,
          introHtml,
        };
        await updateDoc(eventDocRef, updatedData);
        onProfileUpdate(updatedData);
      } else if (isCommunity) {
        const communityDocRef = doc(
          firestore,
          `users/${communityOwnerId}/communities/${communityData.id}`
        );
        const updatedData = {
          intro: trimmedIntro,
          introHtml,
        };
        await updateDoc(communityDocRef, updatedData);
        onProfileUpdate(updatedData);
      } else if (isStartup) {
        const startupDocRef = doc(
          firestore,
          `users/${startupOwnerId}/startups/${startupData.id}`
        );
        const updatedData = {
          intro: trimmedIntro,
          introHtml,
          id: startupData.id, // Include the ID to make identification easier
        };
        await updateDoc(startupDocRef, updatedData);
        onProfileUpdate(updatedData);
      } else {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const currentData = userDoc.data();
          const updatedProfile = {
            ...currentData,
            intro: trimmedIntro,
            introHtml,
          };
          await updateDoc(userDocRef, updatedProfile);
          onProfileUpdate(updatedProfile);
        }
      }
      setLoading(false);
    } catch (error) {
      setError("Failed to update. Please try again.");
      setLoading(false);
      throw error; // Re-throw the error so our Promise knows it failed
    }
  };

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  const handleSaveButtonClick = async () => {
    if (formRef.current) {
      try {
        // Trigger the form submission and wait for it to complete
        await new Promise((resolve, reject) => {
          const event = new Event("submit", {
            bubbles: true,
            cancelable: true,
          });
          formRef.current.addEventListener(
            "submit",
            async (e) => {
              e.preventDefault();
              try {
                await handleSubmit(e);
                resolve();
              } catch (error) {
                reject(error);
              }
            },
            { once: true }
          );
          formRef.current.dispatchEvent(event);
        });

        // Only close if there are no errors
        if (!error) {
          onClose(true);
        }
      } catch (err) {}
    }
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose(false); // Pass false for regular close
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e, isSave = false) => {
    e.preventDefault();
    onClose(isSave);
  };

  const trimTrailingWhitespace = (text) => {
    return text.replace(/\s+$/g, "");
  };

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        ref={modalContentRef}
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            a
            <div
              className={classes.closeIcon}
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m18 10-1.99994 1.9999 1.99994 2M13 5v14m7 0H4c-.5523 0-1-.4477-1-1V6c0-.55228.4477-1 1-1h16c.5523 0 1 .44772 1 1v12c0 .5523-.4477 1-1 1Z"
                />
              </svg>
            </div>
          </div>
        </div>

        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <textarea
            ref={textAreaRef}
            id="intro"
            value={intro}
            onChange={handleIntroChange}
            className={classes.textArea}
            style={{ opacity: loading ? 0.5 : 1 }} // Add this line
            disabled={loading} // Add this line
            placeholder={
              isEvent
                ? "Tell people about your event. What can attendees expect? Share the details that will get people excited to attend."
                : isCommunity
                ? "Tell everyone about your community. What brings people here? What can they expect? Share your community's story."
                : isStartup
                ? "tell us about your page (e.g. startup, accelerator, incubator, community, event, program, etc.), and what it does.\n\n- what makes this unique?\n\n- who's it for?\n\n- what stage/phase?\n\n- key technologies or approaches.\n\n- what you're looking for."
                : "tell us about who you are, and what you do. the more specific you are, the more likely you are to be found.\n\n- your role and industry (e.g., 'ai engineer building llm applications').\n\n- technologies you work with (e.g., 'react, python, tensorflow').\n\n- current focus (e.g., 'developing robotics for sustainable agriculture').\n\n- specific challenges you're tackling.\n\n- resources or connections you're seeking.\n\n- areas where you can offer help or mentorship."
            }
          />
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          {/* <div className={classes.charCount}>{intro.length}/2500</div> */}
          <button
            type="button"
            className={`${classes.button} ${loading ? classes.loading : ""}`}
            disabled={!isChanged || loading || initialLoading}
            onClick={handleSaveButtonClick}
          >
            {loading ? renderLoadingSymbols() : "> save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntroModal;
