import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import googleIcon from "../../../assets/images/googleIcon.svg";
import { getFunctions, httpsCallable } from "firebase/functions";
import { userAnalytics } from "../../../analytics";
import styles from "./LogInSidebar.module.css"; // Using our merged CSS module
import logo from "../../../assets/images/foundry.png";

const functions = getFunctions(undefined, "europe-west1");

const LogInSidebar = ({ isOpen, onToggle, darkMode = false }) => {
  // Added darkMode prop
  const navigate = useNavigate();
  const { signIn, signInWithGoogle, currentUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);
  const [loadingDots, setLoadingDots] = useState(1);

  useEffect(() => {
    if (currentUser) {
      const checkFirestoreFields = async () => {
        try {
          const userDocRef = doc(firestore, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          const userData = userDocSnap.data();

          if (userData && userData.email !== currentUser.email) {
            await updateDoc(userDocRef, {
              email: currentUser.email,
            });

            if (userData.firstName && userData.lastName) {
              try {
                const addContactToSendGrid = httpsCallable(
                  functions,
                  "addContactToSendGrid"
                );
                await addContactToSendGrid({
                  email: currentUser.email,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                });
              } catch (sendGridError) {}
            }
          }

          if (
            userData &&
            userData.firstName &&
            userData.lastName &&
            userData.country &&
            userData.city
          ) {
            navigate("/");
          } else {
            navigate("/onboarding");
          }
        } catch (error) {}
      };

      checkFirestoreFields();
    }
  }, [currentUser, navigate]);

  // Effect for loading animation
  useEffect(() => {
    let interval;
    if (loading || googleLoading) {
      interval = setInterval(() => {
        setLoadingDots((prev) => (prev % 3) + 1);
      }, 500);
    }
    return () => clearInterval(interval);
  }, [loading, googleLoading]);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.toLowerCase();
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(emailValue));
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(passwordValue.length >= 6);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid && isPasswordValid) {
      setLoading(true);
      try {
        const userCredential = await signIn(email, password);
        userAnalytics.trackUserActivity();
        const user = userCredential.user;

        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();

        // Always try to add to SendGrid if profile is complete
        if (userData?.firstName && userData?.lastName) {
          try {
            const addContactToSendGrid = httpsCallable(
              functions,
              "addContactToSendGrid",
              {
                timeout: 60000,
              }
            );

            await addContactToSendGrid({
              email: user.email,
              firstName: userData.firstName,
              lastName: userData.lastName,
            });
          } catch (sendGridError) {}
        } else {
        }

        if (
          !userData?.firstName ||
          !userData?.lastName ||
          !userData?.country ||
          !userData?.city
        ) {
          navigate("/onboarding");
        } else {
          navigate("/");
        }
      } catch (error) {
        setErrorPopup({
          message: "Incorrect login details.",
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      setErrorPopup({
        message: "Incorrect login details.",
        isError: true,
      });
    }
  };

  const handleGoogleSignIn = async () => {
    setGoogleLoading(true);
    try {
      const userCredential = await signInWithGoogle();
      userAnalytics.trackUserActivity();
      const user = userCredential.user;

      const userDocRef = doc(firestore, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();

      if (userData) {
        // Always try to add to SendGrid if profile is complete
        if (userData.firstName && userData.lastName) {
          try {
            const addContactToSendGrid = httpsCallable(
              functions,
              "addContactToSendGrid",
              {
                timeout: 60000,
              }
            );

            await addContactToSendGrid({
              email: user.email,
              firstName: userData.firstName,
              lastName: userData.lastName,
            });
          } catch (sendGridError) {}
        } else {
        }

        if (
          userData.firstName &&
          userData.lastName &&
          userData.country &&
          userData.city
        ) {
          navigate("/");
        } else {
          navigate("/onboarding");
        }
      } else {
        await setDoc(doc(firestore, "users", user.uid), {
          email: user.email,
          firstName: "",
          lastName: "",
          country: "",
          city: "",
        });

        navigate("/onboarding");
      }
    } catch (error) {
    } finally {
      setGoogleLoading(false);
    }
  };

  const isFormValid = isEmailValid && isPasswordValid;

  // Function to render the loading animation
  const renderLoadingSymbols = () => {
    if (loadingDots === 1) return ">";
    if (loadingDots === 2) return "> >";
    return "> > >";
  };

  return (
    <>
      <aside
        className={`${styles.sidebar} ${isOpen ? styles.open : ""} ${
          darkMode ? styles.darkMode : ""
        }`}
      >
        {/* Top bar with logo - matching LandingSidebar */}
        <div className={`${styles.topBar} ${darkMode ? styles.darkMode : ""}`}>
          {/* Logo on the left */}
          <a
            href="/"
            className={styles.logoButton}
            type="button"
            aria-label="Logo"
          >
            <img src={logo} alt="foundry" className={styles.logo} />
          </a>

          {/* Right buttons for toggle sidebar */}
          <div className={styles.topBarRightButtons}>
            <button
              className={styles.iconButton}
              type="button"
              aria-label="Toggle Sidebar"
              onClick={onToggle}
            >
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                keyboard_double_arrow_left
              </span>
            </button>
          </div>
        </div>

        {/* Add spacer to push content to bottom */}
        <div className={styles.spacer}></div>

        {/* Content wrapper for bottom content */}
        <div className={styles.contentWrapper}>
          <h1 className={styles.title}>hey, welcome back.</h1>
          {/* You can either keep or remove this subtitle */}
          {/* <p className={styles.subtitle}>log in to continue</p> */}

          <div className={styles.buttons}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="email"
                className={styles.input}
                value={email}
                onChange={handleEmailChange}
                disabled={loading}
              />

              <input
                id="password"
                name="password"
                type="password"
                placeholder="password"
                className={styles.input}
                value={password}
                onChange={handlePasswordChange}
                autoComplete="new-password"
                disabled={loading}
              />
              <Link
                to="/reset-password"
                className={styles.smallPrint}
                style={{
                  marginLeft: "0.5rem",
                  marginBottom: "0.5rem",
                  display: "inline-block",
                }}
              >
                forgot password?
              </Link>
              <button
                type="submit"
                className={`${styles.link} ${
                  loading || !isFormValid ? styles.disabled : ""
                }`}
                disabled={!isFormValid || loading}
                style={{
                  opacity: !isFormValid || loading ? 0.5 : 1,
                  justifyContent: "flex-start",
                }}
              >
                <div className={styles.linkIcon}>
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize: "1.25rem",
                      transform: "rotate(45deg)",
                    }}
                  >
                    arrow_upward
                  </span>
                </div>
                <span className={styles.linkText} style={{ textAlign: "left" }}>
                  {loading ? renderLoadingSymbols() : "continue w/ email"}
                </span>
              </button>

              <button
                type="button"
                className={styles.loginButton}
                onClick={handleGoogleSignIn}
                disabled={googleLoading}
              >
                {googleLoading ? (
                  renderLoadingSymbols()
                ) : (
                  <>
                    <img
                      src={googleIcon}
                      alt=""
                      className={styles.googleIcon}
                    />
                    <span>continue w/ google</span>
                  </>
                )}
              </button>
            </form>
          </div>
        </div>

        <div className={`${styles.footer} ${darkMode ? styles.darkMode : ""}`}>
          <div className={styles.links}>
            <a href="/t&c's" className={styles.smallPrint}>
              terms
            </a>
            <a href="/privacy-policy" className={styles.smallPrint}>
              privacy
            </a>
            <div className={styles.tag}>beta/v 1.0</div>
          </div>

          {/* <p className={styles.copyright}>© 2025 foundry labs ltd.</p> */}
        </div>
      </aside>
    </>
  );
};

export default LogInSidebar;
