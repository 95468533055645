import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userAnalytics } from "../../../analytics";
import { useAuth } from "../../../contexts/AuthContext";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import googleIcon from "../../../assets/images/googleIcon.svg";
import classes from "./LogIn.module.css";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions(undefined, "europe-west1");

function LogIn() {
  const { signIn, signInWithGoogle, currentUser } = useAuth();
  const [googleLoading, setGoogleLoading] = useState(false);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const checkFirestoreFields = async () => {
        try {
          const userDocRef = doc(firestore, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          const userData = userDocSnap.data();

          if (userData && userData.email !== currentUser.email) {
            await updateDoc(userDocRef, {
              email: currentUser.email,
            });

            if (userData.firstName && userData.lastName) {
              try {
                const addContactToSendGrid = httpsCallable(
                  functions,
                  "addContactToSendGrid"
                );
                await addContactToSendGrid({
                  email: currentUser.email,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                });
              } catch (sendGridError) {
                // Error handling is silent
              }
            }
          }

          if (
            userData &&
            userData.firstName &&
            userData.lastName &&
            userData.country &&
            userData.city &&
            userData.countryISO2
          ) {
            navigate("/home");
          } else {
            navigate("/onboarding");
          }
        } catch (error) {
          // Error handling is silent
        }
      };

      checkFirestoreFields();
    }
  }, [currentUser, navigate]);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.toLowerCase();
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(emailValue));
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(passwordValue.length >= 6);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid && isPasswordValid) {
      setLoading(true);
      setErrorPopup(null);
      try {
        const userCredential = await signIn(email, password);
        userAnalytics.trackUserActivity();
        const user = userCredential.user;

        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();

        if (userData?.firstName && userData?.lastName) {
          try {
            const addContactToSendGrid = httpsCallable(
              functions,
              "addContactToSendGrid",
              {
                timeout: 60000,
              }
            );

            await addContactToSendGrid({
              email: user.email,
              firstName: userData.firstName,
              lastName: userData.lastName,
            });
          } catch (sendGridError) {
            // Error handling is silent
          }
        }

        if (
          !userData?.firstName ||
          !userData?.lastName ||
          !userData?.country ||
          !userData?.city
        ) {
          navigate("/onboarding");
        } else {
          navigate("/home");
        }
      } catch (error) {
        if (error.code === "auth/invalid-credential") {
          setErrorPopup({
            message: "Invalid email or password.",
            isError: true,
          });
        } else if (error.code === "auth/invalid-email") {
          setErrorPopup({
            message: "Invalid email.",
            isError: true,
          });
        } else {
          setErrorPopup({
            message: error.message,
            isError: true,
          });
        }
      } finally {
        setLoading(false);
      }
    } else {
      setErrorPopup({
        message: "Invalid email/password.",
        isError: true,
      });
    }
  };

  const handleGoogleSignIn = async () => {
    setGoogleLoading(true);
    try {
      const userCredential = await signInWithGoogle();
      userAnalytics.trackUserActivity();
      const user = userCredential.user;

      const userDocRef = doc(firestore, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();

      if (userData) {
        if (userData.firstName && userData.lastName) {
          try {
            const addContactToSendGrid = httpsCallable(
              functions,
              "addContactToSendGrid",
              {
                timeout: 60000,
              }
            );

            await addContactToSendGrid({
              email: user.email,
              firstName: userData.firstName,
              lastName: userData.lastName,
            });
          } catch (sendGridError) {
            // Error handling is silent
          }
        }

        if (
          userData.firstName &&
          userData.lastName &&
          userData.country &&
          userData.city
        ) {
          navigate("/home");
        } else {
          navigate("/onboarding");
        }
      } else {
        await setDoc(doc(firestore, "users", user.uid), {
          email: user.email,
          firstName: "",
          lastName: "",
          country: "",
          countryISO2: "",
          city: "",
          bio: "",
          intro: "",
          link: "",
          linkText: "",
          createdAt: new Date().toISOString(),
        });

        navigate("/onboarding");
      }
    } catch (error) {
      setErrorPopup({
        message: "Failed to sign in with Google. Please try again.",
        isError: true,
      });
    } finally {
      setGoogleLoading(false);
    }
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.page}>
      <div className={classes.closeButton} onClick={() => navigate("/")}>
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "1.25rem", color: "gray" }}
        >
          close
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>
                Log in below to unlock the full potential of Foundry
              </p>
            </div>

            <form className={classes.interactions} onSubmit={handleSubmit}>
              <label htmlFor="email" className={classes.label}>
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Ex: name@example.com"
                className={classes.email}
                value={email}
                onChange={handleEmailChange}
                disabled={loading}
              />
              <label htmlFor="password" className={classes.label}>
                Password (6+ characters)
              </label>
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Ex: ******"
                className={classes.email}
                value={password}
                onChange={handlePasswordChange}
                autoComplete="current-password"
                disabled={loading}
              />
              <div className={classes.forgotPasswordLink}>
                <a href="/forgot-password" className={classes.link}>
                  Forgot password?
                </a>
              </div>
              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={!isEmailValid || !isPasswordValid || loading}
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Continue with email"
                )}
              </button>
            </form>

            <div className={classes.divider}>
              <span>
                Don't have an account?{" "}
                <a href="/signup" className={classes.link}>
                  Sign Up
                </a>
              </span>
            </div>

            <div className={classes.altSection}>
              <button
                type="button"
                className={classes.altEmail}
                onClick={handleGoogleSignIn}
                disabled={googleLoading}
              >
                <img src={googleIcon} alt="" className={classes.googleIcon} />
                Continue with Google
              </button>
            </div>

            <div className={classes.termsText}>
              By continuing, you acknowledge Foundry's{" "}
              <a href="/terms" className={classes.link}>
                Terms
              </a>{" "}
              and{" "}
              <a href="/privacy" className={classes.link}>
                Privacy Policy
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogIn;
