import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userAnalytics } from "../../../analytics";
import { useAuth } from "../../../contexts/AuthContext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { sendEmailVerification } from "firebase/auth";
import { firestore, storage } from "../../../firebase";
import googleIcon from "../../../assets/images/googleIcon.svg";
import profileImage from "../../../assets/icons/profileImage.jpg";
import defaultStartupImage from "../../../assets/images/startupImage.jpg";
import classes from "./SignUp.module.css";
import { generateUrlSlug } from "../../../utils/urlSlugUtils";

function SignUp() {
  const { signUp, signInWithGoogle, currentUser } = useAuth();
  const [googleLoading, setGoogleLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [verificationChecking, setVerificationChecking] = useState(false);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);

  useEffect(() => {
    let intervalId;

    if (currentUser && !currentUser.emailVerified && verificationSent) {
      setVerificationChecking(true);
      intervalId = setInterval(async () => {
        try {
          await currentUser.reload();
          if (currentUser.emailVerified) {
            setVerificationChecking(false);
            clearInterval(intervalId);
            checkAndNavigate();
          }
        } catch (error) {
          // Error handling is silent
        }
      }, 3000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [currentUser, verificationSent]);

  useEffect(() => {
    if (currentUser?.emailVerified) {
      const checkFirestoreFields = async () => {
        try {
          const userDocRef = doc(firestore, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          const userData = userDocSnap.data();

          if (
            userData &&
            userData.firstName &&
            userData.lastName &&
            userData.country &&
            userData.city &&
            userData.countryISO2
          ) {
            navigate("/home");
          } else {
            navigate("/onboarding");
          }
        } catch (error) {
          // Error handling is silent
        }
      };

      checkFirestoreFields();
    }
  }, [currentUser, navigate]);

  const checkAndNavigate = async () => {
    if (currentUser) {
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();

        if (
          userData &&
          userData.firstName &&
          userData.lastName &&
          userData.country &&
          userData.city &&
          userData.countryISO2
        ) {
          navigate("/home");
        } else {
          navigate("/onboarding");
        }
      } catch (error) {
        // Error handling is silent
      }
    }
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.toLowerCase();
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(emailValue));
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(passwordValue.length >= 6);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid && isPasswordValid) {
      setLoading(true);
      setErrorPopup(null);
      try {
        const userCredential = await signUp(email, password);
        userAnalytics.trackUserActivity();
        const user = userCredential.user;

        await sendEmailVerification(user);
        setVerificationSent(true);

        // Create storage refs
        const profileRef = ref(
          storage,
          `profileImages/${user.uid}/default.jpg`
        );
        const startupRef = ref(
          storage,
          `startupImages/${user.uid}/default/default/default.jpg`
        );

        // Convert URL path to Blob
        const profileResponse = await fetch(profileImage);
        const profileBlob = await profileResponse.blob();

        // Convert base64 to Blob for startup image
        const startupByteString = atob(defaultStartupImage.split(",")[1]);
        const startupArray = new Uint8Array(startupByteString.length);
        for (let i = 0; i < startupByteString.length; i++) {
          startupArray[i] = startupByteString.charCodeAt(i);
        }
        const startupBlob = new Blob([startupArray], { type: "image/jpeg" });

        // Upload with metadata
        await uploadBytes(profileRef, profileBlob, {
          contentType: "image/jpeg",
        });

        await uploadBytes(startupRef, startupBlob, {
          contentType: "image/jpeg",
        });

        // Get URLs
        const profileUrl = await getDownloadURL(profileRef);
        const startupUrl = await getDownloadURL(startupRef);

        await setDoc(doc(firestore, "users", user.uid), {
          email: email,
          firstName: "",
          lastName: "",
          country: "",
          countryISO2: "",
          city: "",
          bio: "",
          intro: "",
          link: "",
          linkText: "",
          profileImage: profileUrl,
          urlSlug: await generateUrlSlug(email.split("@")[0], "user"),
          defaultStartupImage: startupUrl,
          createdAt: new Date().toISOString(),
        });
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          setErrorPopup({
            message: "This email is already taken.",
            isError: true,
          });
        } else if (error.code === "auth/invalid-email") {
          setErrorPopup({
            message: "Invalid email.",
            isError: true,
          });
        } else {
          setErrorPopup({
            message: error.message,
            isError: true,
          });
        }
      } finally {
        setLoading(false);
      }
    } else {
      setErrorPopup({
        message: "Invalid email/password.",
        isError: true,
      });
    }
  };

  const handleGoogleSignIn = async () => {
    setGoogleLoading(true);
    try {
      const userCredential = await signInWithGoogle();
      userAnalytics.trackUserActivity();
      const user = userCredential.user;

      const userDocRef = doc(firestore, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();

      if (userData) {
        if (
          userData.firstName &&
          userData.lastName &&
          userData.country &&
          userData.city
        ) {
          navigate("/home");
        } else {
          navigate("/onboarding");
        }
      } else {
        // Create storage refs
        const profileRef = ref(
          storage,
          `profileImages/${user.uid}/default.jpg`
        );
        const startupRef = ref(
          storage,
          `startupImages/${user.uid}/default/default/default.jpg`
        );

        // Convert URL path to Blob
        const profileResponse = await fetch(profileImage);
        const profileBlob = await profileResponse.blob();

        // Convert base64 to Blob
        const startupByteString = atob(defaultStartupImage.split(",")[1]);
        const startupArray = new Uint8Array(startupByteString.length);
        for (let i = 0; i < startupByteString.length; i++) {
          startupArray[i] = startupByteString.charCodeAt(i);
        }
        const startupBlob = new Blob([startupArray], { type: "image/jpeg" });

        // Upload with metadata
        await uploadBytes(profileRef, profileBlob, {
          contentType: "image/jpeg",
        });

        await uploadBytes(startupRef, startupBlob, {
          contentType: "image/jpeg",
        });

        // Get URLs
        const profileUrl = await getDownloadURL(profileRef);
        const startupUrl = await getDownloadURL(startupRef);

        await setDoc(userDocRef, {
          email: user.email,
          firstName: "",
          lastName: "",
          country: "",
          countryISO2: "",
          city: "",
          bio: "",
          intro: "",
          link: "",
          linkText: "",
          profileImage: profileUrl,
          urlSlug: await generateUrlSlug(user.email.split("@")[0], "user"),
          defaultStartupImage: startupUrl,
          createdAt: new Date().toISOString(),
        });

        navigate("/onboarding");
      }
    } catch (error) {
      setErrorPopup({
        message: "Failed to sign in with Google. Please try again.",
        isError: true,
      });
    } finally {
      setGoogleLoading(false);
    }
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.page}>
      <div className={classes.closeButton} onClick={() => navigate("/")}>
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "1.25rem", color: "gray" }}
        >
          close
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>
                {verificationSent
                  ? "Please verify your email"
                  : "Sign up below to unlock the full potential of Foundry"}
              </p>
              {verificationSent && (
                <p className={classes.secondaryAbout}>
                  We've sent a verification email to <span>{email}</span>.
                  Please open it, click the verification link, and then return
                  to proceed.
                  {verificationChecking && (
                    <span className={classes.checkingText}>
                      <span
                        className={`material-symbols-outlined ${classes.checkIcon}`}
                      >
                        progress_activity
                      </span>
                      Awaiting verification...
                    </span>
                  )}
                </p>
              )}
            </div>

            {!verificationSent && (
              <>
                <form className={classes.interactions} onSubmit={handleSubmit}>
                  <label htmlFor="email" className={classes.label}>
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Ex: name@example.com"
                    className={classes.email}
                    value={email}
                    onChange={handleEmailChange}
                    disabled={loading}
                  />
                  <label htmlFor="password" className={classes.label}>
                    Password (6+ characters)
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Ex: ******"
                    className={classes.email}
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="new-password"
                    disabled={loading}
                  />
                  <button
                    type="submit"
                    className={`${classes.button} ${
                      loading ? classes.loading : ""
                    }`}
                    disabled={!isEmailValid || !isPasswordValid || loading}
                  >
                    {loading ? (
                      <span
                        className={`material-symbols-outlined ${classes.loadingIcon}`}
                      >
                        progress_activity
                      </span>
                    ) : (
                      "Continue with email"
                    )}
                  </button>
                </form>

                <div className={classes.divider}>
                  <span>
                    Already have an account?{" "}
                    <a href="/login" className={classes.link}>
                      Log In
                    </a>
                  </span>
                </div>

                <div className={classes.altSection}>
                  <button
                    type="button"
                    className={classes.altEmail}
                    onClick={handleGoogleSignIn}
                    disabled={googleLoading}
                  >
                    <img
                      src={googleIcon}
                      alt=""
                      className={classes.googleIcon}
                    />
                    Continue with Google
                  </button>
                </div>

                <div className={classes.termsText}>
                  By continuing, you acknowledge Foundry's{" "}
                  <a href="/terms" className={classes.link}>
                    Terms
                  </a>{" "}
                  and{" "}
                  <a href="/privacy" className={classes.link}>
                    Privacy Policy
                  </a>
                  .
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
