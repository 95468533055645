import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, firestore } from "../../firebase";
import { useChat } from "../../contexts/ChatContext";
import logo from "../../assets/images/foundry.png";
import styles from "./Sidebar.module.css";

const Sidebar = ({
  isOpen,
  onToggle,
  onChatSelect,
  currentChatId,
  isInDiscoverMode = false,
  darkMode = false,
}) => {
  const [chats, setChats] = useState([]);
  const [inboxMode, setInboxMode] = useState(false);
  const [analyticsMode, setAnalyticsMode] = useState(false); // New state for analytics mode
  const [userStats, setUserStats] = useState({
    // New state for user statistics
    searchAppearances: 0,
    viewCount: 0,
    messageCount: 0,
    lastUpdated: null,
    topKeywords: [],
  });
  const [inboxMessages, setInboxMessages] = useState([]);
  const [userProfiles, setUserProfiles] = useState({});
  const { unreadChatsCount, isChatUnread, markChatAsRead } = useChat();
  // Add loading state variables
  const [isChatsLoading, setIsChatsLoading] = useState(true);
  const [isInboxLoading, setIsInboxLoading] = useState(false);
  const [isAnalyticsLoading, setIsAnalyticsLoading] = useState(false); // New loading state for analytics
  const [loadingDots, setLoadingDots] = useState(1);
  const [discoverMode, setDiscoverMode] = useState(false);
  // Add state for delete confirmation
  const [chatConfirmingDelete, setChatConfirmingDelete] = useState(null);

  const toggleDiscoverMode = () => {
    setDiscoverMode(!discoverMode);
    if (!discoverMode) {
      // Switching to discover mode - inform parent component
      onChatSelect("discover");
      // Close inbox and analytics if open
      setInboxMode(false);
      setAnalyticsMode(false);
    } else if (inboxMode) {
      // If switching back from discover and inbox was previously open
      setInboxMode(true);
    } else {
      // Reset to normal chat mode
      onChatSelect(null);
    }
  };

  // New function to toggle analytics mode
  const toggleAnalyticsMode = () => {
    const newAnalyticsMode = !analyticsMode;
    setAnalyticsMode(newAnalyticsMode);

    if (newAnalyticsMode) {
      // Switching to analytics mode
      setDiscoverMode(false); // Exit discover mode
      setInboxMode(false); // Exit inbox mode
      onChatSelect("analytics"); // Tell parent we're in analytics mode
      // Fetch analytics data
      fetchUserAnalytics();
    } else {
      // Exiting analytics mode - go back to normal chat mode
      onChatSelect(null);
    }
  };

  // Helper function to extract meaningful keywords from a query
  const extractKeywords = (query) => {
    // Extended list of common words to filter out
    const commonWords = [
      "a",
      "an",
      "the",
      "in",
      "on",
      "at",
      "for",
      "with",
      "and",
      "or",
      "but",
      "is",
      "are",
      "who",
      "what",
      "where",
      "when",
      "how",
      "me",
      "my",
      "find",
      "looking",
      "search",
      "people",
      "person",
      "help",
      "can",
      "would",
      "like",
      "about",
      "know",
      "get",
      "i",
      "to",
      "of",
      "from",
      "want",
      "need",
      "found",
      "finding",
      "anyone",
      "someone",
      "everybody",
      "everyone",
      "we",
      "us",
      "you",
      "your",
      "their",
      "there",
      "where",
      "here",
      "have",
      "has",
      "had",
      "been",
      "being",
      "was",
      "were",
      "will",
      "would",
      "could",
      "should",
      "may",
      "might",
      "must",
      "that",
      "this",
      "these",
      "those",
      "just",
      "very",
      "really",
      "so",
      "too",
      "such",
      "more",
      "most",
      "many",
      "much",
      "some",
      "any",
      "all",
      "few",
      "little",
      "other",
      "another",
      "same",
      "different",
      "if",
      "then",
      "than",
      "now",
      "also",
      "though",
      "although",
      "however",
      "because",
      "since",
      "while",
      "during",
      "before",
      "after",
      "do",
      "does",
      "did",
      "doing",
      "done",
      "make",
      "making",
      "made",
      "take",
      "taking",
      "took",
      "taken",
    ];

    // Split into words, convert to lowercase, remove punctuation
    return query
      .toLowerCase()
      .replace(/[^\w\s]/g, "")
      .split(/\s+/)
      .filter((word) => word.length > 2 && !commonWords.includes(word));
  };

  // Function to fetch user analytics based on existing chat data
  const fetchUserAnalytics = async () => {
    const user = auth.currentUser;
    if (!user) return;

    setIsAnalyticsLoading(true);

    try {
      // Get all users
      const usersRef = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersRef);

      let searchAppearances = 0;
      let lastUpdated = null;
      const keywordCounts = {};

      // Create a batch of promises to process all users in parallel
      const userPromises = usersSnapshot.docs.map(async (userDoc) => {
        // Skip current user - we're looking for appearances in other users' chats
        if (userDoc.id === user.uid) return { appearances: 0, keywords: {} };

        // Get this user's chats
        const chatsRef = collection(firestore, `users/${userDoc.id}/chats`);
        const chatsSnapshot = await getDocs(chatsRef);

        // Process each chat
        let userAppearances = 0;
        let latestTimestamp = null;
        const userKeywords = {};

        chatsSnapshot.docs.forEach((chatDoc) => {
          const chatData = chatDoc.data();

          // Check if messages exist and is an array
          if (chatData.messages && Array.isArray(chatData.messages)) {
            // Track the previous user query
            let previousQuery = null;

            chatData.messages.forEach((message) => {
              // If it's a user message, store the query
              if (message.role === "user" && message.content) {
                previousQuery = message.content;
              }
              // If it's an assistant message with matches and we have a previous query
              else if (
                message.role === "assistant" &&
                previousQuery &&
                message.matches &&
                Array.isArray(message.matches)
              ) {
                const isInMatches = message.matches.some(
                  (match) => match.id === user.uid
                );

                if (isInMatches) {
                  userAppearances++;

                  // Extract keywords from the query that led to this match
                  const keywords = extractKeywords(previousQuery);

                  // Count these keywords
                  keywords.forEach((keyword) => {
                    userKeywords[keyword] = (userKeywords[keyword] || 0) + 1;
                  });

                  // Track the most recent appearance
                  if (message.timestamp) {
                    const messageDate = new Date(message.timestamp);
                    if (!latestTimestamp || messageDate > latestTimestamp) {
                      latestTimestamp = messageDate;
                    }
                  }
                }
              }
            });
          }
        });

        // Update last updated timestamp if this is the most recent
        if (
          latestTimestamp &&
          (!lastUpdated || latestTimestamp > lastUpdated)
        ) {
          lastUpdated = latestTimestamp;
        }

        return { appearances: userAppearances, keywords: userKeywords };
      });

      // Wait for all user processing to complete
      const results = await Promise.all(userPromises);

      // Sum up the appearances and merge keyword counts
      searchAppearances = 0;
      results.forEach((result) => {
        searchAppearances += result.appearances;

        // Merge keyword counts
        Object.entries(result.keywords).forEach(([keyword, count]) => {
          keywordCounts[keyword] = (keywordCounts[keyword] || 0) + count;
        });
      });

      // Sort keywords by frequency and get top 5
      const topKeywords = Object.entries(keywordCounts)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5)
        .map(([keyword, count]) => ({ keyword, count }));

      setUserStats({
        searchAppearances: searchAppearances,
        viewCount: 0, // Not tracking for now
        messageCount: 0, // Not tracking for now
        lastUpdated: lastUpdated || new Date(),
        topKeywords: topKeywords,
      });
    } catch (error) {
      console.error("Error fetching user analytics:", error);
      setUserStats({
        searchAppearances: 0,
        viewCount: 0,
        messageCount: 0,
        lastUpdated: new Date(),
        topKeywords: [],
      });
    } finally {
      setIsAnalyticsLoading(false);
    }
  };

  // Effect for loading animation
  useEffect(() => {
    let interval;
    if (isChatsLoading || isInboxLoading || isAnalyticsLoading) {
      interval = setInterval(() => {
        setLoadingDots((prev) => (prev % 3) + 1);
      }, 500);
    }
    return () => clearInterval(interval);
  }, [isChatsLoading, isInboxLoading, isAnalyticsLoading]);

  // Function to render the loading animation
  const renderLoadingSymbols = () => {
    if (loadingDots === 1) return ">";
    if (loadingDots === 2) return "> >";
    return "> > >";
  };

  useEffect(() => {
    // Sync the local discoverMode state with the prop from the parent
    setDiscoverMode(isInDiscoverMode);
  }, [isInDiscoverMode]);

  // Fetch regular chats
  useEffect(() => {
    if (inboxMode || analyticsMode) return; // Don't fetch chats in inbox or analytics mode

    const user = auth.currentUser;
    if (!user) return;

    setIsChatsLoading(true); // Set loading to true when starting fetch

    const chatsRef = collection(firestore, `users/${user.uid}/chats`);
    const q = query(chatsRef, orderBy("updatedAt", "desc"));

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const chatData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setChats(chatData);
        setIsChatsLoading(false); // Set loading to false when done
      },
      (error) => {
        console.error("Error fetching chats:", error);
        setIsChatsLoading(false); // Also set loading to false on error
      }
    );

    return () => unsubscribe();
  }, [inboxMode, analyticsMode]);

  // Fetch user profile data
  const fetchUserProfile = async (userId) => {
    if (!userId || userProfiles[userId]) return;

    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserProfiles((prev) => ({
          ...prev,
          [userId]: {
            id: userId,
            name: `${userData.firstName} ${userData.lastName}`,
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  // Fetch inbox messages with real-time updates
  useEffect(() => {
    if (!inboxMode) return; // Only fetch inbox messages in inbox mode

    const user = auth.currentUser;
    if (!user) return;

    setIsInboxLoading(true); // Set inbox loading to true

    // First, get all chats the user is participating in
    const fetchUserChats = async () => {
      try {
        const chatsRef = collection(firestore, "chats");
        const q = query(
          chatsRef,
          where("participants", "array-contains", user.uid)
        );

        const chatDocs = await getDocs(q);
        const chatIds = chatDocs.docs
          .filter((doc) => !doc.data().deletedBy?.includes(user.uid))
          .map((doc) => doc.id);

        return chatIds;
      } catch (error) {
        console.error("Error fetching user chats:", error);
        return [];
      }
    };

    // Set up real-time listeners for all user's chats
    const setupChatListeners = async () => {
      const chatIds = await fetchUserChats();
      if (chatIds.length === 0) {
        setIsInboxLoading(false);
        return () => {};
      }

      // Create a map to store the latest message for each chat
      const latestMessages = {};
      const unsubscribers = [];

      // Set up a listener for each chat
      for (const chatId of chatIds) {
        const messagesRef = collection(firestore, `chats/${chatId}/messages`);
        const messagesQuery = query(messagesRef, orderBy("createdAt", "desc"));

        const unsubscribe = onSnapshot(
          messagesQuery,
          async (snapshot) => {
            // Get the most recent text message for this chat
            const recentMessage = snapshot.docs
              .map((doc) => ({
                id: doc.id,
                chatId: chatId,
                ...doc.data(),
              }))
              .filter((msg) => !msg.hiddenFor?.includes(user.uid))
              .filter((msg) => msg.text) // Only include text messages
              .shift(); // Get only the first (most recent) message

            if (recentMessage) {
              // Get chat data to find the other user
              const chatDoc = await getDoc(doc(firestore, "chats", chatId));
              if (chatDoc.exists()) {
                const chatData = chatDoc.data();
                const otherUserId = chatData.participants.find(
                  (id) => id !== user.uid
                );

                // Fetch profile if needed
                if (otherUserId) {
                  fetchUserProfile(otherUserId);
                }

                // Store this message in our map
                latestMessages[chatId] = {
                  ...recentMessage,
                  otherUserId,
                };

                // Update the inbox messages state with all latest messages
                const messageList = Object.values(latestMessages).sort(
                  (a, b) =>
                    (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0)
                );

                setInboxMessages(messageList);
              }
            }

            setIsInboxLoading(false);
          },
          (error) => {
            console.error(`Error fetching messages for chat ${chatId}:`, error);
          }
        );

        unsubscribers.push(unsubscribe);
      }

      // Return a function to unsubscribe from all listeners
      return () => {
        unsubscribers.forEach((unsubscribe) => unsubscribe());
      };
    };

    const unsubscribeAll = setupChatListeners();
    return () => {
      if (typeof unsubscribeAll === "function") {
        unsubscribeAll();
      }
    };
  }, [inboxMode]);

  const handleNewChat = () => {
    // Exit any special modes and go back to regular chat mode
    setDiscoverMode(false);
    setInboxMode(false);
    setAnalyticsMode(false);
    onChatSelect(null); // Start a new chat
  };

  const handleChatSelect = async (chatId) => {
    // If we're in discover mode and trying to select a chat, exit discover mode first
    if (discoverMode && chatId !== "discover") {
      setDiscoverMode(false);
    }

    // If we're in analytics mode and trying to select a chat, exit analytics mode first
    if (analyticsMode && chatId !== "analytics") {
      setAnalyticsMode(false);
    }

    if (
      window.innerWidth <= 768 &&
      chatId &&
      chatId !== "discover" &&
      chatId !== "analytics"
    ) {
      onToggle(); // Close the sidebar on mobile for any message selection
    }

    // Mark the chat as read when selected
    if (
      chatId &&
      chatId !== "discover" &&
      chatId !== "analytics" &&
      isChatUnread(chatId)
    ) {
      markChatAsRead(chatId);
    }

    // If we're in inbox mode, we need to get additional user info
    if (inboxMode && chatId) {
      try {
        const chatRef = doc(firestore, `chats/${chatId}`);
        const chatDoc = await getDoc(chatRef);

        if (chatDoc.exists()) {
          const chatData = chatDoc.data();
          // Find the other user in this chat
          const otherUserId = chatData.participants.find(
            (id) => id !== auth.currentUser?.uid
          );

          if (otherUserId) {
            // Fetch the other user's profile
            const userDoc = await getDoc(doc(firestore, "users", otherUserId));
            if (userDoc.exists()) {
              const userData = userDoc.data();

              // Create a chat object similar to the one in Chats.js
              const directChatData = {
                id: chatId,
                otherUser: {
                  id: otherUserId,
                  name: `${userData.firstName} ${userData.lastName}`,
                  image: userData.profileImage || "",
                  bio: userData.bio || "",
                  link: userData.link || "",
                  linkText: userData.linkText || "",
                  connectionType: "Direct",
                },
                // Add this flag:
                fromInbox: true,
              };

              // Pass both the chatId and the chat data
              onChatSelect(chatId, directChatData);
              return;
            }
          }
        }
      } catch (error) {
        console.error("Error fetching chat data:", error);
      }
    }

    // If not in inbox mode or if there was an error, just pass the chatId
    onChatSelect(chatId);
  };

  const toggleInboxMode = () => {
    const newInboxMode = !inboxMode;
    setInboxMode(newInboxMode);

    if (newInboxMode) {
      // Switching to inbox mode
      setDiscoverMode(false); // Exit discover mode
      setAnalyticsMode(false); // Exit analytics mode

      // No longer automatically select the first message
      // Just reset current chat when entering inbox mode
      onChatSelect(null);
    } else {
      // Exiting inbox mode - go back to normal chat mode
      onChatSelect(null);
    }
  };

  // Format timestamp for display
  const formatMessageTime = (timestamp) => {
    if (!timestamp) return "";

    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  // Format date for analytics display
  const formatDate = (date) => {
    if (!date) return "";
    return date.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  // Truncate message text if too long
  const truncateText = (text, maxLength = 30) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  // Replace the existing menu toggle and delete functions with these new ones
  const openDeleteConfirmation = (e, chatId) => {
    e.stopPropagation();
    setChatConfirmingDelete(chatId);
  };

  const cancelDeleteConfirmation = (e) => {
    e.stopPropagation();
    setChatConfirmingDelete(null);
  };

  const confirmDeleteChat = async (e, chatId) => {
    e.stopPropagation();

    try {
      const user = auth.currentUser;
      if (!user) return;

      // For regular chats
      if (!inboxMode) {
        await deleteDoc(doc(firestore, `users/${user.uid}/chats`, chatId));

        // If the deleted chat was the current one, reset to null
        if (chatId === currentChatId) {
          onChatSelect(null);
        }
      }
      // For inbox chats
      else {
        // Get the chat document
        const chatRef = doc(firestore, `chats`, chatId);
        const chatDoc = await getDoc(chatRef);

        if (chatDoc.exists()) {
          const chatData = chatDoc.data();
          // Add current user to deletedBy array or create it if it doesn't exist
          const deletedBy = chatData.deletedBy || [];
          if (!deletedBy.includes(user.uid)) {
            deletedBy.push(user.uid);
            await updateDoc(chatRef, { deletedBy });
          }

          // Remove from inbox messages locally
          setInboxMessages((prev) =>
            prev.filter((msg) => msg.chatId !== chatId)
          );

          // If the deleted chat was the current one, reset to null
          if (chatId === currentChatId) {
            onChatSelect(null);
          }
        }
      }
    } catch (error) {
      console.error("Error deleting chat:", error);
    } finally {
      setChatConfirmingDelete(null);
    }
  };

  const handleFeedback = (e) => {
    e.preventDefault();
    const mailtoLink =
      "mailto:hello@foundry.bz?subject=Feedback%20for%20Foundry";
    window.open(mailtoLink, "_blank");
  };

  // Check if we're currently loading
  const isLoading =
    (inboxMode && isInboxLoading) ||
    (!inboxMode && !analyticsMode && isChatsLoading) ||
    (analyticsMode && isAnalyticsLoading);

  // Render the analytics content
  // Render the analytics content
  // Part 1: Remove the loading animation in the renderAnalyticsContent function
  const renderAnalyticsContent = () => {
    // Remove the loading check and directly return the analytics content
    // Create a simple comma-separated list of keywords
    const keywordsText =
      userStats.topKeywords && userStats.topKeywords.length > 0
        ? userStats.topKeywords.map((item) => item.keyword).join(", ")
        : "";

    return (
      <div className={styles.analyticsContainer}>
        <div className={styles.statRow}>
          <span className={styles.statIcon}>
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "1.25rem",
              }}
            >
              query_stats
            </span>
          </span>
          <div className={styles.statContent}>
            <div className={styles.statsLabel}>search appearances</div>
            <div className={styles.statNumber}>
              {userStats.searchAppearances}
            </div>
          </div>
        </div>

        {keywordsText && (
          <div className={styles.statRow}>
            <span className={styles.statIcon}>
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                }}
              >
                manage_search
              </span>
            </span>
            <div className={styles.statContent}>
              <div className={styles.statsLabel}>keyword matches</div>
              <div className={styles.keywordsText}>{keywordsText}</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <aside
        className={`${styles.sidebar} ${isOpen ? styles.open : ""} ${
          darkMode ? styles.darkMode : ""
        }`}
      >
        {/* Top bar with logo only */}
        <div className={`${styles.topBar} ${darkMode ? styles.darkMode : ""}`}>
          {/* Logo on the left as a button with same onClick as search */}
          <button
            className={styles.logoButton}
            onClick={handleNewChat}
            type="button"
            aria-label="Search"
          >
            <img src={logo} alt="foundry" className={styles.logo} />
            {/* <span>foundry</span> */}
          </button>

          {/* Right buttons for toggle sidebar and edit */}
          <div className={styles.topBarRightButtons}>
            <button
              className={styles.iconButton}
              type="button"
              aria-label="Toggle Sidebar"
              onClick={onToggle}
            >
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                keyboard_double_arrow_left
              </span>
            </button>
            <button
              onClick={handleNewChat}
              className={styles.iconButton}
              type="button"
              aria-label="Edit"
            >
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                edit_square
              </span>
            </button>
          </div>
        </div>

        {/* Main navigation menu */}
        <div className={styles.topHalf}>
          {/* Search Button */}
          <button
            className={`${styles.chatButton} ${
              !discoverMode && !inboxMode && !analyticsMode ? styles.active : ""
            }`}
            onClick={handleNewChat}
            type="button"
          >
            <div className={styles.chatButtonContent}>
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  marginRight: "0.5rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                Search
              </span>
              <span className={styles.chatTitle}>Search</span>
            </div>
          </button>

          {/* Analytics Button - New */}
          <button
            className={`${styles.chatButton} ${
              analyticsMode ? styles.active : ""
            }`}
            onClick={toggleAnalyticsMode}
            type="button"
          >
            <div className={styles.chatButtonContent}>
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  marginRight: "0.5rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                monitoring
              </span>
              <span className={styles.chatTitle}>Analytics</span>
            </div>
          </button>

          {/* Inbox Button */}
          <button
            className={`${styles.chatButton} ${inboxMode ? styles.active : ""}`}
            onClick={toggleInboxMode}
            type="button"
          >
            <div className={styles.chatButtonContent}>
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  marginRight: "0.5rem",
                  color: darkMode ? "#b0b0b0" : "gray",
                }}
              >
                Inbox
              </span>
              <span className={styles.chatTitle}>
                Inbox{unreadChatsCount > 0 ? ` (${unreadChatsCount})` : ""}
              </span>
            </div>
          </button>
        </div>

        <div className={styles.chatList}>
          {analyticsMode ? (
            // Display analytics content
            renderAnalyticsContent()
          ) : inboxMode ? (
            // Display inbox messages - with inline delete confirmation
            inboxMessages.length > 0 ? (
              inboxMessages.map((message) => (
                <div key={message.id} className={styles.chatItemContainer}>
                  <button
                    className={`${styles.chatButton} ${
                      message.chatId === currentChatId ? styles.active : ""
                    } ${isChatUnread(message.chatId) ? styles.unreadChat : ""}`}
                    onClick={() => handleChatSelect(message.chatId)}
                    type="button"
                  >
                    <div className={styles.chatButtonContent}>
                      <span className={styles.chatTitle}>{message.text}</span>

                      {/* New inline delete confirmation UI */}
                      {chatConfirmingDelete === message.chatId ? (
                        <div className={styles.iconContainer}>
                          <button
                            className={styles.confirmDeleteBtn}
                            onClick={(e) =>
                              confirmDeleteChat(e, message.chatId)
                            }
                            type="button"
                            aria-label="Confirm delete"
                          >
                            <span
                              className="material-symbols-outlined"
                              style={{
                                fontSize: "1.25rem",
                                color: darkMode ? "#b0b0b0" : "gray",
                              }}
                            >
                              delete
                            </span>
                          </button>
                          <button
                            className={styles.cancelDeleteBtn}
                            onClick={cancelDeleteConfirmation}
                            type="button"
                            aria-label="Cancel delete"
                          >
                            <span
                              className="material-symbols-outlined"
                              style={{
                                fontSize: "1.25rem",
                                color: darkMode ? "#b0b0b0" : "gray",
                              }}
                            >
                              close
                            </span>
                          </button>
                        </div>
                      ) : (
                        <button
                          className={styles.menuTrigger}
                          onClick={(e) =>
                            openDeleteConfirmation(e, message.chatId)
                          }
                          type="button"
                          aria-label="Chat options"
                        >
                          <span className="material-symbols-outlined">
                            more_horiz
                          </span>
                        </button>
                      )}
                    </div>
                  </button>
                </div>
              ))
            ) : !isInboxLoading ? (
              <div className={styles.emptyState}></div>
            ) : null
          ) : // Display regular chats - with inline delete confirmation
          chats.length > 0 ? (
            chats.map((chat) => (
              <div key={chat.id} className={styles.chatItemContainer}>
                <button
                  className={`${styles.chatButton} ${
                    chat.id === currentChatId ? styles.active : ""
                  } ${isChatUnread(chat.id) ? styles.unreadChat : ""}`}
                  onClick={() => handleChatSelect(chat.id)}
                  type="button"
                >
                  <div className={styles.chatButtonContent}>
                    <span className={styles.chatTitle}>
                      {chat.title || "new chat"}
                    </span>

                    {/* New inline delete confirmation UI */}
                    {chatConfirmingDelete === chat.id ? (
                      <div className={styles.iconContainer}>
                        <button
                          className={styles.confirmDeleteBtn}
                          onClick={(e) => confirmDeleteChat(e, chat.id)}
                          type="button"
                          aria-label="Confirm delete"
                        >
                          <span
                            className="material-symbols-outlined"
                            style={{
                              fontSize: "1.25rem",
                              color: darkMode ? "#b0b0b0" : "gray",
                            }}
                          >
                            delete
                          </span>
                        </button>
                        <button
                          className={styles.cancelDeleteBtn}
                          onClick={cancelDeleteConfirmation}
                          type="button"
                          aria-label="Cancel delete"
                        >
                          <span
                            className="material-symbols-outlined"
                            style={{
                              fontSize: "1.25rem",
                              color: darkMode ? "#b0b0b0" : "gray",
                            }}
                          >
                            close
                          </span>
                        </button>
                      </div>
                    ) : (
                      <button
                        className={styles.menuTrigger}
                        onClick={(e) => openDeleteConfirmation(e, chat.id)}
                        type="button"
                        aria-label="Chat options"
                      >
                        <span className="material-symbols-outlined">
                          more_horiz
                        </span>
                      </button>
                    )}
                  </div>
                </button>
              </div>
            ))
          ) : !isChatsLoading ? (
            <div className={styles.emptyState}></div>
          ) : null}
        </div>

        {/* Add spacer to push footer to bottom */}
        {/* <div className={styles.spacer}></div> */}

        {/* Footer with terms, privacy, and beta tag */}
        {/* <div className={`${styles.footer} ${darkMode ? styles.darkMode : ""}`}>
          <div className={styles.links}>
            <a href="/t&c's" className={styles.smallPrint}>
              terms
            </a>
            <a href="/privacy-policy" className={styles.smallPrint}>
              privacy
            </a>
            <div className={styles.tag}>beta/v 1.0</div>
          </div>
        </div> */}
      </aside>
    </>
  );
};

export default Sidebar;
