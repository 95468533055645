import React, { useState, useRef, useEffect } from "react";
import defaultImage from "../../assets/images/startupImage.jpg";
import {
  collection,
  addDoc,
  getDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import DayDropdown from "../Dropdowns/DayDropdown";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestore } from "../../firebase";
import classes from "./AddStartupModal.module.css";
import CustomDropdown from "../Dropdowns/CustomDropdown";
import imageCompression from "browser-image-compression";
import monthsList from "../../utils/monthsList"; // Add this import
import Cropper from "react-easy-crop";
import {
  generateStartupUrlSlug,
  updateStartupUrlSlug,
} from "../../utils/startupUrlUtils";
import MonthDropdown from "../Dropdowns/MonthDropdown";
import YearDropdown from "../Dropdowns/YearDropdown";
import CompanyTypeDropdown from "../Dropdowns/CompanyTypeDropdown";
import { convertUrlsToLinks } from "../../utils/textUtils";

const config = {
  cUrl: "https://api.countrystatecity.in/v1",
  ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
};

const AddStartupModal = ({ onClose, currentUser, onStartupAdded }) => {
  const [role, setRole] = useState("");
  const [loadingDots, setLoadingDots] = useState(1);
  const [industry, setIndustry] = useState("");
  const [companyType, setCompanyType] = useState(null);
  const [startupImage, setStartupImage] = useState(null);
  const [currentStartupImageUrl, setCurrentStartupImageUrl] = useState(null);
  const [startupName, setStartupName] = useState("");
  const [bio, setBio] = useState("");
  // Remove single link state
  // const [link, setLink] = useState("");
  // const [linkText, setLinkText] = useState("");
  // Add multiple links state
  const [links, setLinks] = useState([{ url: "", label: "" }]);
  const [linkErrors, setLinkErrors] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [intro, setIntro] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [monthFounded, setMonthFounded] = useState(null);
  const [yearFounded, setYearFounded] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [dayFounded, setDayFounded] = useState(null);
  const formRef = useRef(null);
  const bioTextareaRef = useRef(null);
  const cityCache = React.useMemo(() => ({}), []);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setLoadingDots((prev) => (prev % 3) + 1);
      }, 500);
    }
    return () => clearInterval(interval);
  }, [loading]);

  const handleIntroChange = (e) => {
    if (e.target.value.length <= 2500) {
      setIntro(e.target.value);
    }
  };

  // Loading symbols function
  const renderLoadingSymbols = () => {
    if (loadingDots === 1) return ">";
    if (loadingDots === 2) return "> >";
    return "> > >";
  };

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.geabbabababb}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
    };
  }, []);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const areRequiredFieldsFilled = () => {
    return startupName.trim() !== "";
  };

  const handleRoleChange = (e) => {
    if (e.target.value.length <= 50) {
      setRole(e.target.value);
    }
  };

  const handleIndustryChange = (e) => {
    if (e.target.value.length <= 50) {
      setIndustry(e.target.value);
    }
  };

  useEffect(() => {
    const loadCountries = async () => {
      try {
        const response = await fetch(`${config.cUrl}/countries`, {
          headers: { "X-CSCAPI-KEY": config.ckey },
        });
        const data = await response.json();
        setCountries(Array.isArray(data) ? data : []);
      } catch (error) {
        setCountries([]);
      }
    };

    loadCountries();
  }, []);

  const loadCities = React.useCallback(async () => {
    if (!selectedCountry) return;
    try {
      const response = await fetch(
        `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
        {
          headers: { "X-CSCAPI-KEY": config.ckey },
        }
      );
      const data = await response.json();
      cityCache[selectedCountry.iso2] = Array.isArray(data) ? data : [];
      setCities(cityCache[selectedCountry.iso2]);
    } catch (error) {
      setCities([]);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    if (selectedCountry) {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        loadCities();
      }
    }
  }, [selectedCountry, cityCache, loadCities]);

  const handleStartupNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setStartupName(e.target.value);
    }
  };

  const handleBioChange = (e) => {
    if (e.target.value.length <= 250) {
      setBio(e.target.value);
    }
  };

  const validateUrl = (string) => {
    if (!string) return true; // Empty string is considered valid (optional)
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  // Multiple links handlers
  const handleLinkChange = (index, field, value) => {
    const newLinks = [...links];
    newLinks[index][field] = value;
    setLinks(newLinks);

    // Validate URL
    const newErrors = [...linkErrors];
    if (field === "url") {
      newErrors[index] = validateUrl(value) ? "" : "Please enter a valid URL";
    }
    setLinkErrors(newErrors);
  };

  const addLink = () => {
    setLinks([...links, { url: "", label: "" }]);
    setLinkErrors([...linkErrors, ""]);
  };

  const removeLink = (index) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);

    const newErrors = [...linkErrors];
    newErrors.splice(index, 1);
    setLinkErrors(newErrors);

    // Always keep at least one link field
    if (newLinks.length === 0) {
      setLinks([{ url: "", label: "" }]);
      setLinkErrors([""]);
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCities([]);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
  };

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleStartupImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 8,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);

        const reader = new FileReader();
        reader.onload = () => {
          setCurrentStartupImageUrl(reader.result);
          setIsCropping(true);
        };
        reader.readAsDataURL(compressedFile);

        setStartupImage(compressedFile);
        e.target.value = "";
      } catch (error) {
        setError("Failed to process the image. Please try again.");
      }
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!startupName) {
      setError("Please fill in all required fields.");
      return false;
    }

    // Validate all links
    const urlErrors = links.map((link) =>
      validateUrl(link.url) ? "" : "Please enter a valid URL"
    );
    if (urlErrors.some((error) => error)) {
      setLinkErrors(urlErrors);
      setError("Please fix the errors in your links.");
      return false;
    }

    setLoading(true);
    setError("");

    try {
      let startupImageUrl = "";

      if (startupImage && croppedAreaPixels) {
        const croppedImage = await getCroppedImg(
          currentStartupImageUrl,
          croppedAreaPixels
        );
        const response = await fetch(croppedImage);
        const blob = await response.blob();

        const storage = getStorage();
        const tempStartupId = `temp_${Date.now()}`;
        const tempPostId = "profile";
        const startupImageRef = ref(
          storage,
          `startupImages/${
            currentUser.uid
          }/${tempStartupId}/${tempPostId}/${Date.now()}.jpg`
        );

        await uploadBytes(startupImageRef, blob);
        startupImageUrl = await getDownloadURL(startupImageRef);
      } else {
        startupImageUrl = defaultImage;
      }

      const startupUrlSlug = await generateStartupUrlSlug(startupName);
      const timestamp = serverTimestamp();

      // Process intro for links
      const trimmedIntro = intro.replace(/\s+$/g, "");
      const introHtml = convertUrlsToLinks(trimmedIntro);

      // Filter out empty links
      const validLinks = links.filter((link) => link.url.trim() !== "");

      // For backward compatibility - take the first link if present
      const firstLink = validLinks.length > 0 ? validLinks[0] : null;

      const startupData = {
        startupName,
        bio,
        // For backward compatibility
        link: firstLink ? firstLink.url : "",
        linkText: firstLink ? firstLink.label : "",
        // New multiple links array
        links: validLinks,
        country: selectedCountry ? selectedCountry.name : "",
        countryISO2: selectedCountry ? selectedCountry.iso2 : "",
        city: selectedCity ? selectedCity.name : "",
        startupImage: startupImageUrl,
        startupUrlSlug,
        intro: trimmedIntro,
        introHtml,
        followingCount: 0,
        role: "",
        industry: "",
        companyType: "",
        createdAt: timestamp,
      };

      const startupsRef = collection(
        firestore,
        `users/${currentUser.uid}/startups`
      );
      const docRef = await addDoc(startupsRef, startupData);

      if (onStartupAdded) {
        onStartupAdded({
          id: docRef.id,
          ...startupData,
          createdAt: new Date(),
        });
      }

      return true;
    } catch (error) {
      setError("Failed to add startup. Please try again.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleSaveButtonClick = async () => {
    if (formRef.current) {
      try {
        const success = await handleSubmit(new Event("submit"));
        if (success && !error) {
          onClose(true);
        }
      } catch (err) {}
    }
  };

  // Add this new function
  const handleClose = (e, isSuccess = false) => {
    // If there's an event, prevent default behavior
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    // Call onClose with the success state
    onClose(isSuccess);
  };

  const cropSize = { width: 96, height: 96 };

  const handleStartupImageClick = (e) => {
    e.preventDefault();
    const input = document.getElementById("startupImage");
    if (input) {
      input.click();
    }
  };

  return (
    <div className={classes.modalBackdrop}>
      <div className={classes.modalContent}>
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            a
            <div
              className={classes.closeIcon}
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <span class="material-symbols-outlined">close</span>
            </div>
          </div>
        </div>

        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
          style={{ opacity: loading ? 0.5 : 1 }}
        >
          <div style={{ opacity: loading ? 0.5 : 1 }}></div>
          <div className={classes.imageContainer}>
            <div className={classes.profileImage}>
              {currentStartupImageUrl && isCropping ? (
                <div className={classes.cropContainer}>
                  <Cropper
                    image={currentStartupImageUrl}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    // cropShape="round"
                    showGrid={false}
                    cropSize={cropSize}
                    objectFit="cover"
                  />
                </div>
              ) : (
                <img
                  src={currentStartupImageUrl || defaultImage}
                  alt="Startup"
                />
              )}
            </div>
            <button
              type="button"
              className={classes.uploadButton}
              onClick={handleStartupImageClick}
            >
              + add image
            </button>
          </div>
          <input
            type="file"
            id="startupImage"
            accept="image/*"
            onChange={handleStartupImageChange}
            className={classes.profileButton}
          />
          <input
            id="startupName"
            name="startupName"
            type="text"
            placeholder="name (ex: airbnb)"
            className={classes.userInput}
            value={startupName}
            onChange={handleStartupNameChange}
            disabled={loading}
          />
          <textarea
            id="bio"
            ref={bioTextareaRef}
            value={bio}
            onChange={handleBioChange}
            className={classes.textArea}
            placeholder="headline (ex: belong anywhere.)"
          />
          <CustomDropdown
            id="country"
            options={countries}
            selectedValue={selectedCountry}
            onChange={handleCountryChange}
            inputValue={countryInputValue}
            setInputValue={setCountryInputValue}
            disabled={loading}
            placeholder="country (Ex: United States)"
          />
          <div className={classes.space}></div>
          <CustomDropdown
            id="city"
            options={cities}
            selectedValue={selectedCity}
            onChange={handleCityChange}
            inputValue={cityInputValue}
            setInputValue={setCityInputValue}
            disabled={!selectedCountry || loading}
            placeholder="city (Ex: San Francisco)"
          />
          <div className={classes.space}></div>
          {/* Multiple links section */}
          <div className={classes.linksSection}>
            <div className={classes.linksSectionHeader}>
              <button
                type="button"
                className={classes.addLinkButton}
                onClick={addLink}
                disabled={loading}
              >
                + add link
              </button>
            </div>

            {links.map((link, index) => (
              <div key={index} className={classes.linkContainer}>
                <div className={classes.linkInputs}>
                  <input
                    type="url"
                    placeholder="url (ex: https://linkedin.com/in/username)"
                    className={`${classes.linkInput} ${
                      linkErrors[index] ? classes.inputError : ""
                    }`}
                    value={link.url}
                    onChange={(e) =>
                      handleLinkChange(index, "url", e.target.value)
                    }
                    disabled={loading}
                  />
                  <input
                    type="text"
                    placeholder="label (ex: linkedin)"
                    className={classes.linkInput}
                    value={link.label}
                    onChange={(e) =>
                      handleLinkChange(index, "label", e.target.value)
                    }
                    disabled={loading || !link.url}
                  />
                </div>
                {linkErrors[index] && (
                  <div className={classes.errorText}>{linkErrors[index]}</div>
                )}
                {links.length > 1 && (
                  <button
                    type="button"
                    className={classes.removeLinkButton}
                    onClick={() => removeLink(index)}
                    disabled={loading}
                  >
                    - remove
                  </button>
                )}
              </div>
            ))}
          </div>
          <textarea
            id="intro"
            value={intro}
            onChange={handleIntroChange}
            className={classes.textArea}
            style={{
              opacity: loading ? 0.5 : 1,
              minHeight: "16.25rem",
            }}
            disabled={loading}
            placeholder={
              "tell us about your page (e.g. startup, accelerator, incubator, community, event, program, etc.), and what it does.\n\n- what makes this unique?\n\n- who's it for?\n\n- what stage/phase?\n\n- key technologies or approaches.\n\n- what you're looking for."
            }
          />
        </form>

        <div className={classes.cardFooter}>
          <button
            type="button"
            className={`${classes.button} ${loading ? classes.loading : ""} ${
              !areRequiredFieldsFilled() ? classes.disabled : ""
            }`}
            disabled={loading || !areRequiredFieldsFilled()}
            onClick={handleSaveButtonClick}
          >
            {loading ? renderLoadingSymbols() : "add page"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddStartupModal;
