import React, { useState, useMemo, useEffect } from "react";
import styles from "./SidebarRight.module.css";
import ProfileModal from "../Modals/ProfileModal";
import IntroModal from "../Modals/IntroModal";
import AddStartupModal from "../Modals/AddStartupModal";
import SettingsModal from "../Modals/SettingsModal";
import EditSingleStartupModal from "../Modals/EditSingleStartupModal";
// Removed DeleteConfirmationModal import
import {
  doc,
  updateDoc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";
import { firestore, auth } from "../../firebase";
import {
  signInWithEmailAndPassword,
  verifyBeforeUpdateEmail,
  updatePassword,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../assets/icons/profileImage.jpg";

const SidebarRight = ({ isOpen, onToggle, userData }) => {
  const navigate = useNavigate();
  const [startupConfirmingDelete, setStartupConfirmingDelete] = useState(null);
  const [loadingDots, setLoadingDots] = useState(1);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const [introModalScrollPosition, setIntroModalScrollPosition] = useState(0);
  const [selectedStartupForIntro, setSelectedStartupForIntro] = useState(null);
  const [expandedTexts, setExpandedTexts] = useState({});
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [profileModalScrollPosition, setProfileModalScrollPosition] =
    useState(0);
  const [showAddStartupModal, setShowAddStartupModal] = useState(false);
  const [isAddStartupModalOpen, setIsAddStartupModalOpen] = useState(false);
  const [addStartupModalScrollPosition, setAddStartupModalScrollPosition] =
    useState(0);
  const [showEditStartupModal, setShowEditStartupModal] = useState(false);
  const [isEditStartupModalOpen, setIsEditStartupModalOpen] = useState(false);
  const [editStartupModalScrollPosition, setEditStartupModalScrollPosition] =
    useState(0);
  const [showSettings, setShowSettings] = useState(false);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const [modalScrollPosition, setModalScrollPosition] = useState(0);
  const [fullUserData, setFullUserData] = useState({ startups: [] });
  const MAX_LENGTH = 125;
  const [accountConfirmingDelete, setAccountConfirmingDelete] = useState(false);
  const [isEmailChangeOpen, setIsEmailChangeOpen] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [isCurrentEmailValid, setIsCurrentEmailValid] = useState(false);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);

  const [isPasswordChangeOpen, setIsPasswordChangeOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  // Add this after your imports
  // Social media icon components
  const SocialIcons = {
    instagram: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    apple: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M17.537 12.625a4.421 4.421 0 0 0 2.684 4.047 10.96 10.96 0 0 1-1.384 2.845c-.834 1.218-1.7 2.432-3.062 2.457-1.34.025-1.77-.794-3.3-.794-1.531 0-2.01.769-3.275.82-1.316.049-2.317-1.318-3.158-2.532-1.72-2.484-3.032-7.017-1.27-10.077A4.9 4.9 0 0 1 8.91 6.884c1.292-.025 2.51.869 3.3.869.789 0 2.27-1.075 3.828-.917a4.67 4.67 0 0 1 3.66 1.984 4.524 4.524 0 0 0-2.16 3.805m-2.52-7.432A4.4 4.4 0 0 0 16.06 2a4.482 4.482 0 0 0-2.945 1.516 4.185 4.185 0 0 0-1.061 3.093 3.708 3.708 0 0 0 2.967-1.416Z" />
      </svg>
    ),
    amazon: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z" />
      </svg>
    ),
    discord: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M18.942 5.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.586 11.586 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3 17.392 17.392 0 0 0-2.868 11.662 15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.638 10.638 0 0 1-1.706-.83c.143-.106.283-.217.418-.331a11.664 11.664 0 0 0 10.118 0c.137.114.277.225.418.331-.544.328-1.116.606-1.71.832a12.58 12.58 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM8.678 14.813a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.929 1.929 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
      </svg>
    ),
    dribbble: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M12 2a10 10 0 1 0 10 10A10.009 10.009 0 0 0 12 2Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.093 20.093 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM10 3.707a8.82 8.82 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.755 45.755 0 0 0 10 3.707Zm-6.358 6.555a8.57 8.57 0 0 1 4.73-5.981 53.99 53.99 0 0 1 3.168 4.941 32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.641 31.641 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM12 20.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 15.113 13a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    dropbox: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12.013 6.175 7.006 9.369l5.007 3.194-5.007 3.193L2 12.545l5.006-3.193L2 6.175l5.006-3.194 5.007 3.194ZM6.981 17.806l5.006-3.193 5.006 3.193L11.987 21l-5.006-3.194Z" />
        <path d="m12.013 12.545 5.006-3.194-5.006-3.176 4.98-3.194L22 6.175l-5.007 3.194L22 12.562l-5.007 3.194-4.98-3.211Z" />
      </svg>
    ),
    facebook: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    github: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M12.006 2a9.847 9.847 0 0 0-6.484 2.44 10.32 10.32 0 0 0-3.393 6.17 10.48 10.48 0 0 0 1.317 6.955 10.045 10.045 0 0 0 5.4 4.418c.504.095.683-.223.683-.494 0-.245-.01-1.052-.014-1.908-2.78.62-3.366-1.21-3.366-1.21a2.711 2.711 0 0 0-1.11-1.5c-.907-.637.07-.621.07-.621.317.044.62.163.885.346.266.183.487.426.647.71.135.253.318.476.538.655a2.079 2.079 0 0 0 2.37.196c.045-.52.27-1.006.635-1.37-2.219-.259-4.554-1.138-4.554-5.07a4.022 4.022 0 0 1 1.031-2.75 3.77 3.77 0 0 1 .096-2.713s.839-.275 2.749 1.05a9.26 9.26 0 0 1 5.004 0c1.906-1.325 2.74-1.05 2.74-1.05.37.858.406 1.828.101 2.713a4.017 4.017 0 0 1 1.029 2.75c0 3.939-2.339 4.805-4.564 5.058a2.471 2.471 0 0 1 .679 1.897c0 1.372-.012 2.477-.012 2.814 0 .272.18.592.687.492a10.05 10.05 0 0 0 5.388-4.421 10.473 10.473 0 0 0 1.313-6.948 10.32 10.32 0 0 0-3.39-6.165A9.847 9.847 0 0 0 12.007 2Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    google: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M12.037 21.998a10.313 10.313 0 0 1-7.168-3.049 9.888 9.888 0 0 1-2.868-7.118 9.947 9.947 0 0 1 3.064-6.949A10.37 10.37 0 0 1 12.212 2h.176a9.935 9.935 0 0 1 6.614 2.564L16.457 6.88a6.187 6.187 0 0 0-4.131-1.566 6.9 6.9 0 0 0-4.794 1.913 6.618 6.618 0 0 0-2.045 4.657 6.608 6.608 0 0 0 1.882 4.723 6.891 6.891 0 0 0 4.725 2.07h.143c1.41.072 2.8-.354 3.917-1.2a5.77 5.77 0 0 0 2.172-3.41l.043-.117H12.22v-3.41h9.678c.075.617.109 1.238.1 1.859-.099 5.741-4.017 9.6-9.746 9.6l-.215-.002Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    linkedin: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
          clipRule="evenodd"
        />
        <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
      </svg>
    ),
    reddit: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12.008 16.521a3.84 3.84 0 0 0 2.47-.77v.04a.281.281 0 0 0 .005-.396.281.281 0 0 0-.395-.005 3.291 3.291 0 0 1-2.09.61 3.266 3.266 0 0 1-2.081-.63.27.27 0 0 0-.38.381 3.84 3.84 0 0 0 2.47.77Z" />
        <path
          fillRule="evenodd"
          d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-4.845-1.407A1.463 1.463 0 0 1 18.67 12a1.46 1.46 0 0 1-.808 1.33c.01.146.01.293 0 .44 0 2.242-2.61 4.061-5.829 4.061s-5.83-1.821-5.83-4.061a3.25 3.25 0 0 1 0-.44 1.458 1.458 0 0 1-.457-2.327 1.458 1.458 0 0 1 2.063-.064 7.163 7.163 0 0 1 3.9-1.23l.738-3.47v-.006a.31.31 0 0 1 .37-.236l2.452.49a1 1 0 1 1-.132.611l-2.14-.45-.649 3.12a7.11 7.11 0 0 1 3.85 1.23c.259-.246.6-.393.957-.405Z"
          clipRule="evenodd"
        />
        <path d="M15.305 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-4.625 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
      </svg>
    ),
    stackoverflow: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M17 20v-5h2v6.988H3V15h1.98v5H17Z" />
        <path d="m6.84 14.522 8.73 1.825.369-1.755-8.73-1.825-.369 1.755Zm1.155-4.323 8.083 3.764.739-1.617-8.083-3.787-.739 1.64Zm3.372-5.481L10.235 6.08l6.859 5.704 1.132-1.362-6.859-5.704ZM15.57 17H6.655v2h8.915v-2ZM12.861 3.111l6.193 6.415 1.414-1.415-6.43-6.177-1.177 1.177Z" />
      </svg>
    ),
    twitter: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M13.795 10.533 20.68 2h-3.073l-5.255 6.517L7.69 2H1l7.806 10.91L1.47 22h3.074l5.705-7.07L15.31 22H22l-8.205-11.467Zm-2.38 2.95L9.97 11.464 4.36 3.627h2.31l4.528 6.317 1.443 2.02 6.018 8.409h-2.31l-4.934-6.89Z" />
      </svg>
    ),
    whatsapp: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z"
          clipRule="evenodd"
        />
        <path d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z" />
      </svg>
    ),
    youtube: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    default: () => (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm8 6a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-10a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 12 8Zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  };

  // Replace openDeleteModal with openDeleteConfirmation
  const openDeleteConfirmation = (startup, e) => {
    e.stopPropagation();
    setStartupConfirmingDelete(startup.id);
  };

  // Add a function to cancel the delete confirmation
  const cancelDeleteConfirmation = (e) => {
    e.stopPropagation();
    setStartupConfirmingDelete(null);
  };

  // Modify the delete function to work with the new flow
  const confirmDeleteStartup = (startupId, e) => {
    e.stopPropagation();
    handleDeleteStartup(startupId);
    setStartupConfirmingDelete(null);
  };

  // Add this with the other useEffect hooks
  useEffect(() => {
    let interval;
    if (isDeleting || startupConfirmingDelete !== null) {
      interval = setInterval(() => {
        setLoadingDots((prev) => (prev % 3) + 1);
      }, 500);
    }
    return () => clearInterval(interval);
  }, [isDeleting, startupConfirmingDelete]);

  // Add this with the other functions
  const renderLoadingSymbols = () => {
    if (loadingDots === 1) return ">";
    if (loadingDots === 2) return "> >";
    return "> > >";
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  // Email change handlers
  const handleInitiateEmailChange = () => {
    setIsEmailChangeOpen(true);
  };

  // Create a utility function to determine link type and get appropriate icon
  // Updated function to determine link type
  const getLinkIconAndType = (url) => {
    if (!url) return { type: "default", icon: "default" };

    try {
      const parsedUrl = new URL(url.toLowerCase());
      const hostname = parsedUrl.hostname;

      if (hostname.includes("linkedin.com")) {
        return { type: "linkedin", icon: "linkedin" };
      } else if (
        hostname.includes("twitter.com") ||
        hostname.includes("x.com")
      ) {
        return { type: "twitter", icon: "twitter" };
      } else if (hostname.includes("instagram.com")) {
        return { type: "instagram", icon: "instagram" };
      } else if (hostname.includes("facebook.com")) {
        return { type: "facebook", icon: "facebook" };
      } else if (hostname.includes("youtube.com")) {
        return { type: "youtube", icon: "youtube" };
      } else if (hostname.includes("github.com")) {
        return { type: "github", icon: "github" };
      } else if (hostname.includes("apple.com")) {
        return { type: "apple", icon: "apple" };
      } else if (hostname.includes("amazon.com")) {
        return { type: "amazon", icon: "amazon" };
      } else if (
        hostname.includes("discord.com") ||
        hostname.includes("discord.gg")
      ) {
        return { type: "discord", icon: "discord" };
      } else if (hostname.includes("dribbble.com")) {
        return { type: "dribbble", icon: "dribbble" };
      } else if (hostname.includes("dropbox.com")) {
        return { type: "dropbox", icon: "dropbox" };
      } else if (hostname.includes("google.com")) {
        return { type: "google", icon: "google" };
      } else if (hostname.includes("reddit.com")) {
        return { type: "reddit", icon: "reddit" };
      } else if (hostname.includes("stackoverflow.com")) {
        return { type: "stackoverflow", icon: "stackoverflow" };
      } else if (hostname.includes("whatsapp.com")) {
        return { type: "whatsapp", icon: "whatsapp" };
      } else {
        return { type: "default", icon: "default" };
      }
    } catch (error) {
      return { type: "default", icon: "default" };
    }
  };

  // Helper function to get a nice display label for the link
  const getLinkDisplayLabel = (link) => {
    // If user provided a custom label, use that
    if (link.label && link.label.trim()) {
      return link.label;
    }

    try {
      const url = new URL(link.url);
      // Try to extract a readable name from the hostname
      let hostname = url.hostname.replace("www.", "");

      // Special cases for common domains
      if (hostname.includes("linkedin.com")) {
        const pathParts = url.pathname.split("/");
        const username = pathParts[pathParts.length - 1] || "profile";
        return `linkedin/${username}`;
      } else if (hostname.includes("github.com")) {
        const pathParts = url.pathname.split("/");
        const username = pathParts[1] || "profile";
        return `github/${username}`;
      }

      // For other sites, just return the domain name
      return hostname.split(".")[0];
    } catch (error) {
      // If URL parsing fails, return the URL as is
      return link.url;
    }
  };

  // Render a single link with the appropriate icon
  // Render a single link with the appropriate icon
  const renderLink = (link, index) => {
    if (!link || !link.url) return null;

    const { icon } = getLinkIconAndType(link.url);
    const displayLabel = getLinkDisplayLabel(link);

    // For unknown links, use the Material Icons "language" icon
    if (icon === "default") {
      return (
        <div className={styles.links} key={`link-${index}`}>
          <a
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <div className={styles.linkIcon}>
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  color: "gray",
                }}
              >
                language
              </span>
            </div>
            <span className={styles.linkText}>{displayLabel}</span>
          </a>
        </div>
      );
    }

    // For known social media platforms, use the custom SVG icons
    const IconComponent = SocialIcons[icon];

    return (
      <div className={styles.links} key={`link-${index}`}>
        <a
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          <div className={styles.linkIcon}>
            <IconComponent />
          </div>
          <span className={styles.linkText}>{displayLabel}</span>
        </a>
      </div>
    );
  };

  const handleNewEmailChange = (e) => {
    setNewEmail(e.target.value);
    setEmailError(""); // Clear any existing errors
  };

  const handleCancelEmailChange = () => {
    setIsEmailChangeOpen(false);
    setCurrentEmail("");
    setNewEmail("");
    setConfirmEmail("");
    setEmailError("");
    setIsCurrentEmailValid(false);
  };

  const areEmailsValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const hasValidFormat =
      emailRegex.test(newEmail) && emailRegex.test(confirmEmail);
    const emailsMatch = newEmail === confirmEmail;
    return hasValidFormat && emailsMatch && isCurrentEmailValid;
  };

  const checkCurrentEmail = async () => {
    if (currentEmail === auth.currentUser.email) {
      setIsCurrentEmailValid(true);
      setEmailError("");
    } else {
      setIsCurrentEmailValid(false);
      setEmailError("Current email is incorrect");
    }
  };

  const handleConfirmEmailChange = async () => {
    setIsChangingEmail(true);
    try {
      // First check if email already exists
      const signInMethods = await fetchSignInMethodsForEmail(auth, newEmail);

      // If any sign-in methods exist for this email, stop here
      if (signInMethods && signInMethods.length > 0) {
        setEmailError("This email address is already in use");
        setIsChangingEmail(false);
        return;
      }

      // Only attempt to send verification email if we get here
      await verifyBeforeUpdateEmail(auth.currentUser, newEmail);

      setEmailError(
        "Verification email sent. Please check your new email address to complete the change."
      );

      // Close modal after showing success message
      setTimeout(() => {
        setIsEmailChangeOpen(false);
        setCurrentEmail("");
        setNewEmail("");
        setConfirmEmail("");
        setEmailError("");
      }, 5000);
    } catch (error) {
      switch (error.code) {
        case "auth/requires-recent-login":
          setEmailError(
            "For security, please sign out and sign in again before changing your email."
          );
          break;
        case "auth/invalid-email":
          setEmailError("Please enter a valid email address.");
          break;
        case "auth/email-already-in-use":
          setEmailError("This email address is already in use.");
          break;
        default:
          setEmailError("Error changing email. Please try again.");
      }
    } finally {
      setIsChangingEmail(false);
    }
  };

  // Password change handlers
  const handleInitiatePasswordChange = () => {
    setIsPasswordChangeOpen(true);
  };

  const handleCancelPasswordChange = () => {
    setIsPasswordChangeOpen(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
  };

  const arePasswordsValid = () => {
    const hasMinLength =
      currentPassword.length >= 6 &&
      newPassword.length >= 6 &&
      confirmPassword.length >= 6;
    const passwordsMatch = newPassword === confirmPassword;

    return hasMinLength && passwordsMatch && isCurrentPasswordValid;
  };

  const checkCurrentPassword = async () => {
    if (currentPassword.length >= 6) {
      try {
        await signInWithEmailAndPassword(
          auth,
          auth.currentUser.email,
          currentPassword
        );
        setIsCurrentPasswordValid(true);
        setPasswordError("");
      } catch (error) {
        setIsCurrentPasswordValid(false);
        setPasswordError("Current password is incorrect");
      }
    }
  };

  const handleConfirmPasswordChange = async () => {
    setIsChangingPassword(true);
    try {
      await updatePassword(auth.currentUser, newPassword);

      // Show success message
      setPasswordError("Password successfully changed.");

      // Close modal and reset fields after delay
      setTimeout(() => {
        setIsPasswordChangeOpen(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setPasswordError("");
      }, 5000);
    } catch (error) {
      // Handle specific error cases with user-friendly messages
      switch (error.code) {
        case "auth/requires-recent-login":
          setPasswordError(
            "For security, please sign out and sign in again before changing your password."
          );
          break;
        case "auth/weak-password":
          setPasswordError("Password must be at least 6 characters long.");
          break;
        default:
          setPasswordError("Error changing password. Please try again.");
      }
    } finally {
      setIsChangingPassword(false);
    }
  };

  const openAccountDeleteConfirmation = () => {
    setAccountConfirmingDelete(true);
  };

  const cancelAccountDeleteConfirmation = () => {
    setAccountConfirmingDelete(false);
  };

  const confirmAccountDelete = async () => {
    setIsDeleting(true);
    try {
      await handleDeleteUser(auth.currentUser, setDeleteStatus);
      navigate("/");
    } catch (error) {
      setDeleteStatus("Error closing account. Please try again.");
      setIsDeleting(false);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleDeleteUser = async (currentUser, setDeleteStatus) => {
    try {
      setDeleteStatus("Closing account...");
      const userDocRef = doc(firestore, `users/${currentUser.uid}`);

      const connectionsRef = collection(userDocRef, "connections");
      const connectionsSnapshot = await getDocs(connectionsRef);

      await Promise.all(
        connectionsSnapshot.docs.map(async (connectionDoc) => {
          const connectionId = connectionDoc.id;
          const reverseConnectionRef = doc(
            firestore,
            `users/${connectionId}/connections/${currentUser.uid}`
          );
          await deleteDoc(reverseConnectionRef);
          await deleteDoc(connectionDoc.ref);
        })
      );

      const donationsRef = collection(userDocRef, "donations");
      const donationsSnapshot = await getDocs(donationsRef);

      await Promise.all(
        donationsSnapshot.docs.map(async (donationDoc) => {
          const donationData = donationDoc.data();
          const startupRef = doc(
            firestore,
            `users/${donationData.startupOwnerId}/startups/${donationData.startupId}/donations/${donationDoc.id}`
          );
          await deleteDoc(startupRef);
          await deleteDoc(donationDoc.ref);
        })
      );

      const notificationsRef = collection(userDocRef, "notifications");
      const notificationsSnapshot = await getDocs(notificationsRef);
      await Promise.all(
        notificationsSnapshot.docs.map((docSnapshot) =>
          deleteDoc(docSnapshot.ref)
        )
      );

      const postsRef = collection(userDocRef, "posts");
      const postsSnapshot = await getDocs(postsRef);

      await Promise.all(
        postsSnapshot.docs.map(async (postDoc) => {
          const commentsRef = collection(postDoc.ref, "comments");
          const commentsSnapshot = await getDocs(commentsRef);
          await Promise.all(
            commentsSnapshot.docs.map((docSnapshot) =>
              deleteDoc(docSnapshot.ref)
            )
          );
          await deleteDoc(postDoc.ref);
        })
      );

      const commentedPostsRef = collection(userDocRef, "commentedPosts");
      const commentedPostsSnapshot = await getDocs(commentedPostsRef);

      await Promise.all(
        commentedPostsSnapshot.docs.map(async (commentDoc) => {
          const postRef = doc(
            firestore,
            `users/${commentDoc.data().originalPosterId}/posts/${
              commentDoc.data().postId
            }/comments/${commentDoc.id}`
          );
          await deleteDoc(postRef);
          await deleteDoc(commentDoc.ref);
        })
      );

      const reactedPostsRef = collection(userDocRef, "reactedPosts");
      const reactedPostsSnapshot = await getDocs(reactedPostsRef);
      await Promise.all(
        reactedPostsSnapshot.docs.map((docSnapshot) =>
          deleteDoc(docSnapshot.ref)
        )
      );

      const communityPostsRef = collection(userDocRef, "communityPosts");
      const communityPostsSnapshot = await getDocs(communityPostsRef);

      await Promise.all(
        communityPostsSnapshot.docs.map(async (postDoc) => {
          const communityRef = doc(
            firestore,
            `users/${postDoc.data().communityOwnerId}/communities/${
              postDoc.data().communityId
            }/posts/${postDoc.id}`
          );
          const commentsRef = collection(communityRef, "comments");
          const commentsSnapshot = await getDocs(commentsRef);
          await Promise.all(
            commentsSnapshot.docs.map((docSnapshot) =>
              deleteDoc(docSnapshot.ref)
            )
          );
          await deleteDoc(communityRef);
          await deleteDoc(postDoc.ref);
        })
      );

      const eventsAttendingRef = collection(userDocRef, "eventsAttending");
      const eventsOrganisingRef = collection(userDocRef, "eventsOrganising");
      const [attendingSnapshot, organisingSnapshot] = await Promise.all([
        getDocs(eventsAttendingRef),
        getDocs(eventsOrganisingRef),
      ]);

      await Promise.all([
        ...attendingSnapshot.docs.map(async (docSnapshot) => {
          const eventRef = doc(
            firestore,
            `users/${docSnapshot.data().eventOwnerId}/events/${
              docSnapshot.id
            }/attendees/${currentUser.uid}`
          );
          await deleteDoc(eventRef);
          await deleteDoc(docSnapshot.ref);
        }),
        ...organisingSnapshot.docs.map(async (docSnapshot) => {
          const eventRef = doc(
            firestore,
            `users/${docSnapshot.data().eventOwnerId}/events/${
              docSnapshot.id
            }/hosts/${currentUser.uid}`
          );
          await deleteDoc(eventRef);
          await deleteDoc(docSnapshot.ref);
        }),
      ]);

      const experiencesRef = collection(userDocRef, "experiences");
      const educationRef = collection(userDocRef, "education");
      const [experiencesSnapshot, educationSnapshot] = await Promise.all([
        getDocs(experiencesRef),
        getDocs(educationRef),
      ]);

      await Promise.all([
        ...experiencesSnapshot.docs.map((docSnapshot) =>
          deleteDoc(docSnapshot.ref)
        ),
        ...educationSnapshot.docs.map((docSnapshot) =>
          deleteDoc(docSnapshot.ref)
        ),
      ]);

      const startupsRef = collection(userDocRef, "startups");
      const startupsSnapshot = await getDocs(startupsRef);

      await Promise.all(
        startupsSnapshot.docs.map(async (startupDoc) => {
          const startupId = startupDoc.id;
          const startup = startupDoc.data();

          const [
            communityPostsSnapshot,
            postsSnapshot,
            reactedPostsSnapshot,
            commentedPostsSnapshot,
            followersSnapshot,
            donationsSnapshot,
            teamSnapshot,
          ] = await Promise.all([
            getDocs(collection(startupDoc.ref, "communityPosts")),
            getDocs(collection(startupDoc.ref, "posts")),
            getDocs(collection(startupDoc.ref, "reactedPosts")),
            getDocs(collection(startupDoc.ref, "commentedPosts")),
            getDocs(collection(startupDoc.ref, "followers")),
            getDocs(collection(startupDoc.ref, "donations")),
            getDocs(collection(startupDoc.ref, "team")),
          ]);

          await Promise.all(
            communityPostsSnapshot.docs.map(async (postDoc) => {
              const postData = postDoc.data();
              if (postData.communityId) {
                const communityPostRef = doc(
                  firestore,
                  `communities/${postData.communityId}/posts/${postDoc.id}`
                );
                const commentsRef = collection(communityPostRef, "comments");
                const commentsSnapshot = await getDocs(commentsRef);
                await Promise.all(
                  commentsSnapshot.docs.map((docSnapshot) =>
                    deleteDoc(docSnapshot.ref)
                  )
                );
                await deleteDoc(communityPostRef);
              }
              await deleteDoc(postDoc.ref);
            })
          );

          await Promise.all(
            postsSnapshot.docs.map(async (postDoc) => {
              const commentsRef = collection(postDoc.ref, "comments");
              const commentsSnapshot = await getDocs(commentsRef);
              await Promise.all(
                commentsSnapshot.docs.map((docSnapshot) =>
                  deleteDoc(docSnapshot.ref)
                )
              );
              await deleteDoc(postDoc.ref);
            })
          );

          await Promise.all(
            followersSnapshot.docs.map(async (followerDoc) => {
              const followerId = followerDoc.id;
              const followingRef = doc(
                firestore,
                `users/${followerId}/startupsFollowing/${startupId}`
              );
              await deleteDoc(followingRef);
              await deleteDoc(followerDoc.ref);
            })
          );

          await Promise.all(
            teamSnapshot.docs.map(async (teamDoc) => {
              const teamMemberId = teamDoc.id;
              const memberTeamRef = doc(
                firestore,
                `users/${teamMemberId}/team/${startupId}`
              );
              await deleteDoc(memberTeamRef);
              await deleteDoc(teamDoc.ref);
            })
          );

          await Promise.all([
            ...reactedPostsSnapshot.docs.map((docSnapshot) =>
              deleteDoc(docSnapshot.ref)
            ),
            ...commentedPostsSnapshot.docs.map((docSnapshot) =>
              deleteDoc(docSnapshot.ref)
            ),
            ...donationsSnapshot.docs.map((docSnapshot) =>
              deleteDoc(docSnapshot.ref)
            ),
          ]);

          if (startup.startupImage) {
            try {
              if (
                !startup.startupImage.includes("startupImage.jpg") &&
                !startup.startupImage.includes("default.jpg")
              ) {
                const storage = getStorage();
                const imageUrl = new URL(startup.startupImage);
                const imagePath = decodeURIComponent(
                  imageUrl.pathname.split("/o/")[1].split("?")[0]
                );
                const imageRef = ref(storage, imagePath);
                try {
                  await getDownloadURL(imageRef);
                  await deleteObject(imageRef);
                } catch (error) {
                  if (error.code !== "storage/object-not-found") {
                    throw error;
                  }
                }
              }
            } catch (error) {}
          }

          await deleteDoc(startupDoc.ref);
        })
      );

      const communitiesRef = collection(userDocRef, "communities");
      const communitiesSnapshot = await getDocs(communitiesRef);

      await Promise.all(
        communitiesSnapshot.docs.map(async (communityDoc) => {
          const communityId = communityDoc.id;
          const community = communityDoc.data();

          const [membersSnapshot, moderatorsSnapshot, postsSnapshot] =
            await Promise.all([
              getDocs(collection(communityDoc.ref, "members")),
              getDocs(collection(communityDoc.ref, "moderators")),
              getDocs(collection(communityDoc.ref, "posts")),
            ]);

          await Promise.all(
            postsSnapshot.docs.map(async (postDoc) => {
              const commentsRef = collection(postDoc.ref, "comments");
              const commentsSnapshot = await getDocs(commentsRef);
              await Promise.all(
                commentsSnapshot.docs.map((docSnapshot) =>
                  deleteDoc(docSnapshot.ref)
                )
              );
              await deleteDoc(postDoc.ref);
            })
          );

          await Promise.all([
            ...membersSnapshot.docs.map(async (memberDoc) => {
              const memberRef = doc(
                firestore,
                `users/${memberDoc.id}/communitiesMemberOf/${communityId}`
              );
              await deleteDoc(memberRef);
              await deleteDoc(memberDoc.ref);
            }),
            ...moderatorsSnapshot.docs.map(async (modDoc) => {
              const modRef = doc(
                firestore,
                `users/${modDoc.id}/communitiesModerating/${communityId}`
              );
              await deleteDoc(modRef);
              await deleteDoc(modDoc.ref);
            }),
          ]);

          if (
            community.communityImage &&
            !community.communityImage.includes("communityImage.jpg")
          ) {
            const storage = getStorage();
            const imageUrl = new URL(community.communityImage);
            const imagePath = decodeURIComponent(
              imageUrl.pathname.split("/o/")[1].split("?")[0]
            );
            const imageRef = ref(storage, imagePath);
            await deleteObject(imageRef);
          }

          await deleteDoc(communityDoc.ref);
        })
      );

      const eventsRef = collection(userDocRef, "events");
      const eventsSnapshot = await getDocs(eventsRef);

      await Promise.all(
        eventsSnapshot.docs.map(async (eventDoc) => {
          const eventId = eventDoc.id;
          const event = eventDoc.data();

          const [hostsSnapshot, attendeesSnapshot] = await Promise.all([
            getDocs(collection(eventDoc.ref, "hosts")),
            getDocs(collection(eventDoc.ref, "attendees")),
          ]);

          await Promise.all([
            ...hostsSnapshot.docs.map(async (hostDoc) => {
              const hostRef = doc(
                firestore,
                `users/${hostDoc.id}/eventsOrganising/${eventId}`
              );
              await deleteDoc(hostRef);
              await deleteDoc(hostDoc.ref);
            }),
            ...attendeesSnapshot.docs.map(async (attendeeDoc) => {
              const attendeeRef = doc(
                firestore,
                `users/${attendeeDoc.id}/eventsAttending/${eventId}`
              );
              await deleteDoc(attendeeRef);
              await deleteDoc(attendeeDoc.ref);
            }),
          ]);

          if (
            event.eventImage &&
            !event.eventImage.includes("eventImage.jpg")
          ) {
            const storage = getStorage();
            const imageUrl = new URL(event.eventImage);
            const imagePath = decodeURIComponent(
              imageUrl.pathname.split("/o/")[1].split("?")[0]
            );
            const imageRef = ref(storage, imagePath);
            await deleteObject(imageRef);
          }

          await deleteDoc(eventDoc.ref);
        })
      );

      const startupsFollowingRef = collection(userDocRef, "startupsFollowing");
      const teamRef = collection(userDocRef, "team");
      const [followingSnapshot, teamSnapshot] = await Promise.all([
        getDocs(startupsFollowingRef),
        getDocs(teamRef),
      ]);

      await Promise.all([
        ...followingSnapshot.docs.map(async (docSnapshot) => {
          const startupRef = doc(
            firestore,
            `users/${docSnapshot.data().ownerId}/startups/${
              docSnapshot.id
            }/followers/${currentUser.uid}`
          );
          await deleteDoc(startupRef);
          await deleteDoc(docSnapshot.ref);
        }),
        ...teamSnapshot.docs.map(async (docSnapshot) => {
          const startupRef = doc(
            firestore,
            `users/${docSnapshot.data().ownerId}/startups/${
              docSnapshot.id
            }/team/${currentUser.uid}`
          );
          await deleteDoc(startupRef);
          await deleteDoc(docSnapshot.ref);
        }),
      ]);

      const communitiesMemberOfRef = collection(
        userDocRef,
        "communitiesMemberOf"
      );
      const communityModeratingRef = collection(
        userDocRef,
        "communitiesModerating"
      );
      const [memberOfSnapshot, moderatingSnapshot] = await Promise.all([
        getDocs(communitiesMemberOfRef),
        getDocs(communityModeratingRef),
      ]);

      await Promise.all([
        ...memberOfSnapshot.docs.map(async (docSnapshot) => {
          const communityRef = doc(
            firestore,
            `users/${docSnapshot.data().communityOwnerId}/communities/${
              docSnapshot.id
            }/members/${currentUser.uid}`
          );
          await deleteDoc(communityRef);
          await deleteDoc(docSnapshot.ref);
        }),
        ...moderatingSnapshot.docs.map(async (docSnapshot) => {
          const communityRef = doc(
            firestore,
            `users/${docSnapshot.data().communityOwnerId}/communities/${
              docSnapshot.id
            }/moderators/${currentUser.uid}`
          );
          await deleteDoc(communityRef);
          await deleteDoc(docSnapshot.ref);
        }),
      ]);

      const userData = (await getDoc(userDocRef)).data();
      if (
        userData.profileImage &&
        !userData.profileImage.includes("profileImage.jpg")
      ) {
        const storage = getStorage();
        const imageUrl = new URL(userData.profileImage);
        const imagePath = decodeURIComponent(
          imageUrl.pathname.split("/o/")[1].split("?")[0]
        );
        const imageRef = ref(storage, imagePath);
        try {
          await deleteObject(imageRef);
        } catch (error) {}
      }

      await deleteDoc(userDocRef);

      try {
        await currentUser.delete();
      } catch (error) {
        if (error.code === "auth/requires-recent-login") {
          await auth.signOut();
          setDeleteStatus(
            "For security reasons, please sign in again to delete your account"
          );
          navigate("/", { state: { afterDelete: true } });
          return;
        }
        throw error;
      }

      setDeleteStatus("Account closed");
      navigate("/");
      return true;
    } catch (error) {
      if (error.code === "auth/requires-recent-login") {
        await auth.signOut();
      }
      throw error;
    }
  };

  const handleDeleteStartup = async (startupId) => {
    try {
      const startup = userData.startups.find((s) => s.id === startupId);
      if (!startup) return;

      const userDocRef = doc(firestore, "users", userData.id);
      const startupDocRef = doc(userDocRef, "startups", startupId);

      // 1. Get all subcollections
      const communityPostsRef = collection(startupDocRef, "communityPosts");
      const postsRef = collection(startupDocRef, "posts");
      const reactedPostsRef = collection(startupDocRef, "reactedPosts");
      const commentedPostsRef = collection(startupDocRef, "commentedPosts");
      const followersRef = collection(startupDocRef, "followers");
      const donationsRef = collection(startupDocRef, "donations");
      const teamRef = collection(startupDocRef, "team");

      // Get all documents from each subcollection
      const [
        communityPostsSnapshot,
        postsSnapshot,
        reactedPostsSnapshot,
        commentedPostsSnapshot,
        followersSnapshot,
        donationsSnapshot,
        teamSnapshot,
      ] = await Promise.all([
        getDocs(communityPostsRef),
        getDocs(postsRef),
        getDocs(reactedPostsRef),
        getDocs(commentedPostsRef),
        getDocs(followersRef),
        getDocs(donationsRef),
        getDocs(teamRef),
      ]);

      // 2. Handle community posts cleanup
      const communityPostsCleanupPromises = communityPostsSnapshot.docs.map(
        async (postDoc) => {
          const postData = postDoc.data();
          if (postData.communityId) {
            const communityRef = doc(
              firestore,
              "communities",
              postData.communityId
            );
            const communityPostRef = doc(
              collection(communityRef, "posts"),
              postDoc.id
            );
            try {
              const commentsRef = collection(communityPostRef, "comments");
              const commentsSnapshot = await getDocs(commentsRef);
              await Promise.all(
                commentsSnapshot.docs.map((commentDoc) =>
                  deleteDoc(commentDoc.ref)
                )
              );
              await deleteDoc(communityPostRef);
            } catch (error) {
              console.error("Error deleting community post:", error);
            }
          }
          await deleteDoc(postDoc.ref);
        }
      );

      // 3. Handle regular posts cleanup
      const postsCleanupPromises = postsSnapshot.docs.map(async (postDoc) => {
        const commentsRef = collection(postDoc.ref, "comments");
        const commentsSnapshot = await getDocs(commentsRef);
        await Promise.all(
          commentsSnapshot.docs.map((commentDoc) => deleteDoc(commentDoc.ref))
        );
        await deleteDoc(postDoc.ref);
      });

      // 4. Handle followers cleanup
      const followersCleanupPromises = followersSnapshot.docs.map(
        async (followerDoc) => {
          const followerId = followerDoc.id;
          const followerRef = doc(firestore, "users", followerId);
          try {
            const followingRef = doc(
              collection(followerRef, "startupsFollowing"),
              startupId
            );
            await deleteDoc(followingRef);
          } catch (error) {}
          await deleteDoc(followerDoc.ref);
        }
      );

      // 5. Handle team cleanup
      const teamCleanupPromises = teamSnapshot.docs.map(async (teamDoc) => {
        const teamMemberId = teamDoc.id;
        const teamMemberRef = doc(firestore, "users", teamMemberId);
        try {
          const memberTeamRef = doc(
            collection(teamMemberRef, "team"),
            startupId
          );
          await deleteDoc(memberTeamRef);
        } catch (error) {}
        await deleteDoc(teamDoc.ref);
      });

      // 6. Handle donations cleanup
      const donationsCleanupPromises = donationsSnapshot.docs.map(
        async (donationDoc) => {
          const donorId = donationDoc.data().userId;
          if (donorId) {
            const donorRef = doc(firestore, "users", donorId);
            try {
              const donationRef = doc(
                collection(donorRef, "donations"),
                donationDoc.id
              );
              await deleteDoc(donationRef);
            } catch (error) {}
          }
          await deleteDoc(donationDoc.ref);
        }
      );

      // 7. Delete notifications
      const allUserIds = new Set([
        userData.id,
        ...followersSnapshot.docs.map((doc) => doc.id),
        ...teamSnapshot.docs.map((doc) => doc.id),
        ...donationsSnapshot.docs
          .map((doc) => doc.data().userId)
          .filter(Boolean),
      ]);

      const notificationsCleanupPromises = Array.from(allUserIds).map(
        async (userId) => {
          const userNotificationsRef = collection(
            doc(firestore, "users", userId),
            "notifications"
          );
          const notificationsQuery = query(
            userNotificationsRef,
            where("startupId", "==", startupId)
          );
          try {
            const notificationsSnapshot = await getDocs(notificationsQuery);
            await Promise.all(
              notificationsSnapshot.docs.map((doc) => deleteDoc(doc.ref))
            );
          } catch (error) {}
        }
      );

      // 8. Delete startup image
      if (
        startup.startupImage &&
        !startup.startupImage.includes("startupImage.jpg")
      ) {
        try {
          const storage = getStorage();
          const imageUrl = new URL(startup.startupImage);
          const imagePath = decodeURIComponent(
            imageUrl.pathname.split("/o/")[1].split("?")[0]
          );
          const imageRef = ref(storage, imagePath);
          await deleteObject(imageRef);
        } catch (error) {}
      }

      // 9. Execute all deletions
      await Promise.all([
        ...communityPostsCleanupPromises,
        ...postsCleanupPromises,
        ...reactedPostsSnapshot.docs.map((doc) => deleteDoc(doc.ref)),
        ...commentedPostsSnapshot.docs.map((doc) => deleteDoc(doc.ref)),
        ...followersCleanupPromises,
        ...teamCleanupPromises,
        ...donationsCleanupPromises,
        ...notificationsCleanupPromises,
      ]);

      // 10. Delete startup document
      await deleteDoc(startupDocRef);

      // 11. Update local state
      const updatedStartups = userData.startups.filter(
        (s) => s.id !== startupId
      );

      // Update both local state and the parent component's state
      setFullUserData((prev) => ({
        ...prev,
        startups: updatedStartups,
      }));

      // This is the important addition - update the parent component's state
      if (userData.onProfileUpdate) {
        userData.onProfileUpdate({ startups: updatedStartups });
      }

      // Since we're using inline confirmation now, this code isn't needed anymore
      // but removing it since it's already handled by confirmDeleteStartup
      // setShowDeleteModal(false);
      // setStartupToDelete(null);
    } catch (error) {
      console.error("Error deleting startup:", error);
    }
  };

  // In SidebarRight.js - add or update this useEffect
  useEffect(() => {
    if (userData?.id) {
      // When userData changes from the parent, update our local state
      setFullUserData((prevState) => ({
        ...prevState,
        ...userData,
        startups: userData.startups || prevState.startups || [],
      }));
    }
  }, [userData]);

  useEffect(() => {
    const fetchFullUserData = async () => {
      if (userData?.id) {
        try {
          // Get existing startups from local state
          const existingStartups = fullUserData?.startups || [];

          const userDocRef = doc(firestore, "users", userData.id);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const experiencesSnapshot = await getDocs(
              collection(firestore, `users/${userData.id}/experiences`)
            );
            const experiences = experiencesSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            const educationSnapshot = await getDocs(
              collection(firestore, `users/${userData.id}/education`)
            );
            const education = educationSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            // When setting state, preserve startups that were locally modified
            const fetchedStartups = userData.startups || [];

            const mergedStartups = fetchedStartups.map((startup) => {
              // Check if this startup was recently modified locally
              const localVersion = existingStartups.find(
                (s) => s.id === startup.id
              );
              // If it was modified in the last 60 seconds, keep the local version
              if (
                localVersion &&
                localVersion._localUpdateTime &&
                Date.now() - localVersion._localUpdateTime < 60000
              ) {
                console.log("Using local version for", startup.id);
                return localVersion;
              }
              return startup;
            });

            setFullUserData({
              uid: userData.id,
              ...userDoc.data(),
              experiences,
              education,
              startups: mergedStartups, // Use merged startups instead of userData.startups
            });
          }
        } catch (error) {
          console.error("Error fetching full user data:", error);
          // Initialize with empty arrays if there's an error
          setFullUserData({ startups: [] });
        }
      }
    };

    fetchFullUserData();
  }, [userData?.id, userData?.startups]);

  useEffect(() => {
    if (isProfileModalOpen || isAddStartupModalOpen || isEditStartupModalOpen) {
      const scrollY = window.scrollY;
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = "100%";
      setModalScrollPosition(scrollY);
    }

    return () => {
      if (
        isProfileModalOpen ||
        isAddStartupModalOpen ||
        isEditStartupModalOpen
      ) {
        document.body.style.position = "";
        document.body.style.top = "";
        document.body.style.width = "";
        window.scrollTo(0, modalScrollPosition);
      }
    };
  }, [
    isProfileModalOpen,
    isAddStartupModalOpen,
    isEditStartupModalOpen,
    modalScrollPosition,
  ]);

  // Password validation
  useEffect(() => {
    const hasMinLength =
      currentPassword.length >= 6 &&
      newPassword.length >= 6 &&
      confirmPassword.length >= 6;
    const passwordsMatch = newPassword === confirmPassword;
    const isValid = hasMinLength && passwordsMatch && isCurrentPasswordValid;

    setIsValidForm(isValid);
  }, [currentPassword, newPassword, confirmPassword, isCurrentPasswordValid]);

  const toggleProfileModal = (isSave = false) => {
    if (!showProfileModal) {
      setProfileModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, profileModalScrollPosition);
      }, 0);
    }
    setShowProfileModal(!showProfileModal);
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  const toggleAddStartupModal = (success = false) => {
    if (!showAddStartupModal) {
      setAddStartupModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addStartupModalScrollPosition);
      }, 0);
    }
    setShowAddStartupModal(!showAddStartupModal);
    setIsAddStartupModalOpen(!isAddStartupModalOpen);
  };

  const toggleEditStartupModal = (startup = null) => {
    if (!showEditStartupModal) {
      setEditStartupModalScrollPosition(window.scrollY);
      setSelectedStartup(startup);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editStartupModalScrollPosition);
      }, 0);
      setSelectedStartup(null);
    }
    setShowEditStartupModal(!showEditStartupModal);
    setIsEditStartupModalOpen(!isEditStartupModalOpen);
  };

  const toggleIntroModal = (startup = null) => {
    console.log("toggleIntroModal called with:", { startup, userData });

    if (!showIntroModal) {
      setIntroModalScrollPosition(window.scrollY);
      setSelectedStartupForIntro(startup); // Only set if opening modal
    } else {
      setTimeout(() => {
        window.scrollTo(0, introModalScrollPosition);
      }, 0);
      setSelectedStartupForIntro(null);
    }
    setShowIntroModal(!showIntroModal);
    setIsIntroModalOpen(!isIntroModalOpen);
  };

  // In SidebarRight.js - handleIntroUpdate function (updated)
  // In SidebarRight.js - handleIntroUpdate function (completely revised)
  const handleIntroUpdate = async (updatedData) => {
    try {
      console.log("Updating intro with:", {
        userId: userData.id,
        updatedData,
        isStartup: !!selectedStartupForIntro,
      });

      // User intro update
      if (!selectedStartupForIntro) {
        // Create document reference using exact path
        const userRef = doc(firestore, "users", userData.id);

        // Update the document
        await updateDoc(userRef, {
          intro: updatedData.intro || "",
          introHtml: updatedData.introHtml || "",
        });

        // Update both local states to ensure UI consistency
        setFullUserData((prev) => ({
          ...prev,
          intro: updatedData.intro || "",
          introHtml: updatedData.introHtml || "",
        }));

        // Create a local copy of userData with the updated intro
        const updatedUserData = {
          ...userData,
          intro: updatedData.intro || "",
          introHtml: updatedData.introHtml || "",
        };

        // Notify parent to update its state
        if (userData.onProfileUpdate) {
          userData.onProfileUpdate(updatedUserData);
        }
      }
      // Startup intro update
      else {
        const startupRef = doc(
          firestore,
          "users",
          userData.id,
          "startups",
          selectedStartupForIntro.id
        );

        await updateDoc(startupRef, {
          intro: updatedData.intro || "",
          introHtml: updatedData.introHtml || "",
        });

        // Create a new startups array with the updated startup
        const updatedStartups = (userData.startups || []).map((startup) =>
          startup.id === selectedStartupForIntro.id
            ? {
                ...startup,
                intro: updatedData.intro || "",
                introHtml: updatedData.introHtml || "",
              }
            : startup
        );

        // Create a complete updated userData object
        const updatedUserData = {
          ...userData,
          startups: updatedStartups,
        };

        // Update both our local states
        setFullUserData((prev) => ({
          ...prev,
          startups: updatedStartups,
        }));

        // Update the parent component with the complete updated userData
        if (userData.onProfileUpdate) {
          userData.onProfileUpdate(updatedUserData);
        }
      }

      toggleIntroModal();
    } catch (error) {
      console.error("Error in handleIntroUpdate:", error);
      throw error;
    }
  };

  // In SidebarRight.js - update the handleStartupAdded function
  const handleStartupAdded = (newStartup) => {
    // Create a new startups array with the new startup added
    const updatedStartups = [...(userData.startups || []), newStartup];

    // Update local state
    setFullUserData((prev) => ({
      ...prev,
      startups: updatedStartups,
    }));

    // Important: Update the parent component's state
    if (userData.onProfileUpdate) {
      userData.onProfileUpdate({ startups: updatedStartups });
    }

    toggleAddStartupModal(true);
  };

  // In SidebarRight.js
  // Replace the current handleStartupUpdate function with this
  const handleStartupUpdate = (updatedStartup) => {
    console.log("Received updated startup in SidebarRight:", updatedStartup);

    // Get the name from the updated startup
    const nameToUse = updatedStartup.startupName || updatedStartup.name;
    console.log("Using name:", nameToUse);

    // Force a DOM update to immediately change the displayed name
    try {
      const elements = document.querySelectorAll(
        `[data-startup-id="${updatedStartup.id}"]`
      );
      console.log(
        `Found ${elements.length} elements with startup ID ${updatedStartup.id}`
      );

      elements.forEach((el) => {
        console.log("Updating element:", el);
        el.textContent = nameToUse;
      });
    } catch (err) {
      console.error("DOM update failed:", err);
    }

    // Update the React state
    if (userData?.startups) {
      // Create a new startups array with the updated startup
      const updatedStartups = userData.startups.map((startup) => {
        if (startup.id === updatedStartup.id) {
          return {
            ...startup,
            ...updatedStartup,
            startupName: nameToUse,
            name: nameToUse,
            _localUpdateTime: Date.now(), // Mark as recently updated
          };
        }
        return startup;
      });

      // Update local state
      setFullUserData((prev) => ({
        ...prev,
        startups: updatedStartups,
      }));

      // Update parent component state
      if (userData.onProfileUpdate) {
        userData.onProfileUpdate({
          startups: updatedStartups,
        });
      }
    }

    // The EditSingleStartupModal now handles closing itself in its handleSubmit function
    // So we should NOT close it from here
    // toggleEditStartupModal(); - REMOVE THIS LINE
  };

  const handleProfileUpdate = async (updatedUserData) => {
    try {
      const userRef = doc(firestore, "users", userData.id);
      await updateDoc(userRef, updatedUserData);

      setFullUserData((prev) => ({
        ...prev,
        ...updatedUserData,
      }));

      toggleProfileModal(true);

      if (userData.onProfileUpdate) {
        userData.onProfileUpdate(updatedUserData);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const toggleExpandText = (id) => {
    setExpandedTexts((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderTruncatedText = (text, id) => {
    if (!text) return null;
    const isExpanded = expandedTexts[id];

    if (text.length <= MAX_LENGTH) {
      return <p className={styles.description}>{text}</p>;
    }

    if (isExpanded) {
      return (
        <p className={styles.description}>
          {text}
          <span
            className={styles.moreButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleExpandText(id);
            }}
          >
            Show less
          </span>
        </p>
      );
    }

    const truncatedText = text.slice(0, MAX_LENGTH).trim();
    return (
      <p className={styles.description}>
        {truncatedText}
        <span
          className={styles.moreButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleExpandText(id);
          }}
        >
          ...more
        </span>
      </p>
    );
  };

  // Add this directly above the existing ownedStartups useMemo
  const ownedStartups = useMemo(() => {
    if (!userData?.startups) return [];

    // If any startup has membershipType, filter by owner
    // Otherwise, assume all startups are owned by the current user
    const anyHasMembershipType = userData.startups.some(
      (startup) => startup.membershipType
    );

    if (anyHasMembershipType) {
      return userData.startups.filter(
        (startup) => startup.membershipType === "owner"
      );
    } else {
      // If no membershipType is set, assume all startups belong to the current user
      return userData.startups;
    }
  }, [userData?.startups]);

  // Add this near your other useEffects in SidebarRight.js
  useEffect(() => {
    if (userData?.startups) {
      // Force consistent naming across all startups
      const processedStartups = userData.startups.map((startup) => {
        const nameToUse = startup.startupName || startup.name || "";
        if (!nameToUse) return startup; // Skip if both names are empty

        return {
          ...startup,
          name: nameToUse,
          startupName: nameToUse,
        };
      });

      // Update local state with processed startups
      setFullUserData((prevState) => ({
        ...prevState,
        startups: processedStartups,
      }));
    }
  }, [userData?.startups]);

  useEffect(() => {
    console.log("Current userData:", userData);
    console.log("Startups from userData:", userData?.startups);
    console.log("Owned startups:", ownedStartups);
  }, [userData, ownedStartups]);

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      if (showProfileModal) toggleProfileModal(false);
      if (showAddStartupModal) toggleAddStartupModal(false);
      if (showEditStartupModal) toggleEditStartupModal();
      if (showIntroModal) toggleIntroModal();
      if (isEmailChangeOpen) handleCancelEmailChange();
      if (isPasswordChangeOpen) handleCancelPasswordChange();
      if (accountConfirmingDelete) cancelAccountDeleteConfirmation();
    }
    setMouseDownOnBackdrop(false);
  };

  return (
    <>
      {showProfileModal && fullUserData && (
        <div
          className={styles.modalBackdrop}
          onMouseDown={handleBackdropMouseDown}
          onMouseUp={handleBackdropMouseUp}
          style={{
            top: isProfileModalOpen ? `-${modalScrollPosition}px` : "0",
          }}
        >
          <ProfileModal
            onClose={toggleProfileModal}
            currentUser={fullUserData}
            onProfileUpdate={handleProfileUpdate}
          />
        </div>
      )}
      {showSettingsModal && (
        <SettingsModal
          onClose={() => setShowSettingsModal(false)}
          onLogout={handleLogout}
          onDeleteAccount={confirmAccountDelete}
        />
      )}

      {showAddStartupModal && fullUserData && (
        <div
          className={styles.modalBackdrop}
          onMouseDown={handleBackdropMouseDown}
          onMouseUp={handleBackdropMouseUp}
          style={{
            top: isAddStartupModalOpen
              ? `-${addStartupModalScrollPosition}px`
              : "0",
          }}
        >
          <AddStartupModal
            onClose={toggleAddStartupModal}
            currentUser={fullUserData}
            onStartupAdded={handleStartupAdded}
          />
        </div>
      )}

      {showEditStartupModal && selectedStartup && (
        <div
          className={styles.modalBackdrop}
          onMouseDown={handleBackdropMouseDown}
          onMouseUp={handleBackdropMouseUp}
          style={{
            top: isEditStartupModalOpen
              ? `-${editStartupModalScrollPosition}px`
              : "0",
          }}
        >
          <EditSingleStartupModal
            startup={selectedStartup}
            onClose={toggleEditStartupModal}
            onUpdate={handleStartupUpdate}
            currentUser={fullUserData}
            isFromStartupPage={false}
          />
        </div>
      )}

      {showIntroModal && fullUserData && (
        <div
          className={styles.modalBackdrop}
          onMouseDown={handleBackdropMouseDown}
          onMouseUp={handleBackdropMouseUp}
          style={{
            top: isIntroModalOpen ? `-${introModalScrollPosition}px` : "0",
          }}
        >
          <IntroModal
            onClose={toggleIntroModal}
            currentUser={{
              ...userData,
              uid: userData.id, // Add uid for backward compatibility
            }}
            onProfileUpdate={handleIntroUpdate}
            isStartup={!!selectedStartupForIntro}
            startupData={selectedStartupForIntro}
            startupOwnerId={userData?.id}
          />
        </div>
      )}

      {/* Email change modal */}
      {isEmailChangeOpen && (
        <div
          className={styles.modalBackdrop}
          onMouseDown={handleBackdropMouseDown}
          onMouseUp={handleBackdropMouseUp}
        >
          <div className={styles.confirmationModal}>
            <div className={styles.confirmationContent}>
              <div className={styles.passwordInputs}>
                <input
                  type="email"
                  placeholder="current email"
                  value={currentEmail}
                  onChange={(e) => setCurrentEmail(e.target.value)}
                  onBlur={checkCurrentEmail}
                  className={styles.userInput}
                />
                <input
                  type="email"
                  placeholder="new email"
                  value={newEmail}
                  onChange={handleNewEmailChange}
                  className={styles.userInput}
                />
                <input
                  type="email"
                  placeholder="confirm new email"
                  value={confirmEmail}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  className={styles.userInput}
                />
              </div>
              {emailError && <div className={styles.error}>{emailError}</div>}
              <div className={styles.confirmationButtons}>
                <button
                  className={`${styles.confirmButton} ${
                    !areEmailsValid() ? styles.disabledButton : ""
                  }`}
                  onClick={handleConfirmEmailChange}
                  disabled={!areEmailsValid() || isChangingEmail}
                >
                  {isChangingEmail ? "saving..." : "save changes"}
                </button>
                <button
                  className={styles.cancelButton}
                  onClick={handleCancelEmailChange}
                >
                  cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Password change modal */}
      {isPasswordChangeOpen && (
        <div
          className={styles.modalBackdrop}
          onMouseDown={handleBackdropMouseDown}
          onMouseUp={handleBackdropMouseUp}
        >
          <div className={styles.confirmationModal}>
            <div className={styles.confirmationContent}>
              <h3>Change Password</h3>
              <div className={styles.passwordInputs}>
                <input
                  type="password"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  onBlur={checkCurrentPassword}
                  className={styles.userInput}
                />
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className={styles.userInput}
                />
                <input
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={styles.userInput}
                />
              </div>
              {passwordError && (
                <div className={styles.error}>{passwordError}</div>
              )}
              <div className={styles.confirmationButtons}>
                <button
                  className={`${styles.confirmButton} ${
                    !arePasswordsValid() ? styles.disabledButton : ""
                  }`}
                  onClick={handleConfirmPasswordChange}
                  disabled={!arePasswordsValid() || isChangingPassword}
                >
                  {isChangingPassword ? "Changing..." : "Change Password"}
                </button>
                <button
                  className={styles.cancelButton}
                  onClick={handleCancelPasswordChange}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <aside className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}>
        {/* Simple close button with clear positioning */}
        <button className={styles.closeBtn} onClick={onToggle}>
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "1.25rem", color: "gray" }}
          >
            keyboard_double_arrow_right
          </span>
        </button>

        {/* The header with the action buttons */}
        <div className={styles.sidebarHeader}>
          <div className={styles.actionButtons}>
            {/* <button
              className={styles.iconButton}
              onClick={toggleAddStartupModal}
            >
              <span
                className="material-symbols-outlined"
                style={{ fontSize: "1.25rem", color: "gray" }}
              >
                add
              </span>
            </button> */}
            {/* <button
              className={styles.iconButton}
              onClick={() => setShowSettingsModal(true)}
            >
              <span className="material-symbols-outlined">settings</span>
            </button> */}
          </div>
        </div>

        <div className={styles.topHalf}>
          <div className={styles.profileSection}>
            <div className={styles.profileImageContainer}>
              <img
                src={userData.profileImage || defaultImage}
                alt="profile"
                className={styles.profileImage}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = defaultImage;
                }}
              />
              <span onClick={toggleProfileModal} className={styles.editIcon}>
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "1.25rem", color: "gray" }}
                >
                  edit
                </span>
              </span>
            </div>

            <h2 className={styles.profileName}>
              {userData.firstName} {userData.lastName}
            </h2>
          </div>

          {userData.bio && <p className={styles.bio}>{userData.bio}</p>}

          {(userData.city || userData.country) && (
            <div className={styles.infoRow}>
              {[userData.city, userData.country].filter(Boolean).join(", ")}
            </div>
          )}

          {/* Render multiple links */}
          {userData.links && userData.links.length > 0 && (
            <div className={styles.linksContainer}>
              {userData.links.map((link, index) => renderLink(link, index))}
            </div>
          )}

          {/* Fallback for old single link format */}
          {!userData.links &&
            userData.link &&
            renderLink({ url: userData.link, label: userData.linkText }, 0)}

          {userData.intro && (
            <>
              <p className={styles.intro}>
                {/* <span className={styles.bold}>tldr:</span> */}
                {userData.intro}
              </p>
            </>
          )}

          {/* {ownedStartups.length > 0 && (
            <>
              {ownedStartups.map((startup) => (
                <div key={startup.id} className={styles.section}>
                
                  <div
                    style={{
                      borderTop: "0.05rem solid lightgray",
                      marginRight: "1rem",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  ></div>

                  <div className={styles.profileImageContainer}>
                    <img
                      src={startup.startupImage || "/api/placeholder/400/320"}
                      alt={startup.startupName}
                      className={styles.startupImage}
                    />
                    <div className={styles.iconContainer}>
                      {startupConfirmingDelete === startup.id ? (
                        <>
                          <span
                            className={`${styles.confirmIcon} ${styles.cancelIcon}`}
                            onClick={(e) => confirmDeleteStartup(startup.id, e)}
                          >
                            <span
                              className="material-symbols-outlined"
                              style={{ fontSize: "1.25rem", color: "gray" }}
                            >
                              delete
                            </span>
                          </span>
                          <span
                            className={styles.cancelIcon}
                            onClick={cancelDeleteConfirmation}
                          >
                            <span
                              className="material-symbols-outlined"
                              style={{ fontSize: "1.25rem", color: "gray" }}
                            >
                              close
                            </span>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className={styles.editIcon}
                            onClick={() => toggleEditStartupModal(startup)}
                          >
                            <span
                              className="material-symbols-outlined"
                              style={{ fontSize: "1.25rem", color: "gray" }}
                            >
                              edit
                            </span>
                          </span>
                          <span
                            className={styles.trashIcon}
                            onClick={(e) => openDeleteConfirmation(startup, e)}
                          >
                            <span
                              className="material-symbols-outlined"
                              style={{ fontSize: "1.25rem", color: "gray" }}
                            >
                              more_horiz
                            </span>
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <h2
                    className={styles.profileName}
                    data-startup-id={startup.id}
                  >
                    {startup.startupName || startup.name}
                  </h2>

                  {startup.bio && <p className={styles.bio}>{startup.bio}</p>}

                  {(startup.city || startup.country) && (
                    <div className={styles.infoRow}>
                      {[startup.city, startup.country]
                        .filter(Boolean)
                        .join(", ")}
                    </div>
                  )}

                  {startup.links && startup.links.length > 0 && (
                    <div className={styles.linksContainer}>
                      {startup.links.map((link, index) =>
                        renderLink(link, index)
                      )}
                    </div>
                  )}


                  {!startup.links && startup.link && (
                    <div className={styles.linksContainer}>
                      {renderLink(
                        { url: startup.link, label: startup.linkText },
                        0
                      )}
                    </div>
                  )}

                  {startup.intro && (
                    <p className={styles.intro}>{startup.intro}</p>
                  )}
                </div>
              ))}
            </>
          )} */}
        </div>
        {/* <div className={styles.footer}>
          <div onClick={toggleAddStartupModal} className={styles.button}>
            <span>+ add page</span>
          </div>
          <div
            onClick={toggleSettings}
            className={`${styles.button} ${
              showSettings ? styles.activeButton : ""
            }`}
          >
            <span>{showSettings ? "< settings" : "> settings"}</span>
          </div>

          {showSettings && (
            <div className={styles.settingsContainer}>
              <div onClick={handleLogout} className={styles.button}>
                <span>{"<"} sign out</span>
              </div>

              {accountConfirmingDelete ? (
                <div className={styles.deleteConfirmation}>
                  <div
                    onClick={confirmAccountDelete}
                    className={styles.confirmButton}
                    disabled={isDeleting}
                  >
                    <span>
                      {isDeleting ? renderLoadingSymbols() : "> confirm"}
                    </span>
                  </div>
                  <div
                    onClick={cancelAccountDeleteConfirmation}
                    className={styles.cancelButton}
                  >
                    <span>{"< "}cancel</span>
                  </div>
                </div>
              ) : (
                <div
                  onClick={openAccountDeleteConfirmation}
                  className={styles.button}
                >
                  <span>- deactive account</span>
                </div>
              )}
            </div>
          )}
        </div> */}
        {/* Add a footer section with the add startup button */}
        {/* <div className={styles.footer}>
          <a
            href="#"
            className={styles.footerButton}
            onClick={(e) => {
              e.preventDefault();
              toggleAddStartupModal();
            }}
          >
            <div className={styles.linkIcon}>
              <span
                className="material-symbols-outlined"
                style={{
                  fontSize: "1.25rem",
                  color: "white",
                  transform: "rotate(45deg)",
                }}
              >
                arrow_upward
              </span>
            </div>
            <span className={styles.linkText}>add page</span>
          </a>
        </div> */}
      </aside>
    </>
  );
};

export default SidebarRight;
